import * as React from 'react';

import { useTheme } from '../../contexts/Theme';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function BarsIcon({
    fill = 'var(--nav-icon-fill)',
    stroke = 'var(--nav-icon-stroke)',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    const { colors } = useTheme();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
            <rect x="1.5" y="9.5" width="3" height="5" rx="1" />
            <path d="M7.5 5.5C6.94772 5.5 6.5 5.94772 6.5 6.5V13.5C6.5 14.0523 6.94772 14.5 7.5 14.5H8.5C9.05228 14.5 9.5 14.0523 9.5 13.5V6.5C9.5 5.94772 9.05228 5.5 8.5 5.5H7.5Z" />
            <path d="M12.5 1.5C11.9477 1.5 11.5 1.94772 11.5 2.5V13.5C11.5 14.0523 11.9477 14.5 12.5 14.5H13.5C14.0523 14.5 14.5 14.0523 14.5 13.5V2.5C14.5 1.94772 14.0523 1.5 13.5 1.5H12.5Z" />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    fill: ${fill ?? colors.system.grey};
                    height: ${size}px;
                    stroke: ${stroke ?? colors.system.greyDark};
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
