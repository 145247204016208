// Copyright 2019 Descript, Inc

import * as React from 'react';
import { FileWithPath } from 'react-dropzone';
import * as fileSelector from 'file-selector';
import { isDomNode } from './ReactEventHandlers';

export type HybridDragEvent = DragEvent | React.DragEvent;

export function hasDragFiles(event: HybridDragEvent): boolean {
    return (
        !!event.dataTransfer &&
        (event.dataTransfer.types.indexOf('Files') >= 0 ||
            event.dataTransfer.types.indexOf('files') >= 0)
    );
}

/**
 * Returns a list of top-level files from a drag event
 *
 * Consider using getFileHierarchyFromDragEvent instead (but note that it's async)
 *
 * @param event
 * @deprecated
 */
export function getFilesFromDragEvent(event: HybridDragEvent): File[] {
    if (!event.dataTransfer?.items) {
        return [];
    }
    const files: File[] = [];
    for (const item of event.dataTransfer.items) {
        if (item.kind === 'file') {
            const file = item.getAsFile();
            if (file) {
                files.push(file);
            }
        }
    }
    return files;
}

/**
 * Returns a list of files from a drag event, including files from nested folders.
 * @param event
 */
export async function getFileHierarchyFromDragEvent(
    event: HybridDragEvent,
): Promise<FileWithPath[]> {
    const allFiles = await fileSelector.fromEvent(event as Event);
    return allFiles.filter((item) => item instanceof File) as FileWithPath[];
}

export function getFilesFromClipboardEvent(
    event: ClipboardEvent | React.ClipboardEvent,
): File[] {
    const files: File[] = [];

    if (event.clipboardData?.items?.length) {
        for (const item of event.clipboardData.items) {
            if (item.kind === 'file') {
                const file = item.getAsFile();
                if (file) {
                    files.push(file);
                }
            }
        }
    }

    return files;
}

export const TEXT_EDITOR_ARIA_LABEL = 'Project Transcript';
export const TEXT_EDITOR_ID = 'script-view';

// TODO: Remove includeContentEditable - https://linear.app/descript/issue/ED-939
export function isEventInTextInputElement(
    { target }: { target: EventTarget | null },
    includeContentEditable: boolean,
): boolean {
    if (!isDomNode(target)) {
        return false;
    }
    if (target.getAttribute('aria-label') === TEXT_EDITOR_ARIA_LABEL) {
        // The script editor does not count as an input.
        return false;
    }
    if (includeContentEditable && target instanceof HTMLElement && target.isContentEditable) {
        return true;
    }
    // TODO: Remove this check - https://linear.app/descript/issue/ED-939
    if (
        target.getAttribute('data-role') === 'comment-box' ||
        target.getAttribute('data-role') === 'text-field'
    ) {
        return true;
    }
    const tagName = target.tagName;
    return tagName === 'INPUT' || tagName === 'SELECT' || tagName === 'TEXTAREA';
}

export function isEventInSliderElement({ target }: { target: EventTarget | null }): boolean {
    return isDomNode(target) && target.getAttribute('role') === 'slider';
}

export function isEventInSelectableElement({
    target,
}: {
    target: EventTarget | null;
}): boolean {
    if (!isDomNode(target)) {
        return false;
    }
    return target.tabIndex >= 0 || target.getAttribute('role') === 'menu';
}
