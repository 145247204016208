// Copyright 2024 Descript, Inc
import { useEffect, useState } from 'react';

import { throttle } from '../lib/throttle';

type ResizeObserverCallback = (width: number, height: number) => void;

/**
 * Sets up a ResizeObserver for the given ref element.
 * Whenever the element's dimension change, callback is called
 * with the element's current width and height.
 */
export const useResizeObserver = (
    ref: React.MutableRefObject<HTMLElement>,
    callback: ResizeObserverCallback,
    throttleDelay = 100,
) => {
    const [isPageVisible, setIsPageVisible] = useState(true);

    /**
     * Setup page visibility change listener.
     * When a page is backgrounded, ResizeObservers don't seem to catch any events.
     * We'll fall back to an interval in that case, since intervals are only throttled,
     * when the page is backgrounded.
     */
    useEffect(() => {
        const handleVisibilityChange = () => {
            setIsPageVisible(document.visibilityState === 'visible');
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const throttledCallback = throttle(callback, throttleDelay);

        if (typeof ResizeObserver !== 'undefined' && isPageVisible) {
            const resizeObserver = new ResizeObserver(() => {
                if (!ref.current) {
                    return;
                }
                // eslint-disable-next-line @descript-eslint/no-force-reflow
                const { height, width } = ref.current.getBoundingClientRect();
                throttledCallback(width, height);
            });
            resizeObserver.observe(ref.current);
            return () => {
                resizeObserver.disconnect();
            };
        }

        let lastWidth: number;
        let lastHeight: number;
        const interval = setInterval(() => {
            if (!ref.current) {
                return;
            }
            // eslint-disable-next-line @descript-eslint/no-force-reflow
            const { height, width } = ref.current.getBoundingClientRect();
            if (width !== lastWidth || height !== lastHeight) {
                callback(width, height);
                lastWidth = width;
                lastHeight = height;
            }
        }, throttleDelay);
        return () => {
            clearInterval(interval);
        };
    }, [callback, isPageVisible, ref, throttleDelay]);
};
