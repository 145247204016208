// Copyright 2023 Descript, Inc

/**
 * @descript/web-recorder
 *
 * client-side primitives for interacting with web recorder that is connected to the device our app is running on
 *
 */

export * from './device/device';
export * from './helpers/timer';
export * from './recorder/recorder';
