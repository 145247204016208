import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function PinIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M7.5 15V7" stroke={color} />
            <path
                d="M7.5 7C9.15685 7 10.5 5.65685 10.5 4C10.5 2.34315 9.15685 1 7.5 1C5.84315 1 4.5 2.34315 4.5 4C4.5 5.65685 5.84315 7 7.5 7Z"
                stroke={color}
            />
        </svg>
    );
}
