import { safeStorage } from '@daily/shared/src/lib/safeStorage';

const safeLocalStorage = safeStorage(() => localStorage);

const KEY = 'custom-virtual-bg';

export const storeCustomBackground = (data: string | ArrayBuffer) => {
    if (data instanceof ArrayBuffer) {
        // Convert to string, because ArrayBuffer can't be stored in localStorage
        const base64String = btoa(
            new Uint8Array(data).reduce((b64, byte) => b64 + String.fromCharCode(byte), ''),
        );
        data = base64String;
    }
    safeLocalStorage.setItem(KEY, data);
};

export const getCustomBackground = (): string | ArrayBuffer => {
    const data = safeLocalStorage.getItem(KEY);
    if (!data) {
        return data;
    }
    try {
        new URL(data);
        return data;
    } catch (e) {
        const binary = atob(data);
        const bytes = new Uint8Array(binary.length);
        for (let i = 0; i < binary.length; i++) {
            bytes[i] = binary.charCodeAt(i);
        }
        return bytes.buffer;
    }
};

export const deleteCustomBackground = () => {
    safeLocalStorage.removeItem(KEY);
};
