// Copyright 2024 Descript, Inc

import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import PubNub from 'pubnub';
import { useDailyEvent } from '@daily-co/daily-react';
import { CallSessionData } from '@contexts/CallProvider';
import { useMeetingSessionData } from '@features/breakouts/hooks/useMeetingSessionData';

interface ContextValue {
    isReady: boolean;
    initPubNub(
        userId: string,
        publishKey: string,
        subscribeKey: string,
        isOwner: boolean,
    ): void;
    addListeners(listeners: PubNub.ListenerParameters): void;
    removeListeners(listeners: PubNub.ListenerParameters): void;
}

const PubNubContext = createContext<ContextValue>({
    isReady: false,
    initPubNub: () => void 0,
    addListeners: () => void 0,
    removeListeners: () => void 0,
});

let pubNub: PubNub = undefined;

export function PubNubProvider({ children }: React.PropsWithChildren<unknown>) {
    const [isOwner, setIsOwner] = useState(false);
    const { sessionData } = useMeetingSessionData<CallSessionData>();
    const [isReady, setIsReady] = useState(false);

    const initPubNub = useCallback(
        (userId: string, publishKey: string, subscribeKey: string, owner: boolean) => {
            if (pubNub) {
                pubNub.unsubscribeAll();
            }

            setIsOwner(owner);

            pubNub = new PubNub({
                publishKey,
                subscribeKey,
                uuid: userId,
            });
            setIsReady(true);
        },
        [setIsOwner],
    );

    const addListeners = useCallback(
        (listeners: PubNub.ListenerParameters) => {
            pubNub?.addListener(listeners);
        },
        [pubNub],
    );

    const removeListeners = useCallback(
        (listeners: PubNub.ListenerParameters) => {
            pubNub?.removeListener(listeners);
        },
        [pubNub],
    );

    const onRecordingStart = useCallback(() => {
        pubNub?.unsubscribeAll();

        if (isOwner) {
            const channelName = `project.${sessionData?.cs?.projectId}`;
            pubNub?.subscribe({ channels: [channelName] });
        }
    }, [pubNub, isOwner, sessionData?.cs?.projectId]);
    useDailyEvent('recording-started', onRecordingStart);

    useEffect(() => () => pubNub?.unsubscribeAll(), [pubNub]);

    return (
        <PubNubContext.Provider
            value={{
                isReady,
                initPubNub,
                addListeners,
                removeListeners,
            }}
        >
            {children}
        </PubNubContext.Provider>
    );
}

export const usePubNub = () => useContext(PubNubContext);
