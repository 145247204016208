// Copyright 2024 Descript, Inc
import classnames from 'classnames';
import * as React from 'react';
import { resolve } from 'styled-jsx/css';

import { useTheme } from '../../contexts/Theme/useTheme';
import { pxToRem } from '../../lib/pxToRem';

interface BaseProps {
    color?:
        | 'default'
        | 'muted'
        | 'warning'
        | 'error'
        | 'info'
        | 'success'
        | 'white'
        | 'inverse'
        | 'inherit';
    El?:
        | 'p'
        | 'span'
        | 'code'
        | 'small'
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'strong'
        | 'div'
        | 'label'
        | 'dt'
        | 'dd'
        | 'ol'
        | 'ul'
        | 'li';
    textAlign?: 'left' | 'center' | 'right' | 'justify' | 'inherit';
    truncate?: boolean;
    underline?: boolean;
    variant?:
        | 'xsmall'
        | 'small'
        | 'mono'
        | 'base'
        | 'strong'
        | 'large'
        | 'largestrong'
        | 'm-heading-big'
        | 'm-heading-medium'
        | 'm-heading-small'
        | 'm-heading-xsmall'
        | 'm-base'
        | 'm-strong'
        | 'm-small'
        | 'xlarge';
}

type TextProps = BaseProps &
    React.ComponentProps<'h1'> & {
        htmlFor?: string;
    };

const useTextStyles = () => resolve`
    .underline {
        text-decoration: underline;
    }
    .default {
        color: var(--text-default);
        text-decoration-color: var(--text-default);
    }
    .muted {
        color: var(--text-muted);
        text-decoration-color: var(--text-muted);
    }
    .error {
        color: var(--text-error);
        text-decoration-color: var(--text-error);
    }
    .info {
        color: var(--text-info);
        text-decoration-color: var(--text-info);
    }
    .success {
        color: var(--text-success);
        text-decoration-color: var(--text-success);
    }
    .warning {
        color: var(--text-warning);
        text-decoration-color: var(--text-warning);
    }
    .inverse {
        color: var(--text-inverse);
        text-decoration-color: var(--text-inverse);
    }
    .white {
        color: var(--text-white);
        text-decoration-color: var(--text-white);
    }
    .inherit {
        color: inherit;
        text-decoration-color: inherit;
    }
    .truncate {
        display: block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

function XSmall({
    children,
    className,
    color = 'default',
    El = 'small',
    textAlign = 'inherit',
    truncate,
    underline,
    ...props
}: React.PropsWithChildren<TextProps>) {
    const { fontFamilies, fontSizes, lineHeights } = useTheme();
    const text = useTextStyles();
    return (
        <El
            className={classnames(className, color, text.className, {
                truncate,
                underline,
            })}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(props as any)}
        >
            {children}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                ${El} {
                    font-family: ${fontFamilies.medium};
                    font-size: ${fontSizes.xsmall};
                    font-weight: normal;
                    letter-spacing: 0.4px;
                    line-height: ${lineHeights.small};
                    text-align: ${textAlign};
                    text-transform: uppercase;
                }
            `}</style>
            {text.styles}
        </El>
    );
}

function Small({
    children,
    className,
    color = 'muted',
    El = 'p',
    textAlign = 'inherit',
    truncate,
    underline,
    ...props
}: React.PropsWithChildren<TextProps>) {
    const { fontFamilies, fontSizes, lineHeights } = useTheme();
    const text = useTextStyles();
    return (
        <El
            className={classnames(className, color, text.className, {
                truncate,
                underline,
            })}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(props as any)}
        >
            {children}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                ${El} {
                    font-family: ${El === 'strong'
                        ? fontFamilies.medium
                        : fontFamilies.regular};
                    font-size: ${fontSizes.small};
                    font-weight: normal;
                    line-height: ${lineHeights.base};
                    margin: 0;
                    text-align: ${textAlign};
                }
            `}</style>
            {text.styles}
        </El>
    );
}

function Mono({
    children,
    className,
    color = 'default',
    El = 'code',
    textAlign = 'inherit',
    truncate,
    underline,
    ...props
}: React.PropsWithChildren<TextProps>) {
    const { fontFamilies, fontSizes, lineHeights } = useTheme();
    const text = useTextStyles();
    return (
        <El
            className={classnames(className, color, text.className, {
                truncate,
                underline,
            })}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(props as any)}
        >
            {children}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                ${El} {
                    font-family: ${fontFamilies.mono};
                    font-size: ${fontSizes.mono};
                    line-height: ${lineHeights.base};
                    text-align: ${textAlign};
                }
            `}</style>
            {text.styles}
        </El>
    );
}

function Strong({
    children,
    className,
    color = 'default',
    El = 'strong',
    textAlign = 'inherit',
    truncate,
    underline,
    ...props
}: React.PropsWithChildren<TextProps>) {
    const { fontFamilies, fontSizes, lineHeights } = useTheme();
    const text = useTextStyles();
    return (
        <El
            className={classnames(className, color, text.className, {
                truncate,
                underline,
            })}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(props as any)}
        >
            {children}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                ${El} {
                    font-family: ${fontFamilies.medium};
                    font-size: ${fontSizes.base};
                    font-weight: normal;
                    line-height: ${lineHeights.base};
                    text-align: ${textAlign};
                    margin: 0;
                }
            `}</style>
            {text.styles}
        </El>
    );
}

function BaseText({
    children,
    className,
    color = 'default',
    dangerouslySetInnerHTML,
    El = 'p',
    textAlign = 'inherit',
    truncate,
    underline,
    ...props
}: React.PropsWithChildren<TextProps>) {
    const { fontFamilies, fontSizes, lineHeights } = useTheme();
    const text = useTextStyles();
    return (
        <El
            className={classnames(className, color, text.className, {
                truncate,
                underline,
            })}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(props as any)}
        >
            {dangerouslySetInnerHTML ? (
                <span dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
            ) : (
                children
            )}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                ${El} {
                    font-family: ${fontFamilies.regular};
                    font-size: ${fontSizes.base};
                    font-weight: normal;
                    line-height: ${lineHeights.base};
                    margin: 0;
                    text-align: ${textAlign};
                }
                ${El} :global(> strong) {
                    font-family: ${fontFamilies.medium};
                    font-size: ${fontSizes.base};
                    font-weight: normal;
                    line-height: ${lineHeights.base};
                    margin: 0;
                }
            `}</style>
            {text.styles}
        </El>
    );
}

function Large({
    children,
    className,
    color = 'default',
    El = 'h2',
    textAlign = 'inherit',
    truncate,
    underline,
    ...props
}: React.PropsWithChildren<TextProps>) {
    const { fontFamilies, fontSizes, lineHeights } = useTheme();
    const text = useTextStyles();
    return (
        <El
            className={classnames(className, color, text.className, {
                truncate,
                underline,
            })}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(props as any)}
        >
            {children}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                ${El} {
                    font-family: ${El === 'strong'
                        ? fontFamilies.medium
                        : fontFamilies.regular};
                    font-size: ${fontSizes.large};
                    font-weight: normal;
                    line-height: ${lineHeights.large};
                    margin: 0;
                    text-align: ${textAlign};
                }
                ${El} :global(> strong) {
                    font-family: ${fontFamilies.medium};
                    font-size: ${fontSizes.large};
                    font-weight: normal;
                    line-height: ${lineHeights.large};
                    margin: 0;
                }
            `}</style>
            {text.styles}
        </El>
    );
}

function LargeStrong({
    children,
    className,
    color = 'default',
    El = 'h2',
    textAlign = 'inherit',
    truncate,
    underline,
    ...props
}: React.PropsWithChildren<TextProps>) {
    const { fontFamilies, fontSizes, lineHeights } = useTheme();
    const text = useTextStyles();
    return (
        <El
            className={classnames(className, color, text.className, {
                truncate,
                underline,
            })}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(props as any)}
        >
            {children}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                ${El} {
                    font-family: ${fontFamilies.medium};
                    font-size: ${fontSizes.large};
                    font-weight: normal;
                    line-height: ${lineHeights.large};
                    margin: 0;
                    text-align: ${textAlign};
                }
            `}</style>
            {text.styles}
        </El>
    );
}

function XLarge({
    children,
    className,
    color = 'default',
    El = 'h1',
    textAlign = 'inherit',
    truncate,
    underline,
    ...props
}: React.PropsWithChildren<TextProps>) {
    const { fontFamilies, fontSizes, lineHeights } = useTheme();
    const text = useTextStyles();
    return (
        <El
            className={classnames(className, color, text.className, {
                truncate,
                underline,
            })}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(props as any)}
        >
            {children}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                ${El} {
                    font-family: ${fontFamilies.medium};
                    font-size: ${fontSizes.xlarge};
                    font-weight: normal;
                    line-height: ${lineHeights.xlarge};
                    margin: 0;
                    text-align: ${textAlign};
                }
            `}</style>
            {text.styles}
        </El>
    );
}

function MarketingHeadingBig({
    children,
    className,
    color = 'default',
    El = 'h1',
    textAlign = 'inherit',
    truncate,
    underline,
    ...props
}: React.PropsWithChildren<TextProps>) {
    const { fontFamilies } = useTheme();
    const text = useTextStyles();
    return (
        <El
            className={classnames(className, color, text.className, {
                truncate,
                underline,
            })}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(props as any)}
        >
            {children}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                ${El} {
                    font-family: ${fontFamilies.semibold};
                    font-size: ${pxToRem(34)};
                    font-weight: normal;
                    line-height: ${pxToRem(38)};
                    margin: 0;
                    text-align: ${textAlign};
                }
            `}</style>
            {text.styles}
        </El>
    );
}

function MarketingHeadingMedium({
    children,
    className,
    color = 'default',
    El = 'h2',
    textAlign = 'inherit',
    truncate,
    underline,
    ...props
}: React.PropsWithChildren<TextProps>) {
    const { fontFamilies } = useTheme();
    const text = useTextStyles();
    return (
        <El
            className={classnames(className, color, text.className, {
                truncate,
                underline,
            })}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(props as any)}
        >
            {children}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                ${El} {
                    font-family: ${fontFamilies.semibold};
                    font-size: ${pxToRem(28)};
                    font-weight: normal;
                    line-height: ${pxToRem(32)};
                    margin: 0;
                    text-align: ${textAlign};
                }
            `}</style>
            {text.styles}
        </El>
    );
}

function MarketingHeadingSmall({
    children,
    className,
    color = 'default',
    El = 'h3',
    textAlign = 'inherit',
    truncate,
    underline,
    ...props
}: React.PropsWithChildren<TextProps>) {
    const { fontFamilies } = useTheme();
    const text = useTextStyles();
    return (
        <El
            className={classnames(className, color, text.className, {
                truncate,
                underline,
            })}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(props as any)}
        >
            {children}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                ${El} {
                    font-family: ${fontFamilies.semibold};
                    font-size: ${pxToRem(22)};
                    font-weight: normal;
                    line-height: ${pxToRem(26)};
                    margin: 0;
                    text-align: ${textAlign};
                }
            `}</style>
            {text.styles}
        </El>
    );
}

function MarketingHeadingXSmall({
    children,
    className,
    color = 'default',
    El = 'h4',
    textAlign = 'inherit',
    truncate,
    underline,
    ...props
}: React.PropsWithChildren<TextProps>) {
    const { fontFamilies } = useTheme();
    const text = useTextStyles();
    return (
        <El
            className={classnames(className, color, text.className, {
                truncate,
                underline,
            })}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(props as any)}
        >
            {children}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                ${El} {
                    font-family: ${fontFamilies.semibold};
                    font-size: ${pxToRem(16)};
                    font-weight: normal;
                    line-height: ${pxToRem(20)};
                    margin: 0;
                    text-align: ${textAlign};
                }
            `}</style>
            {text.styles}
        </El>
    );
}

function MarketingBase({
    children,
    className,
    color = 'default',
    El = 'p',
    textAlign = 'inherit',
    truncate,
    underline,
    ...props
}: React.PropsWithChildren<TextProps>) {
    const { fontFamilies } = useTheme();
    const text = useTextStyles();
    return (
        <El
            className={classnames(className, color, text.className, {
                truncate,
                underline,
            })}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(props as any)}
        >
            {children}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                ${El} {
                    font-family: ${fontFamilies.regular};
                    font-size: ${pxToRem(16)};
                    line-height: ${pxToRem(28)};
                    margin: 0;
                    text-align: ${textAlign};
                }
            `}</style>
            {text.styles}
        </El>
    );
}

function MarketingStrong({
    children,
    className,
    color = 'default',
    El = 'strong',
    textAlign = 'inherit',
    truncate,
    underline,
    ...props
}: React.PropsWithChildren<TextProps>) {
    const { fontFamilies } = useTheme();
    const text = useTextStyles();
    return (
        <El
            className={classnames(className, color, text.className, {
                truncate,
                underline,
            })}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(props as any)}
        >
            {children}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                ${El} {
                    font-family: ${fontFamilies.semibold};
                    font-size: ${pxToRem(16)};
                    line-height: ${pxToRem(28)};
                    margin: 0;
                    text-align: ${textAlign};
                }
            `}</style>
            {text.styles}
        </El>
    );
}

function MarketingSmall({
    children,
    className,
    color = 'default',
    El = 'small',
    textAlign = 'inherit',
    truncate,
    underline,
    ...props
}: React.PropsWithChildren<TextProps>) {
    const { fontFamilies } = useTheme();
    const text = useTextStyles();
    return (
        <El
            className={classnames(className, color, text.className, {
                truncate,
                underline,
            })}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(props as any)}
        >
            {children}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                ${El} {
                    font-family: ${fontFamilies.regular};
                    font-size: ${pxToRem(12)};
                    font-weight: 400;
                    line-height: ${pxToRem(16)};
                    margin: 0;
                    text-align: ${textAlign};
                }
            `}</style>
            {text.styles}
        </El>
    );
}

export function Text({ variant = 'base', ...props }: React.PropsWithChildren<TextProps>) {
    switch (variant) {
        case 'base':
            return <BaseText {...props} />;
        case 'mono':
            return <Mono {...props} />;
        case 'xsmall':
            return <XSmall {...props} />;
        case 'small':
            return <Small {...props} />;
        case 'strong':
            return <Strong {...props} />;
        case 'large':
            return <Large {...props} />;
        case 'largestrong':
            return <LargeStrong {...props} />;
        case 'xlarge':
            return <XLarge {...props} />;
        case 'm-heading-big':
            return <MarketingHeadingBig {...props} />;
        case 'm-heading-medium':
            return <MarketingHeadingMedium {...props} />;
        case 'm-heading-small':
            return <MarketingHeadingSmall {...props} />;
        case 'm-heading-xsmall':
            return <MarketingHeadingXSmall {...props} />;
        case 'm-base':
            return <MarketingBase {...props} />;
        case 'm-strong':
            return <MarketingStrong {...props} />;
        case 'm-small':
            return <MarketingSmall {...props} />;
        default:
            return <BaseText {...props} />;
    }
}
