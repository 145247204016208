import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    size?: number;
    color?: string;
    backgroundColor?: string;
}

export function NoiseCancellationIconOn({
    size = 16,
    color = 'currentColor',
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_1_696)">
                <path
                    d="M14.5761 11.5C15.165 10.4398 15.5 9.22178 15.5 7.92631C15.5 3.82487 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.82487 0.5 7.92631C0.5 9.22178 0.835003 10.4398 1.42391 11.5"
                    stroke={color}
                />
                <path
                    d="M1.47737 11.5854C1.37034 11.3746 1.42566 11.1176 1.60997 10.9694L2.99592 9.85536C3.24343 9.65641 3.6113 9.73559 3.75503 10.0188L5.48885 13.4347C5.63259 13.7178 5.47936 14.0615 5.17266 14.1439L3.45529 14.605C3.22692 14.6663 2.98681 14.5592 2.87978 14.3484L1.47737 11.5854Z"
                    stroke={color}
                />
                <path
                    d="M14.5477 11.5854C14.6547 11.3746 14.5994 11.1176 14.4151 10.9694L13.0291 9.85536C12.7816 9.65641 12.4138 9.73559 12.27 10.0188L10.5362 13.4347C10.3925 13.7178 10.5457 14.0615 10.8524 14.1439L12.5698 14.605C12.7981 14.6663 13.0383 14.5592 13.1453 14.3484L14.5477 11.5854Z"
                    stroke={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_1_696">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
