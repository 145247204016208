// Copyright 2024 Descript, Inc

import { useMemo } from 'react';
import { atom, useRecoilState } from 'recoil';
import { ParticipantUploadState, UserUploadState } from '../types/uploadProgress';

/**
 * Holds the local user's upload state. This is an aggregation of all local recorders
 */
const userUploadState = atom<UserUploadState>({
    key: 'user-upload-state',
    default: {
        percent: 0,
        estRemaining: -1,
        name: '',
        id: '',
        status: 'initial',
        updatedAt: 0,
        totalSegments: 0,
        lastUploadedSegment: 0,
    },
});
export const useUploadingState = () => useRecoilState(userUploadState);

/**
 * Holds the values of other participants uploadState as received by PubNub
 * (Used by owner only)
 */
const participantsUploadState = atom<Record<string, ParticipantUploadState>>({
    key: 'participants-upload-state',
    default: {},
});
export const useParticipantsUploadingState = () => useRecoilState(participantsUploadState);

/**
 * A hook that returns an array containing the local user's upload state plus the other participant's.
 * For guests this would return an array with one item, their own state.
 */
export const useAllUploadingStates = () => {
    const [userUploadingState] = useUploadingState();
    const [participantsUploadState] = useParticipantsUploadingState();

    const allStates = useMemo(
        () => [userUploadingState, ...Object.values(participantsUploadState)],
        [userUploadingState, participantsUploadState],
    );

    return allStates;
};
