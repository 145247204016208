import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function NoiseCancellationIcon({
    color = 'currentColor',
    size = 16,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_168_65)">
                <path d="M1 1L15 15" stroke={color} />
                <path
                    d="M1.47737 11.5854C1.37034 11.3746 1.42566 11.1176 1.60997 10.9694L2.99592 9.85536C3.24343 9.65641 3.6113 9.73559 3.75503 10.0188L5.48885 13.4347C5.63259 13.7178 5.47936 14.0615 5.17266 14.1439L3.45529 14.605C3.22692 14.6663 2.98681 14.5592 2.87978 14.3484L1.47737 11.5854Z"
                    stroke={color}
                />
                <path
                    d="M13.5 13.5L13.3198 14.0342C13.1526 14.393 12.7774 14.5751 12.4206 14.4708L11.079 14.0785C10.5999 13.9384 10.3604 13.3537 10.585 12.8719L11.2622 11.4189"
                    stroke={color}
                />
                <path
                    d="M1.42391 11.5C0.835003 10.4398 0.5 9.22178 0.5 7.92631C0.5 5.89382 1.32461 4.05204 2.66062 2.71108M15.1007 10.3233C15.3596 9.57131 15.5 8.76504 15.5 7.92631C15.5 3.82487 12.1421 0.5 8 0.5C7.3074 0.5 6.63672 0.59296 6 0.766967"
                    stroke={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_168_65">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
