// Copyright 2024 Descript, Inc
import { put, call, select } from 'typed-redux-saga';
import { getRecordingErrorInfo } from './errorHelpers';
import { trackError } from '../../Utilities/ErrorTracker';
import { ErrorCategory } from '@descript/errors';
import { VideoRecorderError } from './errors';
import { SetSelectedMediaReferenceIdsAction } from '../../Actions/EditorActions';
import { HideEditorRecordingPopoverAction } from '../../Actions/EditorRecordingActions';
import { HandleErrorCallback } from '../callbackTypes';
import {
    MediaReferenceId,
    ReplaceTextErrorType,
    getAttributedStringLength,
} from '@descript/descript-model';
import { DynamicRecordingTarget } from '@descript/recorder-base';
import { finalizeDynamicRecordingTarget } from '../DocumentManager/dynamicRecordingTargets';
import { getNonInteractiveDocument } from '../../Reducers/Selectors/DocumentSelectors';

type WorkflowPhase = 'setup' | 'recording' | 'importing' | 'transcribing' | 'verification';

function getErrorTypeFromPhase(phase: WorkflowPhase) {
    return phase === 'setup'
        ? 'recorder-setup'
        : phase === 'recording'
          ? 'recorder-workflow-v2'
          : phase === 'importing'
            ? 'recorder-add-to-composition-v2'
            : phase === 'transcribing'
              ? 'recorder-transcription-v2'
              : 'recorder-verification-v2';
}

/**
 * Unwrap custom errors to inner errors to provide more useful stack trace information
 */
function unwrapRecorderError(error: Error): Error {
    if (VideoRecorderError.isVideoRecorderError(error) && error.innerError) {
        return error.innerError;
    }
    return error;
}

export type ErrorMessageAlerter = {
    showErrorBox: ({ title, content }: { title: string; content: string }) => void;
    showMessageBox: (options: object) => number | undefined;
};

// Some errors are what we consider "expected errors". These errors do not get counted as failures
// in our recording metrics (i.e. if a user unplugs their microphone while recording, the recording
// will fail, but we will not count that as a failure)
export function isExpectedError(error: Error) {
    const expectedErrors: (string | undefined)[] = [
        'user_error',
        'permission_error',
        'hardware_error',
        'memory_error',
        'os_error',
        'device_error',
        'gpu_error',
    ];
    return expectedErrors.includes(getErrorType(error));
}

export function getErrorType(error: Error) {
    if (
        VideoRecorderError.isVideoRecorderError(error) &&
        error.errorObject &&
        'error_type' in error.errorObject
    ) {
        return error.errorObject?.error_type;
    }
    return undefined;
}

export function getErrorHandler(
    projectId: string,
    recordingId: string | undefined,
    phase: WorkflowPhase,
    messager: ErrorMessageAlerter,
    platform: 'mac' | 'win' | 'web',
): HandleErrorCallback {
    return function* (
        error: Error,
        target?: DynamicRecordingTarget,
        mediaReferenceIds?: MediaReferenceId[],
    ) {
        const recordingErrorInfo = getRecordingErrorInfo(error, platform);
        const unwrappedError = unwrapRecorderError(error);
        const extraData = yield* call(getErrorExtraData, unwrappedError, projectId, target);

        // log to Sentry
        trackError(unwrappedError, getErrorTypeFromPhase(phase), {
            category: ErrorCategory.Recording,
            extra: {
                projectId,
                recordingId,
                targetType: target?.type,
                ...extraData,
            },
        });
        if (phase === 'setup') {
            // Close the config modal
            yield* put(HideEditorRecordingPopoverAction({ projectId }));
            // Show user friendly error message
            messager.showErrorBox({
                title: 'Unable To Launch Recorder',
                content:
                    'We are sorry, but we seem to be unable to launch the recorder. Please update Descript if you are not on the latest version, otherwise please try restarting and reach out to Support if the problem persists.',
            });
        } else if (phase === 'recording') {
            // show informative error messaging in UI
            if (recordingErrorInfo.url) {
                const selectedButton = messager.showMessageBox({
                    type: 'error',
                    title: recordingErrorInfo.title,
                    message: recordingErrorInfo.content,
                    detail: recordingErrorInfo.detail,
                    buttons: ['OK', 'Troubleshooting help'],
                    defaultId: 0,
                });

                if (selectedButton === 1) {
                    window.open(recordingErrorInfo.url, '_blank');
                }
            } else {
                messager.showErrorBox({
                    ...recordingErrorInfo,
                });
            }
        } else if (phase === 'importing' || phase === 'verification') {
            // If there was an error adding the media to the card/script, just show the media in the media library
            yield* put(
                SetSelectedMediaReferenceIdsAction({
                    projectId,
                    selectedMediaReferenceIds: new Set(mediaReferenceIds),
                }),
            );

            // Show user friendly error message
            messager.showErrorBox({
                title: 'Processing Error',
                content:
                    "Your media recorded successfully, but we had an issue adding it to the composition. It is available in your project's media library.",
            });
        } else if (phase === 'transcribing') {
            // Show user friendly error message
            messager.showErrorBox({
                title: 'Transcription Error',
                content:
                    'Your media recorded successfully and was added to your composition, but an issue occurred while transcribing.',
            });
        }
    };
}

export function handleCardStepFailure(messager: ErrorMessageAlerter) {
    messager.showErrorBox({
        title: 'Scene Stepping Error',
        content:
            'Your media is still recording, but we were unable to apply the scene-stepping data.',
    });
}

function* getErrorExtraData(
    error: Error,
    projectId: string,
    dynamicTarget?: DynamicRecordingTarget,
): object {
    try {
        const errorExtraData: { [key: string]: unknown } = {};
        if (error.message === ReplaceTextErrorType.InvalidInsertLocation && dynamicTarget) {
            const target = yield* call(
                finalizeDynamicRecordingTarget,
                projectId,
                dynamicTarget,
            );
            const doc = yield* select(getNonInteractiveDocument, projectId);
            if (target.type === 'replace-selection') {
                errorExtraData.replaceRange = target.range;
            } else if (target.type === 'script') {
                errorExtraData.insertLoc = target.insertLocation;
            } else if (target.type === 'card') {
                errorExtraData.cardId = target.cardId;
            }

            errorExtraData.docLength = getAttributedStringLength(doc, target.compositionId);
        }
        return errorExtraData;
    } catch (e) {
        return { errorFetchingExtraData: e };
    }
}
