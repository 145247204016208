// Copyright 2023 Descript, Inc

import { useEffect, useLayoutEffect, useRef } from 'react';

import * as React from 'react';
import { createContext } from '@radix-ui/react-context';

import { useEventCallback } from './useEventCallback';
import { UseThrottleWithRafOptions, useThrottleWithRaf } from './useThrottleWithRaf';
import { fastDeepEqual } from '@descript/fast-deep-equal';

export interface MeasureRect {
    width: number;
    height: number;
}

type OnResize = (entry: ResizeObserverEntry) => void;

interface ObserveOptions {
    el: Element;
    onResize: OnResize;
    options?: ResizeObserverOptions | undefined;
}

const [ResizeObserverContextProvider, useResizeObserverContext] = createContext<{
    observe: (options: ObserveOptions) => void;
    disconnect: (el: Element, onResize: OnResize) => void;
}>('ResizeObserver');

export const useSharedResizeObserver = useResizeObserverContext;

/**
 * Creates and manages a shared ResizeObserver that components can hook into via useResizeObserver.
 *
 * Using 1 vs many ResizeObservers is a performance optimization.
 * https://groups.google.com/a/chromium.org/forum/#!msg/blink-dev/z6ienONUb5A/F5-VcUZtBAAJ
 */
export function ResizeObserverProvider({ children }: { children: React.ReactNode }) {
    const resizeObserver = useRef<ResizeObserver | undefined>();
    const observers = useRef(new Map<Element, OnResize[]>());

    const observe = useEventCallback((option: ObserveOptions) => {
        const currentResizeFn = observers.current.get(option.el);

        if (currentResizeFn) {
            observers.current.set(option.el, [...currentResizeFn, option.onResize]);
        } else {
            observers.current.set(option.el, [option.onResize]);
        }

        resizeObserver.current?.observe(option.el, option.options);
    });

    const disconnect = useEventCallback((el: Element, onResize: OnResize) => {
        const resizeFns = observers.current.get(el) || [];
        const newResizeFns = resizeFns.filter((fn) => fn !== onResize);

        if (newResizeFns.length === 0) {
            resizeObserver.current?.unobserve(el);
            observers.current.delete(el);
        } else {
            observers.current.set(el, newResizeFns);
        }
    });

    useLayoutEffect(() => {
        resizeObserver.current = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const resizeFns = observers.current.get(entry.target);

                if (resizeFns) {
                    resizeFns.forEach((resizeFn) => {
                        resizeFn(entry);
                    });
                }
            }
        });

        return () => {
            resizeObserver.current?.disconnect();
        };
    }, []);

    return (
        <ResizeObserverContextProvider observe={observe} disconnect={disconnect}>
            {children}
        </ResizeObserverContextProvider>
    );
}

interface UseResizeObserverOptions {
    ref:
        | React.RefObject<Element>
        | Element
        | React.RefObject<Element>[]
        | Element[]
        | null
        | undefined;
    onResize: OnResize;
    box?: ResizeObserverOptions['box'] | undefined;
    minimumMsPerTick?: UseThrottleWithRafOptions['minimumMsPerTick'];
    /** @default true */
    throttle?: boolean;
}

/**
 * Hook into the ResizeObserverProvider to get notified when an element resizes.
 */
export function useResizeObserver({
    ref,
    box,
    onResize,
    minimumMsPerTick,
    throttle = true,
}: UseResizeObserverOptions) {
    const context = useResizeObserverContext('useResizeObserver');
    const [throttledOnResize, cancelThrottledOnResize] = useThrottleWithRaf({
        update: onResize,
        minimumMsPerTick,
    });
    const onResizeDefault = useEventCallback(onResize);
    const onUpdate = throttle ? throttledOnResize : onResizeDefault;

    useEffect(() => {
        if (!ref) {
            return;
        }

        const elements = (Array.isArray(ref) ? ref : [ref])
            .filter(Boolean)
            .map((item) => ('current' in item ? item.current : item));

        for (const el of elements) {
            if (el) {
                context.observe({ el, onResize: onUpdate, options: { box } });
            }
        }

        return () => {
            cancelThrottledOnResize();

            for (const el of elements) {
                if (el) {
                    context.disconnect(el, onUpdate);
                }
            }
        };
    }, [context, ref, box, cancelThrottledOnResize, onUpdate]);
}

/**
 * Get the size of the element as reactive state.
 * NOTE: This will re-render the component on every resize event.
 */
export function useMeasure(
    ref: React.RefObject<Element> | Element | null | undefined,
    options: Omit<UseResizeObserverOptions, 'ref' | 'onResize'> = {},
) {
    const [size, setSize] = React.useState<MeasureRect>({
        width: 0,
        height: 0,
    });

    useResizeObserver({
        ...options,
        ref,
        onResize: (entry) => {
            setSize((oldSize) => {
                const newValue = {
                    width: entry.contentRect.width,
                    height: entry.contentRect.height,
                };

                return fastDeepEqual(newValue, oldSize) ? oldSize : newValue;
            });
        },
    });

    return size;
}

/**
 * Get the size of the element as a non-reactive ref.
 * NOTE: This will won't re-render the component on every resize event.
 */
export function useMeasureRef(
    ref: React.RefObject<Element> | Element | null | undefined,
    options: Omit<UseResizeObserverOptions, 'ref' | 'onResize'> = {},
) {
    const sizeRef = React.useRef<MeasureRect>({
        width: 0,
        height: 0,
    });

    useResizeObserver({
        ...options,
        ref,
        onResize: (entry) => {
            sizeRef.current = {
                width: entry.contentRect.width,
                height: entry.contentRect.height,
            };
        },
    });

    return sizeRef;
}

/** Get the full bounding client rect of an element without reflows */
export function getFullBoundingClientRect(el: Element, cb: (rect: DOMRect) => void) {
    const observer = new IntersectionObserver(([floatingRect]) => {
        if (floatingRect) {
            cb(floatingRect.boundingClientRect);
        }
        observer.disconnect();
    });

    observer.observe(el);
}

export function getFullBoundingClientRectAsync(el: Element) {
    return new Promise<DOMRect>((resolve) => {
        getFullBoundingClientRect(el, resolve);
    });
}

export function useFullBoundingClientRect(
    ref: React.RefObject<Element> | Element | null | undefined,
) {
    const [rect, setRect] = React.useState<Omit<DOMRect, 'toJSON'>>({
        width: 0,
        height: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        x: 0,
        y: 0,
    });

    useResizeObserver({
        ref,
        onResize: (entry) => getFullBoundingClientRect(entry.target, setRect),
    });

    return rect;
}

export function useFullBoundingClientRectRef(
    ref: React.RefObject<Element> | Element | null | undefined,
) {
    const rectRef = React.useRef<Omit<DOMRect, 'toJSON'>>({
        width: 0,
        height: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        x: 0,
        y: 0,
    });

    useResizeObserver({
        ref,
        onResize: (entry) => {
            rectRef.current = entry.contentRect;
            getFullBoundingClientRect(entry.target, (rect) => (rectRef.current = rect));
        },
    });

    return rectRef;
}
