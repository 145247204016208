// Copyright 2022 Descript, Inc

import { VideoRecorderError } from './errors';
import { genericRecorderTroubleshootingArticle } from '../../App/Constants/DescriptLinks';
import { getErrorMessage } from './ErrorMessage';

export type RecordingPhase = 'launch' | 'before' | 'during';

const defaultRecordingErrorMessage =
    'Something went wrong with your recording. Please check the recorder troubleshooting guide for help.';

const unknownRecordingErrorMessage = 'Recording failed.\n\n' + defaultRecordingErrorMessage;

export function recordingPhaseToFormattedString(recordingPhase: RecordingPhase): string {
    switch (recordingPhase) {
        case 'launch':
            return 'at launch';

        case 'before':
            return 'before starting';

        case 'during':
            return 'while recording';
    }
}

function recordingPhaseToTitle(recordingPhase: RecordingPhase): string {
    switch (recordingPhase) {
        case 'launch':
            return 'Failed to launch native recorder process';

        case 'before':
            return 'Failed to start recording';

        case 'during':
            return 'Recording failed';
    }
}

function getMessageFromError(error: VideoRecorderError): string | undefined {
    if (error.errorMessage.includes('CannotAddAggregateDeviceToAggregateDevice')) {
        return (
            'Cannot record system audio with aggregate input device\n\n' +
            'The quick recorder can record from an aggregate input or it can record system audio, but not both at the same time.\n' +
            'Please either disable system audio, choose a different input, or use the Editor recorder. If the problem persists, contact Descript support.'
        );
    }
    if (error.errorMessage.includes('OutOfStorageSpace')) {
        return 'Out of disk storage space, cannot record more media. Please clear up some space on your hard drive to make more room.';
    }
    if (error.errorMessage.startsWith('DeviceDisconnected')) {
        // TODO COM-9136: Add help center article for connection issues
        return 'Unable to continue recording due to a connection issue with one of your devices. Please ensure your device is properly connected and try again.';
    }
    if (error.errorMessage?.startsWith('FailedToStart')) {
        return `Unable to start recording due to an issue with one of your devices. Please try again, or check the recorder troubleshooting guide for help if the problem persists.`;
    }
    if (error.errorMessage?.startsWith('InternalClockError')) {
        let deviceStr = "one of your devices'";
        if (error.errorMessage.split('DescriptDeviceType.').length > 1) {
            const device = error.errorMessage.split('DescriptDeviceType.')[1]!.replace(')', '');
            deviceStr = `your ${device} device's`;
        }
        return `Unable to start recording due to an error with ${deviceStr} internal clock. This is a hardware issue, and not an issue with Descript's software. To fix the issue, please restart your computer or try recording with a different device.`;
    }
    if (
        error.errorMessage?.startsWith(
            'StoppedReceivingSamplesFromDataOutput(screen_recorder_mac.DescriptDeviceType.screen)',
        )
    ) {
        // TODO COM-9302: Add help center article for Screen Capture Kit
        return 'Unable to continue recording due to an issue with your system\'s screen capture API. Please try again. If the problem persists, disabling the "Use ScreenCaptureKit for screen recordings" option in the recorder settings menu may help.';
    }
    return undefined;
}

function getIsUserError(error: VideoRecorderError): boolean | 'unknown' {
    if (error.errorMessage.includes('OutOfStorageSpace')) {
        return true;
    }
    return 'unknown';
}

export function getRecordingErrorInfo(
    error: unknown,
    platform: 'mac' | 'win' | 'web',
): {
    title: string;
    content: string;
    detail?: string;
    url?: string;
    isUserError: boolean | 'unknown';
    errorObject?: Error | undefined;
} {
    if (VideoRecorderError.isVideoRecorderError(error)) {
        const { recordingPhase } = error;

        let messageFromError = undefined;
        if (platform === 'win') {
            if (VideoRecorderError.isVideoRecorderError(error)) {
                messageFromError = getErrorMessage(error.errorObject);
            } else {
                messageFromError = getMessageFromError(error);
            }
        } else {
            messageFromError = getMessageFromError(error);
        }

        if (messageFromError) {
            return {
                title: recordingPhaseToTitle(recordingPhase),
                content: messageFromError,
                isUserError: getIsUserError(error),
            };
        } else {
            return {
                title: recordingPhaseToTitle(recordingPhase),
                // from the docs: some platforms do not show the title,
                // so we repeat it here
                content:
                    recordingPhaseToTitle(recordingPhase) +
                    '\n\n' +
                    defaultRecordingErrorMessage,
                detail: error.errorMessage,
                url: genericRecorderTroubleshootingArticle,
                isUserError: getIsUserError(error),
            };
        }
    }

    return {
        title: 'Unknown recorder error',
        content: unknownRecordingErrorMessage,
        url: genericRecorderTroubleshootingArticle,
        isUserError: 'unknown',
    };
}
