// Copyright 2020 Descript, Inc

import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { DescriptError, ErrorCategory } from '@descript/errors';

function makeErrorForLater(): Error {
    return new DescriptError('', ErrorCategory.AppArchitecture);
}

/**
 * Creates a function that is memoized a single time. This is useful to pass as event
 * handlers where the deps are changing _without_ triggering a re-render.
 *
 * See https://reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback
 *
 * This pattern may get incorporated into react proper as `useEvent` via this RFC:
 * https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
 */
export function useEventCallback<Args extends unknown[], Res>(
    fn: (...args: Args) => Res,
): (...args: Args) => Res {
    // We want an error with a trace of the callsite, otherwise it's hard to debug
    const [error] = useState<Error | undefined>(
        process.env.NODE_ENV === 'production'
            ? // but, as a perf improvement, don't generate this error for prod builds
              undefined
            : makeErrorForLater,
    );
    const ref = useRef<(...args: Args) => Res>(() => {
        const err = error || new DescriptError('', ErrorCategory.AppArchitecture);
        err.message = 'Cannot call an event handler while rendering.';
        throw err;
    });

    useLayoutEffect(() => {
        ref.current = fn;
    });

    return useCallback((...args: Args): Res => {
        return ref.current(...args);
    }, []);
}

/* eslint-enable @typescript-eslint/no-explicit-any */
