import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function EmojiIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_1_324)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM7.99992 12C6.13608 12 4.56998 10.7252 4.12594 9H5.17062C5.58246 10.1652 6.6937 11 7.99992 11C9.30614 11 10.4174 10.1652 10.8292 9H11.8739C11.4299 10.7252 9.86376 12 7.99992 12ZM11.5 6.125C11.5 6.60825 11.1082 7 10.625 7C10.1418 7 9.75 6.60825 9.75 6.125C9.75 5.64175 10.1418 5.25 10.625 5.25C11.1082 5.25 11.5 5.64175 11.5 6.125ZM5.125 7C5.60825 7 6 6.60825 6 6.125C6 5.64175 5.60825 5.25 5.125 5.25C4.64175 5.25 4.25 5.64175 4.25 6.125C4.25 6.60825 4.64175 7 5.125 7Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_1_324">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
