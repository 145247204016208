// Copyright 2024 Descript, Inc

import { trackEvent } from '@descript/analytics';
import {
    RecordingAnalyticsEvents,
    RecordingEventProps,
    WebRecordingSession,
} from '@descript/recorder-base';

function fixedPrecisionNumber(num: number, fractionalDigits: number): number {
    return Number(num.toFixed(fractionalDigits));
}

export function trackRecordingAnalyticsEvent(
    session: WebRecordingSession,
    event: RecordingAnalyticsEvents,
    extraProps?: Record<string, unknown>,
    recorderId?: string,
) {
    let screenMeta, videoMeta, audioMeta;

    if (recorderId) {
        const rec = session.recorders.get(recorderId);
        switch (rec?.mode) {
            case 'screen':
                screenMeta = rec;
                break;
            case 'video':
                videoMeta = rec;
                break;
            case 'audio':
                audioMeta = rec;
                break;
            default:
                break;
        }
    } else if (session?.recorders?.size) {
        for (const rec of session.recorders.values()) {
            switch (rec.mode) {
                case 'screen':
                    screenMeta = rec;
                    break;
                case 'video':
                    videoMeta = rec;
                    break;
                case 'audio':
                    audioMeta = rec;
                    break;
                default:
                    break;
            }
        }
    }

    const kind = screenMeta ? 'screen' : videoMeta ? 'camera' : audioMeta ? 'audio' : 'unknown';
    const sessionProps: RecordingEventProps = {
        session_id: session?.sessionId,
        target: session?.target?.type,
        kind,
        // Include all of these if not a specific recorder
        has_microphone: recorderId
            ? undefined
            : (videoMeta?.hasAudio ?? audioMeta?.hasAudio ?? false),
        has_camera: recorderId ? undefined : (videoMeta?.hasVideo ?? false),
        has_screen: recorderId ? undefined : (screenMeta?.hasVideo ?? false),
        has_computer_audio: recorderId ? undefined : (screenMeta?.hasAudio ?? false),
        transcription:
            (session?.microphoneTrackSettings?.transcriptionEnabled ||
                session?.computerAudioTrackSettings?.transcriptionEnabled) ??
            false,
        studio_sound:
            (session?.microphoneTrackSettings?.studioSoundEnabled ||
                session?.computerAudioTrackSettings?.studioSoundEnabled) ??
            false,
        ...(extraProps?.countdown !== undefined ? { countdown: !!extraProps.countdown } : {}),
    };

    const audioProps: Partial<RecordingEventProps> = {
        has_microphone: sessionProps.has_microphone ?? audioMeta?.hasAudio,
        audio_size_mb: audioMeta ? fixedPrecisionNumber(audioMeta.size_mb ?? 0, 4) : undefined,
        audio_echo_cancellation: audioMeta?.audioSettings?.echoCancellation,
    };

    const videoProps: Partial<RecordingEventProps> = {
        has_camera: sessionProps.has_camera ?? videoMeta?.hasVideo,
        default_camera_resolution: videoMeta
            ? `${videoMeta?.videoSettings?.width}x${videoMeta?.videoSettings?.height}`
            : undefined,
        camera_height: videoMeta?.videoSettings?.height,
        camera_width: videoMeta?.videoSettings?.width,
        video_size_mb: videoMeta ? fixedPrecisionNumber(videoMeta.size_mb ?? 0, 4) : undefined,
        // Audio props
        has_microphone: videoMeta?.hasAudio,
        audio_echo_cancellation: videoMeta?.audioSettings?.echoCancellation,
    };

    const screenProps: Partial<RecordingEventProps> = {
        has_screen: sessionProps.has_screen ?? screenMeta?.hasVideo,
        default_screen_resolution: screenMeta
            ? `${screenMeta?.videoSettings?.width}x${screenMeta?.videoSettings?.height}`
            : undefined,
        screen_height: screenMeta?.videoSettings?.height,
        screen_width: screenMeta?.videoSettings?.width,
        screen_size_mb: screenMeta
            ? fixedPrecisionNumber(screenMeta.size_mb ?? 0, 4)
            : undefined,
        // Audio props
        has_computer_audio: sessionProps.has_computer_audio ?? screenMeta?.hasAudio,
        screen_echo_cancellation: screenMeta?.audioSettings?.echoCancellation,
    };

    const eventProps: RecordingEventProps = {
        ...sessionProps,
        ...audioProps,
        ...videoProps,
        ...screenProps,
        ...(process.env.IS_ROOMS ? { build: 'rooms' } : {}),
    };

    if (
        ![
            RecordingAnalyticsEvents.recording_started,
            RecordingAnalyticsEvents.recorder_started,
        ].includes(event)
    ) {
        const duration_sec = Math.max(
            audioMeta?.duration_sec ?? 0,
            videoMeta?.duration_sec ?? 0,
            screenMeta?.duration_sec ?? 0,
        );

        if (duration_sec > 0) {
            eventProps.duration_sec = fixedPrecisionNumber(duration_sec, 2);
        }
        eventProps.audio_size_mb = audioMeta
            ? fixedPrecisionNumber(audioMeta.size_mb ?? 0, 4)
            : undefined;
        eventProps.video_size_mb = videoMeta
            ? fixedPrecisionNumber(videoMeta.size_mb ?? 0, 4)
            : undefined;
        eventProps.screen_size_mb = screenMeta
            ? fixedPrecisionNumber(screenMeta.size_mb ?? 0, 4)
            : undefined;
    }

    trackEvent(event, { ...eventProps, ...extraProps });
}
