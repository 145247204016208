// Copyright 2024 Descript, Inc
import Bowser from 'bowser';

export const isAndroidChrome = (browserName?: string) => {
    const browser = Bowser.parse(navigator.userAgent);
    const name = browserName ?? browser?.browser?.name;
    return (
        name === 'Chrome' &&
        browser?.platform?.type === 'mobile' &&
        browser?.os?.name === 'Android'
    );
};

export const isAndroid = () => {
    const browser = Bowser.parse(navigator.userAgent);
    return browser?.platform?.type === 'mobile' && browser?.os?.name === 'Android';
};

export const isFirefox = () => {
    const browser = Bowser.parse(navigator.userAgent);
    return browser?.browser?.name === 'Firefox';
};

export const isIOSMobile = () => {
    const browser = Bowser.parse(navigator.userAgent);
    return (
        browser.platform?.vendor === 'Apple' &&
        ['mobile', 'tablet'].includes(browser.platform?.type) &&
        navigator.maxTouchPoints > 0 &&
        typeof TouchEvent !== 'undefined'
    );
};

export const isSafari = (minVersion: number = 1, maxVersion: number = 100) => {
    const browser = Bowser.parse(navigator.userAgent);
    /**
     * Safari stopped reporting its version when running in a WebView on iOS15 an above.
     * In this case we can fallback to the OS version, since Safari on iOS
     * _usually_ matches the OS version anyway (not necessarily the patch version).
     * This avoids trying to destructure from undefined, which would result in a client exception.
     * See https://github.com/lancedikson/bowser/issues/499
     */
    const version = browser?.browser?.version ?? browser?.os?.version;
    const [major] = (version || '').split('.').map((n) => parseInt(n, 10));
    if (!major) {
        return browser?.browser?.name === 'Safari';
    }
    return browser?.browser?.name === 'Safari' && major >= minVersion && major <= maxVersion;
};

export const isAppleTabletSafari = () => {
    const browser = Bowser.parse(navigator.userAgent);
    return (
        browser?.platform?.vendor === 'Apple' &&
        browser?.browser?.name === 'Safari' &&
        navigator.maxTouchPoints > 0 &&
        ['desktop', 'tablet'].includes(browser?.platform?.type)
    );
};
