// Copyright © 2024 Descript, Inc. All rights reserved.
import { ReactNode, useEffect, useState } from 'react';
import { createContext } from '@radix-ui/react-context';
import { hasCommandModifier, DomModifiers } from './KeyboardEvent';
import { produce } from 'immer';

/**
 * Standard DOM modifiers plus a synthetic `cmdKey` defined as metaKey on macOS and ctrlKey otherwise
 */
export type Modifiers = Readonly<
    DomModifiers & {
        cmdKey: boolean;
    }
>;

const defaultValue: Modifiers = {
    altKey: false,
    shiftKey: false,
    ctrlKey: false,
    metaKey: false,
    cmdKey: false,
} as const;

const [Provider, useContext] = createContext<Modifiers>('ModifiersContext');
export const useModifierKeys = useContext;

export function ModifierKeysProvider({ children }: { children: ReactNode }) {
    const [state, setState] = useState<Modifiers>(defaultValue);

    useEffect(() => {
        const updateModifiersState = (event: DomModifiers) => {
            setState((currentState) =>
                produce(currentState, (draft) => {
                    draft.altKey = event.altKey;
                    draft.shiftKey = event.shiftKey;
                    draft.ctrlKey = event.ctrlKey;
                    draft.metaKey = event.metaKey;
                    draft.cmdKey = hasCommandModifier(event);
                }),
            );
        };
        const clearModifiersState = () => {
            setState(defaultValue);
        };

        // Some app (like cleanshot) will take control but not move focus away from the window
        // This means that we won't get the blur event or the keyup event so we have to listen
        // for pointerup events as well. This makes it so that when the user starts interacting with
        // Descript again we reset the modifier state.
        window.addEventListener('pointerup', updateModifiersState, {
            capture: true,
            passive: true,
        });
        window.addEventListener('keydown', updateModifiersState, {
            capture: true,
            passive: true,
        });
        window.addEventListener('keyup', updateModifiersState, {
            capture: true,
            passive: true,
        });
        window.addEventListener('blur', clearModifiersState);

        return () => {
            window.removeEventListener('pointerup', updateModifiersState, { capture: true });
            window.removeEventListener('keydown', updateModifiersState, { capture: true });
            window.removeEventListener('keyup', updateModifiersState, { capture: true });
            window.removeEventListener('blur', clearModifiersState);
        };
    }, []);

    return <Provider {...state}>{children}</Provider>;
}
