import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    size?: number;
}

export function AddEmojiIcon({ size = 24 }: React.PropsWithChildren<Props>) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2354_51031)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.3264 5C14.7583 5.90926 15 6.9264 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C9.0736 1 10.0907 1.24169 11 1.67363V0.581517C10.0736 0.206507 9.0609 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 6.9391 15.7935 5.92643 15.4185 5H14.3264ZM4.12594 9C4.56998 10.7252 6.13608 12 7.99992 12C9.86376 12 11.4299 10.7252 11.8739 9H10.8292C10.4174 10.1652 9.30614 11 7.99992 11C6.6937 11 5.58246 10.1652 5.17062 9H4.12594ZM10.625 7C11.1082 7 11.5 6.60825 11.5 6.125C11.5 5.64175 11.1082 5.25 10.625 5.25C10.1418 5.25 9.75 5.64175 9.75 6.125C9.75 6.60825 10.1418 7 10.625 7ZM6 6.125C6 6.60825 5.60825 7 5.125 7C4.64175 7 4.25 6.60825 4.25 6.125C4.25 5.64175 4.64175 5.25 5.125 5.25C5.60825 5.25 6 5.64175 6 6.125Z"
                    fill="#343434"
                />
                <path d="M14 0V4" stroke="#343434" />
                <path d="M16 1.89844H12" stroke="#343434" />
            </g>
            <defs>
                <clipPath id="clip0_2354_51031">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
