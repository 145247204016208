// Copyright © 2024 Descript, Inc. All rights reserved.

import { useEffect, useState } from 'react';

export function useDebounce<T>(value: T, delay: number): T {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        if (delay > 0) {
            const timeout = window.setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            return () => {
                window.clearTimeout(timeout);
            };
        } else {
            setDebouncedValue(value);
        }
        return undefined;
    }, [delay, value]);
    return debouncedValue;
}
