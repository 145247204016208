// Copyright 2024 Descript, Inc

// eslint-disable-next-line no-restricted-imports
import { actionCreatorFactory } from 'typescript-fsa';

const actionCreator = actionCreatorFactory('WEB_RECORDING_RECOVERY');

export const ManualRecoveryAction = actionCreator<{ sessionId: string }>('MANUAL_RECOVERY');

export const AutoRecoveryAction = actionCreator<{ reason: string }>('AUTO_RECOVERY');
