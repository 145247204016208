import * as React from 'react';

/** Suspends until the dep array is defined. This hook must be used inside a Suspense boundary */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useLoading(deps: any[]) {
    // A ref to the currently promise being awaited
    const promiseRef = React.useRef<Promise<void>>();
    // A ref the functions that resolves the promiseRef
    const resolveRef = React.useRef<() => void>();

    // If any of the deps haven't resolved yet, we need to suspend
    if (deps.some((dep) => Boolean(dep) === false)) {
        // If we don't have a promise, create one
        if (!promiseRef.current) {
            promiseRef.current = new Promise<void>((resolve) => {
                resolveRef.current = resolve;
            });
        }

        // Throw the promise and trigger the Suspense boundary
        throw promiseRef.current;
    }

    // If we get here than all the deps have resolved,
    // so we can resolve the promise
    if (promiseRef.current) {
        resolveRef.current?.();
        promiseRef.current = undefined;
        resolveRef.current = undefined;
    }
}
