import { useLocalSessionId, useScreenShare } from '@daily-co/daily-react';

import { useOrderedParticipantIds } from '@contexts/ParticipantsProvider';

export const useScreens = () => {
    const localSessionId = useLocalSessionId();
    const orderedParticipantIds = useOrderedParticipantIds();
    const { screens } = useScreenShare();

    return screens.filter(
        (screen) =>
            orderedParticipantIds.includes(screen.session_id) ||
            screen.session_id === localSessionId,
    );
};
