// Copyright 2019 Descript, Inc

import { useCallback, useEffect, useRef, useState } from 'react';
import { raf } from '@react-spring/rafz';

import { ReactNull } from './ReactNull';
import { DescriptError, ErrorCategory } from '@descript/errors';

export interface UseThrottleWithRafOptions<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Fn extends (...args: any[]) => void = (...args: any[]) => void,
> {
    /** Throttled calls to the function. React events automatically persisted. No need for useCallback or useEventCallback */
    update: Fn;
    onEvery?: (willProcess: boolean, ...args: Parameters<Fn>) => void;
    minimumMsPerTick?: number;
    /**
     * Prevent the default action when `update` is called.
     * This is useful for preventing the default behavior of drag events so
     * onDragOver and onDrop can be called.
     */
    preventDefault?: boolean;
}

/**
 * Throttles calls to the `update` function using requestAnimationFrame
 * @param update
 * @param onEvery - if defined will be called on each call. Can be useful if we
 * still need to do work on each call, e.g., `event.stopPropagation()` or
 * `event.persist()`
 * @param minimumMsPerTick - if defined, will wait at least `minimumMsPerTick`
 * milliseconds between each call to `update`. If not the function will be called
 * at the refresh rate of the monitor.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useThrottleWithRaf<Fn extends (...args: any[]) => void>({
    update,
    onEvery,
    minimumMsPerTick,
    preventDefault,
}: UseThrottleWithRafOptions<Fn>): [(...args: Parameters<Fn>) => void, () => void] {
    const lastTick = useRef(performance.now());
    const updateRef = useRef<Fn>(update);
    const argRef = useRef<Parameters<Fn> | null>(ReactNull);
    const isRunningRef = useRef(false);

    const doUpdate = useCallback(() => {
        const now = performance.now();

        if (minimumMsPerTick !== undefined && now - lastTick.current < minimumMsPerTick) {
            // returning true runs the rafz again
            return true;
        }

        isRunningRef.current = false;
        lastTick.current = now;

        if (argRef.current === ReactNull) {
            throw new DescriptError(
                'Arg ref should never be null when executing update',
                ErrorCategory.AppArchitecture,
            );
        }

        updateRef.current(...argRef.current);
        // and false stops the loop
        return false;
    }, [lastTick, minimumMsPerTick]);

    const throttledFn = useCallback(
        (...args: Parameters<Fn>) => {
            if (onEvery) {
                onEvery(isRunningRef.current, ...args);
            }

            // Record the latest arguments to the throttled function
            argRef.current = args;

            args.map((arg) => {
                if (
                    arg &&
                    typeof arg === 'object' &&
                    'persist' in arg &&
                    typeof arg.persist === 'function'
                ) {
                    arg.persist();
                    const dataTransfer = arg.dataTransfer;

                    if (preventDefault) {
                        arg.preventDefault();
                    }

                    // If we're throttling a drag event, we need to clone the dataTransfer
                    // Otherwise all the drag data will be lost
                    if (dataTransfer instanceof DataTransfer) {
                        const types = Array.from(dataTransfer.types);
                        const files = Array.from(dataTransfer.files);
                        const dataTransferClone = new DataTransfer();
                        dataTransferClone.dropEffect = dataTransfer.dropEffect;
                        dataTransferClone.effectAllowed = dataTransfer.effectAllowed;
                        types.forEach((type) => {
                            const data = dataTransfer.getData(type);
                            dataTransferClone.setData(type, data);
                        });
                        files.forEach((file) => {
                            dataTransferClone.items.add(file);
                        });
                        arg.dataTransfer = dataTransferClone;
                    }
                }
            });

            if (!isRunningRef.current) {
                isRunningRef.current = true;
                raf(doUpdate);
            }
        },
        [onEvery, preventDefault, doUpdate],
    );

    const cancel = useCallback(() => {
        raf.cancel(doUpdate);
        isRunningRef.current = false;
    }, [doUpdate]);

    useEffect(cancel, [cancel]);

    useEffect(() => {
        updateRef.current = update;
    }, [update]);

    return [throttledFn, cancel];
}

/**
 * It's just like `useState` but the setter is throttled using `requestAnimationFrame`
 */
export function useThrottledState<T>(
    initialState: T | (() => T),
    options?: UseThrottleWithRafOptions<(newState: T) => void>,
): [T, (newState: T) => void, () => void] {
    const [state, setState] = useState(initialState);
    const [throttledSetState, cancel] = useThrottleWithRaf({
        ...options,
        update: setState as (newState: T) => void,
    });

    return [state, throttledSetState, cancel];
}
