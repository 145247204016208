// Copyright 2024 Descript, Inc

import { errorCategoryContext } from '@descript/analytics';
import { uploadTranscriptionFailure, uploadTranscriptionSuccess } from '@descript/client';
import { WebRecorder } from '@descript/web-recorder';
import { invariant, ErrorCategory } from '@descript/errors';
import { getWebRecordingSession } from './webRecorderSessions';
import { WebRecorderTranscriptionMetadata } from '@descript/recorder-base';

const defaultCtx = errorCategoryContext(ErrorCategory.Recording);

export async function handleTranscriptionSuccess(metadata: WebRecorderTranscriptionMetadata) {
    try {
        invariant(
            metadata.sessionId,
            'Handle Recording Started: No recording session id found',
            ErrorCategory.Recording,
        );

        const webRecordingSession = getWebRecordingSession(metadata.sessionId);

        invariant(
            webRecordingSession,
            `Handle Recording Started: No recording session found for id: ${metadata.sessionId}`,
            ErrorCategory.Recording,
        );
        invariant(
            webRecordingSession.backendWorkflowId,
            'No backend workflow id found for session',
            ErrorCategory.Recording,
        );

        await uploadTranscriptionSuccess(defaultCtx(), webRecordingSession.backendWorkflowId, {
            type: 'done',
            perChannelAlignments: metadata.perChannelAlignments,
            formattedText: metadata.formattedText,
        });
    } catch (error) {
        WebRecorder.onRecorderEvent({
            type: 'error',
            recorder: metadata.mode,
            error: error as Error,
            context: 'transcription_success',
        });
    }
}

export async function handleTranscriptionCancelled(metadata: WebRecorderTranscriptionMetadata) {
    try {
        invariant(
            metadata.sessionId,
            'Handle Recording Cancelled: No recording session id found',
            ErrorCategory.Recording,
        );

        const webRecordingSession = getWebRecordingSession(metadata.sessionId);

        invariant(
            webRecordingSession,
            `Handle Recording Cancelled: No recording session found for id: ${metadata.sessionId}`,
            ErrorCategory.Recording,
        );
        invariant(
            webRecordingSession.backendWorkflowId,
            'No backend workflow id found for session',
            ErrorCategory.Recording,
        );

        await uploadTranscriptionFailure(defaultCtx(), webRecordingSession.backendWorkflowId);
    } catch (error) {
        WebRecorder.onRecorderEvent({
            type: 'error',
            recorder: metadata.mode,
            error: error as Error,
            context: 'transcription_cancelled',
        });
    }
}
