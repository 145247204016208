// Copyright 2024 Descript, Inc
import { DailyParticipantUpdateOptions } from '@daily-co/daily-js';
import {
    ExtendedDailyParticipant,
    useActiveSpeakerId,
    useDaily,
    useLocalSessionId,
    useParticipantIds,
    usePermissions,
    useThrottledDailyEvent,
} from '@daily-co/daily-react';
import { createContext, useCallback, useContext, useEffect } from 'react';
import {
    atom,
    selector,
    useRecoilCallback,
    useRecoilState,
    useRecoilValue,
    useSetRecoilState,
} from 'recoil';

import { useCallConfig } from '@hooks/useCallConfig';
import { usePreviousValue } from '@hooks/usePreviousValue';
import { isParticipantTrackOff, isTrackOff } from '@lib/participants';
import { usePinnedId } from '@lib/state/layout';
import { ReactNull } from '@descript/react-utils';

export interface ParticipantMetaData {
    last_active_date?: Date;
}

interface ContextValue {
    muteAll(muteFutureParticipants?: boolean): void;
    setFilteredParticipantIds(ids: string[] | null): void;
    swapParticipantPosition(id1: string, id2: string): void;
}

export const ParticipantsContext = createContext<ContextValue>(ReactNull);

/**
 * This state contains a list of participant ids that should be exclusively in scope for the call.
 * Other participants will be unsubscribed.
 *
 * This state allows to filter the participant scope to a subgroup, e.g. in breakout rooms, without
 * making core components dependent on a feature like breakout rooms.
 */
const filteredParticipantIdsState = atom<string[] | null>({
    key: 'filtered-participant-ids',
    default: ReactNull,
});
export const useFilteredParticipantIds = (): string[] | null =>
    useRecoilValue(filteredParticipantIdsState);

const participantMetaDataState = atom<Record<string, ParticipantMetaData>>({
    key: 'participant-meta-data',
    default: {},
});
export const useParticipantMetaData = () => useRecoilValue(participantMetaDataState);

const orderedParticipantIdsState = atom<string[]>({
    key: 'ordered-participant-ids',
    default: [],
});

const orderedVisibleParticipantIdsState = selector<string[]>({
    key: 'ordered-visible-participant-ids',
    get: ({ get }) => {
        const filteredIds = get(filteredParticipantIdsState);
        const orderedIds = get(orderedParticipantIdsState);
        return orderedIds.filter((id) =>
            Array.isArray(filteredIds) ? filteredIds.includes(id) : true,
        );
    },
});

export const useOrderedParticipantIds = () => useRecoilValue(orderedVisibleParticipantIdsState);

const participantMarkedForRemovalState = atom<string>({
    key: 'participant-marked-for-removal',
    default: '',
});
export const useParticipantMarkedForRemoval = () =>
    useRecoilState(participantMarkedForRemovalState);

const muteNewParticipantsState = atom<boolean>({
    key: 'mute-new-participants',
    default: false,
});
export const useMuteNewParticipants = () => useRecoilValue(muteNewParticipantsState);

function ParticipantsEventsHandler() {
    const daily = useDaily();
    const { broadcast } = useCallConfig();
    const muteNewParticipants = useMuteNewParticipants();

    /**
     * Maintain positions for each participant in Speaker & Grid view.
     */
    useThrottledDailyEvent(
        ['participant-joined', 'participant-left'],
        useRecoilCallback(
            ({ transact_UNSTABLE }) =>
                (evts) => {
                    const participants = daily?.participants?.() ?? {};
                    const muteUpdates: Record<string, DailyParticipantUpdateOptions> = {};
                    transact_UNSTABLE(({ set }) => {
                        evts.forEach((ev) => {
                            switch (ev.action) {
                                case 'participant-joined': {
                                    // Ignore non-owners in owner-only-broadcast
                                    if (broadcast && !ev.participant.owner) {
                                        return;
                                    }
                                    // Save new participant's cam & mic state for later
                                    const isCamOn = !isParticipantTrackOff(
                                        ev.participant,
                                        'video',
                                    );
                                    const isMicOn = !isParticipantTrackOff(
                                        ev.participant,
                                        'audio',
                                    );
                                    set(orderedParticipantIdsState, (prevIds) => {
                                        const newIds = prevIds.slice();
                                        if (!isCamOn) {
                                            // Append cam-off participants to the end of the list
                                            return [...newIds, ev.participant.session_id];
                                        }
                                        // Find position of first cam-off & mic-off participant
                                        const firstInactiveCamOffIndex = newIds.findIndex(
                                            (id) =>
                                                isParticipantTrackOff(
                                                    participants[id],
                                                    'video',
                                                ) &&
                                                isParticipantTrackOff(
                                                    participants[id],
                                                    'audio',
                                                ) &&
                                                !participants[id]?.local,
                                        );
                                        if (firstInactiveCamOffIndex >= 0) {
                                            // Insert new participant BEFORE first cam-off & mic-off participant
                                            newIds.splice(
                                                firstInactiveCamOffIndex,
                                                0,
                                                ev.participant.session_id,
                                            );
                                        } else {
                                            // Otherwise just append new participant to the end of the list
                                            newIds.push(ev.participant.session_id);
                                        }
                                        return newIds.filter(Boolean);
                                    });

                                    if (isMicOn) {
                                        // Actual check for muting happens later.
                                        // This just adds to the updates object.
                                        muteUpdates[ev.participant.session_id] = {
                                            setAudio: false,
                                        };
                                    }
                                    break;
                                }
                                case 'participant-left':
                                    set(orderedParticipantIdsState, (prevIds) =>
                                        prevIds.filter(
                                            (id) => id !== ev.participant.session_id,
                                        ),
                                    );
                                    set(participantMetaDataState, (md) => {
                                        const newMetaData = { ...md };
                                        delete newMetaData[ev.participant.session_id];
                                        return newMetaData;
                                    });
                                    break;
                                default:
                                    break;
                            }
                        });
                    });

                    if (muteNewParticipants && daily && Object.keys(muteUpdates).length > 0) {
                        daily.updateParticipants(muteUpdates);
                    }
                },
            [broadcast, daily, muteNewParticipants],
        ),
    );

    return ReactNull;
}

export function ParticipantsProvider({ children }: React.PropsWithChildren<unknown>) {
    const daily = useDaily();

    /**
     * Unmuted participant ids.
     */
    const unmutedParticipantIds = useParticipantIds({
        filter: useCallback(
            (p: ExtendedDailyParticipant) => !isTrackOff(p.tracks.audio.state),
            [],
        ),
    });
    const prevUnmutedParticipantIds = usePreviousValue(unmutedParticipantIds);
    const setMetaData = useSetRecoilState(participantMetaDataState);
    /**
     * Update last_active_date whenever a participant unmutes.
     */
    useEffect(() => {
        const newlyUnmutedIds = unmutedParticipantIds.filter(
            (id) => !prevUnmutedParticipantIds.includes(id),
        );
        if (!newlyUnmutedIds.length) {
            return;
        }
        setMetaData((m) => {
            const newMetaData = { ...m };
            newlyUnmutedIds.forEach((id) => {
                newMetaData[id] = {
                    ...newMetaData[id],
                    last_active_date: new Date(),
                };
            });
            return newMetaData;
        });
    }, [prevUnmutedParticipantIds, setMetaData, unmutedParticipantIds]);

    const localSessionId = useLocalSessionId();
    const { canAdminParticipants } = usePermissions();

    /**
     * Swaps the position of 2 participants identified by their session_id.
     */
    const swapParticipantPosition = useRecoilCallback(
        ({ set }) =>
            (id1: string, id2: string) => {
                /**
                 * Ignore in the following cases:
                 * - id1 and id2 are equal
                 * - one of both ids is not set
                 */
                if (id1 === id2 || !id1 || !id2) {
                    return;
                }
                set(orderedParticipantIdsState, (prevIds) => {
                    const newIds = prevIds.slice();
                    const idx1 = prevIds.indexOf(id1);
                    const idx2 = prevIds.indexOf(id2);
                    /**
                     * Could not find one of both ids in array.
                     * This can be due to a race condition when a participant leaves,
                     * while a swap of positions is triggered.
                     */
                    if (idx1 === -1 || idx2 === -1) {
                        return prevIds;
                    }
                    newIds[idx1] = id2;
                    newIds[idx2] = id1;
                    return newIds;
                });
            },
        [],
    );

    const muteAll = useRecoilCallback(
        ({ set }) =>
            (muteFutureParticipants: boolean = false) => {
                if (!canAdminParticipants) {
                    return;
                }
                set(muteNewParticipantsState, muteFutureParticipants);
                if (!unmutedParticipantIds.length) {
                    return;
                }
                daily.updateParticipants(
                    unmutedParticipantIds.reduce<Record<string, DailyParticipantUpdateOptions>>(
                        (o, id) => {
                            if (id === localSessionId) {
                                return o;
                            }
                            o[id] = {
                                setAudio: false,
                            };
                            return o;
                        },
                        {},
                    ),
                );
            },
        [canAdminParticipants, daily, localSessionId, unmutedParticipantIds],
    );

    const setFilteredParticipantIds = useRecoilCallback(
        ({ set }) =>
            (ids: string[] | null) => {
                set(filteredParticipantIdsState, ids);
            },
        [],
    );

    return (
        <ParticipantsContext.Provider
            value={{
                muteAll,
                setFilteredParticipantIds,
                swapParticipantPosition,
            }}
        >
            {children}
            <ParticipantsEventsHandler />
        </ParticipantsContext.Provider>
    );
}

export const useParticipants = () => useContext(ParticipantsContext);

/**
 * Returns the session_id for the participant that should be rendered
 * prominently in speaker or mobile view.
 */
export const useCurrentSpeakerId = () => {
    const daily = useDaily();
    const localSessionId = useLocalSessionId();
    const orderedParticipantIds = useOrderedParticipantIds();
    const activeSpeakerId = useActiveSpeakerId({
        filter: useCallback(
            (id: string) => {
                const isRemoteSpeaker = orderedParticipantIds.includes(id);
                if (orderedParticipantIds.length) {
                    return isRemoteSpeaker && id !== localSessionId;
                }
                return id === localSessionId;
            },
            [localSessionId, orderedParticipantIds],
        ),
    });
    const [pinnedId] = usePinnedId();
    const participantMetaData = useParticipantMetaData();
    const { broadcastRole } = useCallConfig();

    if (!daily) {
        return ReactNull;
    }
    /**
     * Ensure activeParticipant is still present in the call.
     * The activeParticipant only updates to a new active participant so
     * if everyone else is muted when AP leaves, the value will be stale.
     */
    const isPresent = orderedParticipantIds.includes(activeSpeakerId);
    const isPinnedPresent =
        orderedParticipantIds.includes(pinnedId) || pinnedId === localSessionId;

    if (isPinnedPresent) {
        return pinnedId;
    }

    const participants = Object.values(daily.participants());

    if (
        !isPresent &&
        orderedParticipantIds.length > 0 &&
        orderedParticipantIds.every((id) => {
            const participant = participants.find((p) => p.session_id === id);
            return (
                isParticipantTrackOff(participant, 'audio') &&
                !participantMetaData?.[id]?.last_active_date
            );
        })
    ) {
        // Return first cam on participant in case everybody is muted and nobody ever talked
        // or first remote participant, in case everybody's cam is muted, too.
        return (
            orderedParticipantIds.find((id) => {
                const participant = participants.find((p) => p.session_id === id);
                return !isParticipantTrackOff(participant, 'video');
            }) ?? orderedParticipantIds?.[0]
        );
    }

    const sorted = orderedParticipantIds
        .slice()
        .sort((a, b) => {
            const lastActiveA = participantMetaData?.[a]?.last_active_date;
            const lastActiveB = participantMetaData?.[b]?.last_active_date;
            if (lastActiveA > lastActiveB) {
                return 1;
            }
            if (lastActiveA < lastActiveB) {
                return -1;
            }
            return 0;
        })
        .reverse();

    const fallback = broadcastRole === 'attendee' ? ReactNull : localSessionId;

    return isPresent ? activeSpeakerId : (sorted?.[0] ?? fallback);
};
