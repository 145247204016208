// Copyright 2024 Descript, Inc
export const throttle = <T extends (...args: unknown[]) => void>(
    callback: T,
    limit: number,
) => {
    let wait = false;
    let cachedCall: T;
    let cachedArgs: unknown[];

    return (...args: unknown[]) => {
        if (!wait) {
            callback(...args);
            wait = true;
            setTimeout(() => {
                wait = false;
                if (cachedCall && cachedArgs) {
                    cachedCall(...cachedArgs);
                    cachedCall = undefined;
                    cachedArgs = undefined;
                }
            }, limit);
        } else {
            cachedCall = callback;
            cachedArgs = args;
        }
    };
};
