import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function ZoomPlusIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
            <circle cx="7.5" cy="7.5" r="5" fill="transparent" stroke={color} />
            <path d="M11.3847 10.6777L14.3533 13.6464C14.5486 13.8416 14.5486 14.1582 14.3534 14.3535C14.1581 14.5488 13.8415 14.5488 13.6462 14.3535L10.6776 11.3848L11.3847 10.6777Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 8L7 10H8V8H10V7H8V5H7L7 7H5V8H7Z"
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    fill: ${color};
                    height: ${size}px;
                    width: ${size}px;
                }
            `}</style>
        </svg>
    );
}
