import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function ArrowDownIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
            <path
                d="M8 4L8 12M8 12L11 9M8 12L5 9"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
