import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
    variant: 'enter' | 'leave';
}

export function PictureInPictureIcon({
    color = 'currentColor',
    size = 24,
    variant,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M0.666667 2.5H15.3333C15.4254 2.5 15.5 2.57462 15.5 2.66667V13.3333C15.5 13.4254 15.4254 13.5 15.3333 13.5H0.666667C0.574619 13.5 0.5 13.4254 0.5 13.3333V2.66667C0.5 2.57462 0.574619 2.5 0.666667 2.5Z"
                stroke={color}
            />
            <path
                d="M9.5 13.5C8.94772 13.5 8.5 13.0523 8.5 12.5V9.94444C8.5 9.82657 8.56704 9.71352 8.68639 9.63017C8.80573 9.54682 8.96759 9.5 9.13636 9.5H14.5"
                stroke={color}
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
