// Copyright 2024 Descript, Inc
import Daily from '@daily-co/daily-js';
import { usePermissions, useRoom } from '@daily-co/daily-react';
import { parse } from 'bowser';
import { createContext, useEffect } from 'react';
import { ReactNull } from '@descript/react-utils';

import { isCloudRecordingType } from '@features/recording/util/isCloudRecordingType';
import { isSafari } from '@lib/browserConfig';
import { useMaxGridTilesPerPage } from '@lib/state/custom';
import { usePreferredViewMode } from '@lib/state/layout';

type BroadcastRole = 'owner' | 'attendee';

export interface CallConfig {
    broadcast: boolean | null;
    broadcastRole: BroadcastRole | null;
    poweredByDaily: boolean;
    enableBreakoutRooms?: boolean;
    enableEmojiReactions?: boolean;
    enableAdvancedChat?: boolean;
    enableChat?: boolean;
    enableLiveCaptionsUI?: boolean;
    enableNetworkUI?: boolean;
    enableNoiseCancellationUI?: boolean;
    enablePeopleUI?: boolean;
    enablePIPUI?: boolean;
    enableHandRaising?: boolean;
    enableRecording?: string | null;
    enableRecordingUI?: boolean;
    enableScreenShare?: boolean;
    enableTranscription?: boolean;
    enableVideoProcessingUI?: boolean;
    maxParticipants?: number;
    optimizeLargeCalls?: boolean;
}

export const CallConfigContext = createContext<CallConfig>({
    broadcast: ReactNull,
    broadcastRole: ReactNull,
    poweredByDaily: false,
});

export function CallConfigProvider({
    children,
}: React.PropsWithChildren<Record<string, never>>) {
    const room = useRoom();

    const [, setPreferredViewMode] = usePreferredViewMode();
    const [, setMaxGridTilesPerPage] = useMaxGridTilesPerPage();
    const { canSendScreenAudio, canSendScreenVideo } = usePermissions();

    /**
     * Apply Prebuilt UI constraints, when experimental_optimize_large_calls is set.
     */
    useEffect(() => {
        const optimizeLargeCalls = room?.config?.experimental_optimize_large_calls;
        if (!optimizeLargeCalls) {
            return;
        }
        setMaxGridTilesPerPage(12);
        // TBC: For now, start the call in Speaker view to match the group call default
        // setPreferredViewMode('grid');
    }, [room, setMaxGridTilesPerPage, setPreferredViewMode]);

    const config = room?.config;
    const domainConfig = room?.domainConfig;
    const tokenConfig = room?.tokenConfig;

    const broadcast = config?.owner_only_broadcast ?? ReactNull;
    const isOwner = tokenConfig?.is_owner;

    const hasFullUIControls = !broadcast || (broadcast && isOwner);

    const recordingType = tokenConfig?.enable_recording ?? config?.enable_recording;

    const browser =
        typeof window === 'undefined' ? ReactNull : parse(window.navigator.userAgent);
    const supportsLocalRecording =
        browser?.platform?.type === 'desktop' && browser?.engine?.name === 'Blink';

    const isLocalRecordingReady = recordingType === 'local' && supportsLocalRecording;

    const recordingsBucket = config?.recordings_bucket ?? domainConfig?.recordings_bucket;
    const isCloudRecordingAllowed =
        !domainConfig?.hipaa || (domainConfig?.hipaa && recordingsBucket);
    const isCloudRecordingReady =
        isCloudRecordingAllowed && isCloudRecordingType(recordingType);

    const enableRecording =
        hasFullUIControls && (isLocalRecordingReady || isCloudRecordingReady) && isOwner
            ? recordingType
            : ReactNull;

    const enableTranscription = Boolean(domainConfig?.enable_transcription);

    return (
        <CallConfigContext.Provider
            value={
                room
                    ? {
                          broadcast,
                          broadcastRole: broadcast
                              ? ((isOwner ? 'owner' : 'attendee') as BroadcastRole)
                              : ReactNull,
                          enableBreakoutRooms:
                              config?.enable_breakout_rooms ??
                              !!domainConfig?.enable_breakout_rooms,
                          enableEmojiReactions:
                              config?.enable_emoji_reactions ??
                              !!domainConfig?.enable_emoji_reactions,
                          enableAdvancedChat:
                              config?.enable_advanced_chat ??
                              !!domainConfig?.enable_advanced_chat,
                          enableChat: config?.enable_chat ?? !!domainConfig?.enable_chat,
                          enableLiveCaptionsUI:
                              tokenConfig?.enable_live_captions_ui ??
                              config?.enable_live_captions_ui ??
                              !!domainConfig?.enable_live_captions_ui,
                          enableNetworkUI:
                              config?.enable_network_ui ?? !!domainConfig?.enable_network_ui,
                          enableNoiseCancellationUI:
                              !isSafari() && // Krisp is currently unstable in Safari
                              Daily.supportedBrowser().supportsAudioProcessing && // user's browser must support it
                              (config?.enable_noise_cancellation_ui ?? // must be enabled in dash
                                  !!domainConfig?.enable_noise_cancellation_ui),
                          enablePeopleUI:
                              config?.enable_people_ui ??
                              domainConfig?.enable_people_ui ??
                              true,
                          enablePIPUI: config?.enable_pip_ui ?? !!domainConfig?.enable_pip_ui,
                          enableHandRaising:
                              config?.enable_hand_raising ??
                              !!domainConfig?.enable_hand_raising,
                          enableRecording,
                          enableRecordingUI:
                              tokenConfig?.enable_recording_ui ?? !!enableRecording,
                          enableScreenShare:
                              hasFullUIControls &&
                              (tokenConfig?.enable_screenshare ?? config?.enable_screenshare) &&
                              Daily.supportedBrowser().supportsScreenShare &&
                              (canSendScreenAudio || canSendScreenVideo),
                          enableTranscription,
                          enableVideoProcessingUI:
                              Daily.supportedBrowser().supportsVideoProcessing &&
                              (config?.enable_video_processing_ui ??
                                  !!domainConfig?.enable_video_processing_ui),
                          maxParticipants: config?.max_participants,
                          optimizeLargeCalls: config?.experimental_optimize_large_calls,
                          poweredByDaily:
                              domainConfig && 'hide_daily_branding' in domainConfig
                                  ? !domainConfig?.hide_daily_branding
                                  : false,
                      }
                    : {
                          broadcast: ReactNull,
                          broadcastRole: ReactNull,
                          poweredByDaily: false,
                      }
            }
        >
            {children}
        </CallConfigContext.Provider>
    );
}
