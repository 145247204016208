import { PlatformHelpers } from '@descript/descript-core';

/**
 * The modifiers available on DOM KeyboardEvent and MouseEvent
 */
export interface DomModifiers {
    ctrlKey: boolean;
    altKey: boolean;
    metaKey: boolean;
    shiftKey: boolean;
}

// Like draftjs's KeyBindingUtil, but for generic keyboard event-ish objects
export function hasCommandWithoutAltModifier(keyboardEvent: DomModifiers): boolean {
    return !keyboardEvent.altKey && PlatformHelpers.isMac()
        ? Boolean(keyboardEvent.metaKey)
        : Boolean(keyboardEvent.ctrlKey);
}

export function hasCommandModifier(keyboardEvent: DomModifiers): boolean {
    return PlatformHelpers.isMac()
        ? Boolean(keyboardEvent.metaKey)
        : Boolean(keyboardEvent.ctrlKey);
}

export function hasModifier(keyboardEvent: DomModifiers): boolean {
    return hasCommandModifier(keyboardEvent) || keyboardEvent.altKey || keyboardEvent.shiftKey;
}
