import * as React from 'react';

interface BaseProps extends React.SVGAttributes<SVGElement> {
    color?: string;
    muted?: boolean;
    size?: number;
}

interface PropsWithTransition extends BaseProps {
    id: string;
}

// const MicrophoneIconWithTransition: React.FC<
//   React.PropsWithChildren<PropsWithTransition>
// > = ({ color = 'currentColor', id, muted = false, size = 24, ...props }) => {
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
//       <defs>
//         <mask id={`micIconMask${id}`}>
//           <rect x="0" y="0" width="24" height="24" fill="white" />
//           <path
//             className={classNames('strike', { muted })}
//             d="M2.88 19L18.88 3"
//             stroke="black"
//             strokeWidth="1.5"
//             strokeLinecap="round"
//           />
//         </mask>
//       </defs>
//       <g mask={muted ? `url(#micIconMask${id})` : undefined}>
//         <path
//           fill={color}
//           d="M12 3C10.3431 3 9 4.34315 9 6V12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12V6C15 4.34315 13.6569 3 12 3Z"
//         />
//         <path
//           fill={color}
//           d="M7.5 12C7.5 11.5858 7.16421 11.25 6.75 11.25C6.33579 11.25 6 11.5858 6 12C6 13.9175 6.62158 15.4436 7.73826 16.4858C8.67527 17.3603 9.90114 17.8386 11.25 17.9654V20.25C11.25 20.6642 11.5858 21 12 21C12.4142 21 12.75 20.6642 12.75 20.25V17.9654C14.0989 17.8386 15.3247 17.3603 16.2617 16.4858C17.3784 15.4436 18 13.9175 18 12C18 11.5858 17.6642 11.25 17.25 11.25C16.8358 11.25 16.5 11.5858 16.5 12C16.5 13.5825 15.9966 14.6814 15.2383 15.3892C14.4713 16.105 13.3583 16.5 12 16.5C10.6417 16.5 9.52867 16.105 8.76174 15.3892C8.00342 14.6814 7.5 13.5825 7.5 12Z"
//         />
//       </g>
//       <path
//         className={classNames('strike', { muted })}
//         d="M4 20L20 4"
//         stroke={color}
//         strokeLinecap="round"
//         strokeWidth="1.5"
//       />
{
    /* eslint-disable-next-line react/no-unknown-property */
}
//       <style jsx>{`
//         svg {
//           height: ${size}px;
//           width: ${size}px;
//           display: block;
//         }
//         .strike {
//           transform-origin: center;
//           transform: scale(0);
//           transition: transform 240ms ease;
//         }
//         .strike.muted {
//           transform: scale(1);
//         }
//       `}</style>
//     </svg>
//   );
// };

function MicrophoneIconStatic({
    color = 'currentColor',
    id,
    muted = false,
    size = 24,
    ...props
}: React.PropsWithChildren<BaseProps>) {
    if (muted) {
        return (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <g clipPath="url(#clip0_1_570)">
                    <path d="M5 15.5H11" stroke={color} />
                    <path d="M0 0L16 16" stroke={color} />
                    <path
                        d="M9.5 5.5V2.29972C9.50038 1.85386 9.36113 1.42373 9.10928 1.09284C8.85743 0.761957 8.51096 0.553915 8.13712 0.509107C7.76328 0.464298 7.38874 0.585918 7.08622 0.850358C6.7837 1.1148 6.57477 1.50319 6.5 1.94014"
                        stroke={color}
                    />
                    <path
                        d="M6.5 7.85888V7.19712L8.66022 9.33201C8.54138 9.39589 8.41538 9.44226 8.28561 9.46988C8.00185 9.53028 7.70733 9.4996 7.43763 9.37995C7.16741 9.26007 6.93078 9.05452 6.76175 8.78378C6.59257 8.5128 6.50031 8.19087 6.5 7.85888Z"
                        stroke={color}
                    />
                    <path
                        d="M11.5714 11.6071C10.8742 12.2676 9.98013 12.7198 9.00406 12.9055C8.02799 13.0912 7.01439 13.002 6.0933 12.6494C5.17222 12.2968 4.38566 11.6968 3.83453 10.9264C3.2834 10.1561 2.99284 9.25046 3.00013 8.32579V7M13 7V8.32579C12.9997 8.59919 12.9735 8.87204 12.9214 9.14115"
                        stroke={color}
                    />
                    <path d="M8 13V16" stroke={color} />
                </g>
                <defs>
                    <clipPath id="clip0_1_570">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
                {/* eslint-disable-next-line react/no-unknown-property */}
                <style jsx>{`
                    svg {
                        height: ${size}px;
                        width: ${size}px;
                        display: block;
                    }
                `}</style>
            </svg>
        );
    }

    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M5 15.5H11" stroke={color} />
            <path d="M8 13V16" stroke={color} />
            <path
                d="M6.95131 0.968787C7.2357 0.664085 7.61374 0.5 8 0.5C8.38626 0.5 8.7643 0.664085 9.04869 0.968787C9.33416 1.27466 9.5 1.69644 9.5 2.14286V7.85714C9.5 8.30356 9.33416 8.72534 9.04869 9.03121C8.7643 9.33592 8.38626 9.5 8 9.5C7.61374 9.5 7.2357 9.33592 6.95131 9.03121C6.66584 8.72534 6.5 8.30356 6.5 7.85714V2.14286C6.5 1.69644 6.66584 1.27466 6.95131 0.968787Z"
                stroke={color}
            />
            <path
                d="M13 7V8.33333C13 9.57101 12.4732 10.758 11.5355 11.6332C10.5979 12.5083 9.32608 13 8 13C6.67392 13 5.40215 12.5083 4.46447 11.6332C3.52678 10.758 3 9.57101 3 8.33333V7"
                stroke={color}
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}

export function MicrophoneIcon(
    props: React.PropsWithChildren<BaseProps | (PropsWithTransition & { transition: boolean })>,
) {
    // if ('transition' in props) {
    //   const { transition, ...rest } = props;
    //   return <MicrophoneIconWithTransition {...rest} />;
    // }
    return <MicrophoneIconStatic {...props} />;
}
