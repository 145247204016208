export function downloadBlob(blob: Blob, filename: string) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
}

export function addPaddedZeros(num: number, length: number) {
    const numStrLength = num.toString().length;
    if (numStrLength >= length) {
        return num.toString();
    }
    const diff = length - numStrLength;
    return '0'.repeat(diff) + num.toString();
}
