import * as React from 'react';

import { useTheme } from '../../contexts/Theme';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function ReverseIcon({ size = 24, ...props }: React.PropsWithChildren<Props>) {
    const { colors } = useTheme();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 8C0 5.79086 1.79086 4 4 4H20C22.2091 4 24 5.79086 24 8V18C24 20.2091 22.2091 22 20 22H4C1.79086 22 0 20.2091 0 18V8Z"
                fill={colors.system.white}
            />
            <path
                d="M7.72361 2.55279L7 4H17L16.2764 2.55279C16.107 2.214 15.7607 2 15.382 2H8.61803C8.23926 2 7.893 2.214 7.72361 2.55279Z"
                fill="white"
            />
            <path
                d="M17.3107 12.613C17.3107 9.51304 14.7977 7 11.6977 7C10.2742 7 8.97442 7.52992 7.98494 8.40326"
                stroke={colors.system.blueDark}
                strokeWidth="1.13806"
                strokeLinecap="round"
            />
            <path
                d="M6.68926 12.613C6.68926 15.713 9.2023 18.2261 12.3023 18.2261C13.7258 18.2261 15.0256 17.6962 16.0151 16.8228"
                stroke={colors.system.blueDark}
                strokeWidth="1.13806"
                strokeLinecap="round"
            />
            <path
                d="M6.56147 10.5493C6.75567 10.2256 7.22476 10.2256 7.41896 10.5493L9.11312 13.3729C9.31308 13.7061 9.07302 14.1301 8.68438 14.1301H5.29606C4.90741 14.1301 4.66736 13.7061 4.86731 13.3729L6.56147 10.5493Z"
                fill={colors.system.blueDark}
            />
            <path
                d="M17.4344 14.683C17.2402 15.0067 16.7711 15.0067 16.5769 14.683L14.8786 11.8526C14.6787 11.5193 14.9187 11.0953 15.3074 11.0953L18.7039 11.0953C19.0926 11.0953 19.3326 11.5193 19.1327 11.8526L17.4344 14.683Z"
                fill={colors.system.blueDark}
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    fill: transparent;
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
