// Copyright 2023 Descript, Inc
/*
 * DO NOT EDIT!!!
 *
 * This file is generated from values in Figma! To regenerate the values
 * in this file run the following command:
 *
 * > pnpm generate
 */

import * as React from 'react';
import makeClass from 'classnames';

export interface IconProps extends React.ComponentPropsWithoutRef<'div'> {
    size?: number;
    color?: string;
    opacity?: number;
    name: string;
    type?: 'icon' | 'cursor';
}

export const Icon = React.forwardRef(
    (
        {
            style,
            size = 16,
            color = 'currentColor',
            type = 'icon',
            opacity,
            className,
            name,
            ...props
        }: IconProps,
        ref: React.Ref<HTMLDivElement>,
    ) => (
        <div
            aria-hidden="true"
            ref={ref}
            className={makeClass(className, type === 'icon' ? 'icon-token' : 'cursor-token')}
            style={
                {
                    '--icon-opacity': opacity,
                    '--icon-size': `${size}px`,
                    '--icon-color': color,
                    '--icon-mask': `var(--${name}-${type})`,
                    ...style,
                } as React.CSSProperties
            }
            {...props}
        />
    ),
);
