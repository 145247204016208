import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function WarningIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M0.637508 12.9312L7.1193 2.00198C7.11951 2.00163 7.11972 2.00127 7.11994 2.00092C7.21237 1.84742 7.34197 1.72108 7.49586 1.63357C7.65007 1.54588 7.82368 1.5 8 1.5C8.17632 1.5 8.34993 1.54588 8.50414 1.63357C8.65801 1.72107 8.7876 1.84739 8.88003 2.00087C8.88025 2.00124 8.88048 2.00161 8.8807 2.00198L15.3625 12.9312C15.452 13.089 15.4995 13.2679 15.5 13.4504C15.5005 13.6341 15.4534 13.8145 15.3636 13.9736C15.2739 14.1326 15.1448 14.2645 14.9898 14.3566C14.8355 14.4483 14.6606 14.4976 14.4824 14.5H1.51763C1.33937 14.4976 1.1645 14.4483 1.01021 14.3566L0.754766 14.7864L1.01021 14.3566C0.855183 14.2645 0.726136 14.1326 0.636397 13.9736C0.54664 13.8145 0.499495 13.6341 0.500004 13.4504C0.50051 13.2679 0.548027 13.089 0.637508 12.9312Z"
                stroke={color}
            />
            <path d="M8 4V9" stroke={color} />
            <path d="M8 12H8.01" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    );
}
