// Copyright 2024 Descript, Inc
import {
    useDaily,
    useDevices,
    useLocalSessionId,
    useParticipantProperty,
    useRecording,
    useRoom,
    useParticipantCounts,
    useParticipantIds,
} from '@daily-co/daily-react';
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { trackEvent } from '@descript/analytics';
import { CallSessionData, useCallState } from './CallProvider';
import { useBackgroundEffect } from '@hooks/useBackgroundEffect';
import { useSidebarView } from '@lib/state/core';
import { useViewMode } from '@lib/state/layout';
import { RecordingAnalyticsEvents, WebRecordingSession } from '@descript/recorder-base';
import {
    getWebRecordingSession,
    trackRecordingAnalyticsEvent,
} from '@descript/recorder-shared';
import { useMeetingSessionData } from '@features/breakouts/hooks/useMeetingSessionData';
import { RecordingSessionData } from '../features/recording/contexts/RecordingProvider';

interface ContextValue {
    trackEvent(event: string, properties?: Record<string, unknown>): void;
    trackWithCallProps(event: string, properties?: Record<string, unknown>): void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const AnalyticsContext = createContext<ContextValue>({
    trackEvent: noop,
    trackWithCallProps: noop,
});

export function AnalyticsProvider({ children }: React.PropsWithChildren<unknown>) {
    const daily = useDaily();
    const devices = useDevices();
    const room = useRoom();
    const callState = useCallState();
    const { isRecording } = useRecording();
    const backgroundEffect = useBackgroundEffect();
    const [sidebarView] = useSidebarView();
    const viewMode = useViewMode();
    const localSessionId = useLocalSessionId();
    const { sessionData } = useMeetingSessionData<CallSessionData & RecordingSessionData>();
    const [videoState, audioState, screenVideoState, screenAudioState, joinedAt] =
        useParticipantProperty(localSessionId, [
            'tracks.video.state',
            'tracks.audio.state',
            'tracks.screenVideo.state',
            'tracks.screenAudio.state',
            'joined_at',
        ]);
    const skipInitialSet = useRef(new Set<string>());
    const hasBeenTrueSet = useRef(new Set<string>());
    const workflowId = useRef(sessionData?.rec?.workflowId);
    const [joinedRoomAt, setJoinedRoomAt] = useState(undefined);
    const [recordingCount, setRecordingCount] = useState(0);
    const participantCounts = useParticipantCounts();
    const participantIds = useParticipantIds();
    const participantSet = useRef(new Set<string>());

    const isNotFirst = (event: string) => {
        const ev = event.split('-').slice(0, -1).join('-');
        if (skipInitialSet.current.has(ev)) {
            return true;
        }
        skipInitialSet.current.add(ev);
        return false;
    };

    const hasBeenTruthy = (event: string, value: boolean | string) => {
        const ev = event.split('-').slice(0, -1).join('-');
        if (hasBeenTrueSet.current.has(ev) || value) {
            hasBeenTrueSet.current.add(ev);
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (sessionData?.rec?.workflowId) {
            workflowId.current = sessionData?.rec?.workflowId;
        }
    }, [sessionData?.rec?.workflowId]);

    const globalProps = useMemo(() => {
        return {
            build: 'rooms',
            owner: room?.tokenConfig?.is_owner,
            roomSessionId: daily?.meetingSessionSummary()?.id,
            roomName: room?.name,
            micState: devices?.micState,
            camState: devices?.camState,
            hasMicError: devices?.hasMicError,
            hasCamError: devices?.hasCamError,
            currentMic: devices?.currentMic?.device?.label,
            currentCam: devices?.currentCam?.device?.label,
            currentOutput: devices?.currentSpeaker?.device?.label,
            backgroundEffect: backgroundEffect?.currentEffect,
            videoState,
            audioState,
            screenVideoState,
            screenAudioState,
            roomState: callState?.state,
            isRecording: isRecording ?? false,
            sidebarView: sidebarView ?? 'none',
            viewMode,
            workflowId: workflowId.current,
            secondsSinceJoined: joinedRoomAt
                ? Math.floor((Date.now() - joinedRoomAt) / 1000)
                : undefined,
            recordingCount,
            currentParticipants: participantCounts?.present,
            historicalParticipants: participantSet.current.size,
        };
    }, [
        daily,
        devices,
        room?.name,
        room?.tokenConfig?.is_owner,
        backgroundEffect,
        videoState,
        audioState,
        screenVideoState,
        screenAudioState,
        callState?.state,
        isRecording,
        sidebarView,
        viewMode,
        joinedRoomAt,
        recordingCount,
        participantCounts,
    ]);

    const globalPropsRef = useRef(globalProps);

    useEffect(() => {
        if (joinedAt) {
            setJoinedRoomAt(joinedAt);
        }
    }, [joinedAt]);

    useEffect(() => {
        participantIds.forEach((id) => {
            participantSet.current.add(id);
        });
    }, [participantIds]);

    useEffect(() => {
        globalPropsRef.current = globalProps;
    }, [globalProps]);

    // Track an event with Daily call properties, mostly empty outside an active call
    const trackWithCallProps = useCallback(
        (event: string | RecordingAnalyticsEvents, properties?: Record<string, unknown>) => {
            const currentGlobalProps = globalPropsRef.current;

            const analyticsEvents = [
                RecordingAnalyticsEvents.recording_started,
                RecordingAnalyticsEvents.recording_stopped,
                RecordingAnalyticsEvents.recording_completed,
            ];

            if (analyticsEvents.includes(event as RecordingAnalyticsEvents)) {
                const sessionId = workflowId.current ?? properties.workflowId;
                let session: WebRecordingSession | undefined;
                if (sessionId) {
                    session = getWebRecordingSession(sessionId as string);
                }
                trackRecordingAnalyticsEvent(session, event as RecordingAnalyticsEvents, {
                    ...currentGlobalProps,
                    ...properties,
                });
            } else {
                trackEvent(event, { ...currentGlobalProps, ...properties });
            }
        },
        [], // This must remain a stable function (no dependencies)
    );

    // Map Daily events to analytics events
    useEffect(() => {
        if (callState?.state) {
            const ev = `room-state-${callState.state}`;
            if (callState.state === 'joined') {
                setRecordingCount(0);
            }
            trackWithCallProps(ev);
        }
    }, [callState?.state, trackWithCallProps]);

    useEffect(() => {
        const ev = `room-video-state-${videoState}`;
        if (videoState && isNotFirst(ev)) {
            trackWithCallProps(ev);
        }
    }, [videoState, trackWithCallProps]);

    useEffect(() => {
        const ev = `room-audio-state-${audioState}`;
        if (audioState && isNotFirst(ev)) {
            trackWithCallProps(ev);
        }
    }, [audioState, trackWithCallProps]);

    useEffect(() => {
        const ev = `room-recording-state-${isRecording}`;
        if (hasBeenTruthy(ev, isRecording)) {
            if (isRecording) {
                setRecordingCount((prev) => prev + 1);
            }
            trackWithCallProps(ev);
        }
    }, [isRecording, trackWithCallProps]);

    useEffect(() => {
        const ev = `room-sidebar-view-${sidebarView ?? 'none'}`;
        if (hasBeenTruthy(ev, sidebarView)) {
            trackWithCallProps(ev);
        }
    }, [sidebarView, trackWithCallProps]);

    useEffect(() => {
        const ev = `room-view-mode-${viewMode}`;
        if (isNotFirst(ev)) {
            trackWithCallProps(ev);
        }
    }, [viewMode, trackWithCallProps]);

    return (
        <AnalyticsContext.Provider
            value={{
                trackEvent,
                trackWithCallProps,
            }}
        >
            {children}
        </AnalyticsContext.Provider>
    );
}

export const useAnalytics = () => useContext(AnalyticsContext);
