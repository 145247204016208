// Copyright 2024 Descript, Inc
import { DailyCall } from '@daily-co/daily-js';

// This file includes helper functions for working with userData
// and ensuring that prebuilt usage doesn't stomp or get confused
// with iframe usage. Instead of using daily-js setUserData or
// accessing userData directly from the participant blobs, prebuilt
// should use `setPrebuiltUserData` and `getPrebuiltUserData` defined
// below

export const CUSTOMER_USER_DATA_KEY = 'c';
export const PREBUILT_USER_DATA_KEY = 'p';

export const setPrebuiltUserData = async <T = unknown>(daily: DailyCall, data: T) => {
    const iframeUserData = getIframeUserData(daily);

    // if both our data and iframe data is undefined, then setUserData to
    // undefined to take advantage of a slimmed down presence payload
    if (iframeUserData === undefined && data === undefined) {
        return await daily.setUserData(data);
    }
    const newData = { [PREBUILT_USER_DATA_KEY]: data };
    if (iframeUserData !== undefined) {
        newData[CUSTOMER_USER_DATA_KEY] = iframeUserData;
    }

    return await daily.setUserData(newData);
};

export const getPrebuiltUserData = (daily: DailyCall, participantId = 'local') => {
    const participants = daily?.participants() ?? {};
    if (typeof participants[participantId]?.userData !== 'object') {
        // in prebuilt, userData should always be either an object of
        // the form { i?: <something>, p?: <something } or undefined
        // if it is anything else, it means that prebuilt has called
        // daily-js.setUserData directly without the setPrebuiltUserData
        // helper above. let's detect this to help prevent that mistake.
        if (participants[participantId]?.userData !== undefined) {
            console.warn('Unsafe use of setUserData detected in prebuilt.');
        }
        return undefined;
    }
    const userData: object = participants[participantId].userData as object;
    return { ...userData[PREBUILT_USER_DATA_KEY] };
};

export const getIframeUserData = (daily: DailyCall, participantId = 'local') => {
    const participants = daily?.participants() ?? {};
    if (typeof participants[participantId]?.userData !== 'object') {
        // unlike in prebuilt code, for customer use of useData, we
        // actually shim the call and can guarantee that the data the
        // sent is either nested in an object or undefined, nonetheless
        // let's warn prebuilt if it's done something to muck with it
        // like we do in getPrebuiltUserData :)
        if (participants[participantId]?.userData !== undefined) {
            console.warn('Unsafe use of setUserData detected in prebuilt.');
        }
        return undefined;
    }
    const userData: object = participants[participantId].userData as object;
    return userData[CUSTOMER_USER_DATA_KEY];
};
