// Copyright 2023 Descript, Inc

import * as React from 'react';
import { isDomNode } from './ReactEventHandlers';

export function useBodyPointerEvents() {
    React.useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            for (const mutation of mutations) {
                if (mutation.target.nodeName === 'BODY') {
                    if (!isDomNode(mutation.target)) {
                        return;
                    }

                    const body = mutation.target;
                    const currentAttribute = body.getAttribute('data-pointer-events');
                    const newAttribute =
                        body.style.pointerEvents === 'none' ? 'disabled' : 'enabled';

                    if (currentAttribute !== newAttribute) {
                        body.setAttribute('data-pointer-events', newAttribute);
                    }

                    break;
                }
            }
        });

        observer.observe(document.body, { attributes: true });

        return () => {
            observer.disconnect();
        };
    }, []);
}
