// Copyright 2024 Descript, Inc
import { useEffect } from 'react';

import { throttle } from '../lib/throttle';

/**
 * Accepts a stable function reference to be called on window.resize
 * and window.orientationchange events.
 * useResize calls the function once initially.
 */
export const useResize = <T extends (...args: unknown[]) => unknown>(
    callback: T,
    throttleDelay = 100,
) => {
    useEffect(() => {
        let frame: ReturnType<typeof requestAnimationFrame>;
        const handleResize = throttle(callback, throttleDelay);
        window.addEventListener('resize', handleResize, { passive: true });
        window.addEventListener('orientationchange', handleResize, {
            passive: true,
        });
        handleResize();
        return () => {
            if (frame) {
                cancelAnimationFrame(frame);
            }
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('orientationchange', handleResize);
        };
    }, [callback, throttleDelay]);
};
