// Copyright 2022 Descript, Inc

import {
    SpanProcessor,
    BasicTracerProvider,
    SimpleSpanProcessor,
    ReadableSpan,
    Span,
} from '@opentelemetry/sdk-trace-base';
import { Context, SpanAttributes } from '@opentelemetry/api';
import { PrefixConsoleSpanExporter } from './PrefixConsoleSpanExporter';

/**
 * Logs OpenTelemetry traces to the console
 */
export function addConsoleSpanProcessor(tracerProvider: BasicTracerProvider): void {
    tracerProvider.addSpanProcessor(
        new SimpleSpanProcessor(new PrefixConsoleSpanExporter('OTEL')),
    );
}

/**
 * Span processor that adds a default set of attributes to every span
 */
class DefaultAttributesSpanProcessor implements SpanProcessor {
    constructor(private readonly getDefaultAttributes: () => SpanAttributes) {
        // NOP
    }

    async forceFlush(): Promise<void> {
        // NOP
    }

    onEnd(span: ReadableSpan): void {
        // NOP
    }

    onStart(span: Span, parentContext: Context): void {
        span.setAttributes(this.getDefaultAttributes());
    }

    async shutdown(): Promise<void> {
        // NOP
    }
}

export function addDefaultAttributesProcessor(
    tracerProvider: BasicTracerProvider,
    getDefaultAttributes: () => SpanAttributes,
): void {
    tracerProvider.addSpanProcessor(new DefaultAttributesSpanProcessor(getDefaultAttributes));
}
