// Copyright 2024 Descript, Inc
import { useLocalSessionId, useParticipantProperty } from '@daily-co/daily-react';
import React, { createContext, useCallback, useContext, useEffect } from 'react';
import { CallSessionData } from './CallProvider';
import {
    configureBrowserTracerProvider,
    LegacyTransmissionExporter,
} from '@descript/client/src/Utilities/Tracing/OpenTelemetry';
import { getInitialFeatureFlag } from '@descript/client/src/FeatureFlags/settings';
import { trace } from '@opentelemetry/api';
import { SimpleSpanProcessor } from '@opentelemetry/sdk-trace-base';
import {
    getDatasetForBuild,
    InMemoryTransmission,
} from '@descript/client/src/Utilities/Tracing';
import { useMeetingSessionData } from '@features/breakouts/hooks/useMeetingSessionData';
import { RecordingSessionData } from '../features/recording/contexts/RecordingProvider';
import '@lib/sentry';

interface ContextValue {
    setTracerDelegateToken(token: string): void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const TracingContext = createContext<ContextValue>({
    setTracerDelegateToken: noop,
});

export function TracingProvider({ children }: React.PropsWithChildren<unknown>) {
    const localSessionId = useLocalSessionId();
    const { sessionData } = useMeetingSessionData<CallSessionData & RecordingSessionData>();
    const [isOwner] = useParticipantProperty(localSessionId, ['owner']);

    // Initialize opentelemetry tracing to send spans to Honeycomb
    const setTracerProvider = useCallback((token?: string) => {
        const provider = configureBrowserTracerProvider({
            serviceName: 'client.rooms',
            clientEventLoggingFlag: Boolean(
                getInitialFeatureFlag('client-event-logging', true),
            ),
            getDefaultSpanMetadata: () => ({}),
            spanTransmissionProcessor: new SimpleSpanProcessor(
                new LegacyTransmissionExporter(
                    InMemoryTransmission.getShared({
                        dataset: getDatasetForBuild(),
                        delegateToken: token,
                    }),
                ),
            ),
        });
        if (provider) {
            trace.setGlobalTracerProvider(provider);
        }
    }, []);

    // Initialize tracer provider on load
    useEffect(() => {
        setTracerProvider();
    }, [setTracerProvider]);

    const setTracerDelegateToken = useCallback(
        (token: string) => {
            setTracerProvider(token);
        },
        [setTracerProvider],
    );

    useEffect(() => {
        // eslint-disable-next-line no-null/no-null
        if (isOwner === null) {
            // Wait until isOwner is set
            return;
        }
        setTracerDelegateToken(isOwner ? undefined : sessionData?.cs?.delegateToken);
    }, [isOwner, sessionData?.cs?.delegateToken, setTracerDelegateToken]);

    return (
        <TracingContext.Provider
            value={{
                setTracerDelegateToken,
            }}
        >
            {children}
        </TracingContext.Provider>
    );
}

export const useTracing = () => useContext(TracingContext);
