// Copyright 2024 Descript, Inc
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { ReactNull } from '@descript/react-utils';

import { useCustomIntegrations } from '@hooks/useCustomIntegrations';
import { useScreens } from '@hooks/useScreens';

import { isMobileState } from './core';
import Cookies from 'js-cookie';

/**
 * This file contains UI state pertaining to layout. E.g.: are we on a mobile device or not,
 * which view mode is preferred.
 */

const mobileTrayState = atom<boolean>({
    key: 'mobile-tray',
    default: true,
});
/**
 * Returns whether the mobile tray should be shown and a setter to change it.
 */
export const useMobileTray = () => useRecoilState(mobileTrayState);

const showMobileMoreMenuState = atom<boolean>({
    key: 'show-mobile-more-menu-state',
    default: false,
});
/**
 * Returns a flag indicating whether to show the More menu in mobile view.
 */
export const useShowMobileMoreMenu = () => useRecoilState(showMobileMoreMenuState);

const showMobilePageMenuState = atom<boolean>({
    key: 'show-mobile-page-menu-state',
    default: false,
});
/**
 * Returns a flag indicating whether to show the page menu in mobile view.
 */
export const useShowMobilePageMenu = () => useRecoilState(showMobilePageMenuState);

const pinnedIdState = atom<string | null>({
    key: 'pinned-id',
    default: ReactNull,
});
/**
 * Returns the currently pinned session or screen id and a setter to change it.
 */
export const usePinnedId = () => useRecoilState(pinnedIdState);

export type PreferredViewMode = 'grid' | 'speaker';

const PREFERRED_VIEW_MODE_COOKIE_KEY = 'preferred-view-mode';

const preferredViewModeState = atom<PreferredViewMode>({
    key: 'preferred-view-mode',
    default: new Promise((resolve) => {
        if (typeof window === 'undefined') {
            resolve('grid');
            return;
        }
        const search = new URLSearchParams(window.location.search);
        if (search.get('v') === 'speaker') {
            resolve('speaker');
            return;
        }
        resolve('grid');
        return;
    }),
    effects: [
        /**
         * Synchronizes atom state with cookie.
         */
        ({ onSet, setSelf, trigger }) => {
            if (trigger === 'get') {
                const storedPreferredViewMode = Cookies.get(
                    PREFERRED_VIEW_MODE_COOKIE_KEY,
                ) as PreferredViewMode;
                if (storedPreferredViewMode) {
                    setSelf(storedPreferredViewMode);
                }
            }
            onSet((newPreferredViewMode) => {
                Cookies.set(PREFERRED_VIEW_MODE_COOKIE_KEY, newPreferredViewMode, {
                    expires: new Date('Tue, 19 Jan 2038 03:14:07 GMT'),
                    sameSite: 'none',
                    secure: true,
                });
            });
        },
    ],
});
/**
 * Returns the currently preferred view mode and a setter to change it.
 */
export const usePreferredViewMode = () => useRecoilState(preferredViewModeState);

type ViewMode = PreferredViewMode | 'mobile';

const viewModeSelector = selector<ViewMode>({
    key: 'view-mode',
    get: ({ get }) => {
        const isMobile = get(isMobileState);
        if (isMobile) {
            return 'mobile';
        }
        const pinnedId = get(pinnedIdState);
        if (pinnedId) {
            return 'speaker';
        }
        const preferredViewMode = get(preferredViewModeState);
        return preferredViewMode;
    },
});
/**
 * Returns the current view mode: 'grid', 'speaker' or 'mobile'.
 * In case a participant or screen is pinned or a screen is shared, it will default to 'speaker'.
 */
export const useViewMode = () => {
    const screens = useScreens();
    const viewMode = useRecoilValue(viewModeSelector);
    const [pinnedId] = usePinnedId();
    const { runningMainIntegrations } = useCustomIntegrations();
    if (
        (screens.length + runningMainIntegrations.length > 0 && viewMode !== 'mobile') ||
        pinnedId
    ) {
        return 'speaker';
    }
    return viewMode;
};

// camera aspect ratio
const CAMERA_ASPECT_RATIO_COOKIE_KEY = 'camera-aspect-ratio';
export type CameraAspectRatio = '16:9' | '1:1';

const aspectRatioState = atom({
    key: 'camera-aspect-ratio',
    default: '1:1',
    effects: [
        /**
         * Synchronizes atom state with cookie.
         */
        ({ onSet, setSelf, trigger }) => {
            if (trigger === 'get') {
                const storedCameraAspectRation = Cookies.get(
                    CAMERA_ASPECT_RATIO_COOKIE_KEY,
                ) as CameraAspectRatio;
                if (storedCameraAspectRation) {
                    setSelf(storedCameraAspectRation);
                }
            }
            onSet((newCameraAspectRation) => {
                Cookies.set(CAMERA_ASPECT_RATIO_COOKIE_KEY, newCameraAspectRation, {
                    expires: new Date('Tue, 19 Jan 2038 03:14:07 GMT'),
                    sameSite: 'none',
                    secure: true,
                });
            });
        },
    ],
});

export const useCameraAspectRatio = () => {
    const [aspectRatio, setAspectRatio] = useRecoilState(aspectRatioState);

    return { aspectRatio, setAspectRatio };
};

// frame your shot overlay
const FRAME_UP_YOUR_SHOT_OVERLAY_COOKIE_KEY = 'frame-your-shot-overlay';
export type FrameYourShotOverlay = 'none' | 'grid' | 'centerYourself';

const frameYourShotOverlayState = atom({
    key: FRAME_UP_YOUR_SHOT_OVERLAY_COOKIE_KEY,
    default: 'none',
    effects: [
        /**
         * Synchronizes atom state with cookie.
         */
        ({ onSet, setSelf, trigger }) => {
            if (trigger === 'get') {
                const storedFrameUpYourShotOverlay = Cookies.get(
                    FRAME_UP_YOUR_SHOT_OVERLAY_COOKIE_KEY,
                ) as FrameYourShotOverlay;
                if (storedFrameUpYourShotOverlay) {
                    setSelf(storedFrameUpYourShotOverlay);
                }
            }
            onSet((newFrameUpYourShotOverlay) => {
                Cookies.set(FRAME_UP_YOUR_SHOT_OVERLAY_COOKIE_KEY, newFrameUpYourShotOverlay, {
                    expires: new Date('Tue, 19 Jan 2038 03:14:07 GMT'),
                    sameSite: 'none',
                    secure: true,
                });
            });
        },
    ],
});

export const useFrameYourShotOverlay = () => {
    const [frameYourShotOverlay, setFrameYourShotOverlay] = useRecoilState(
        frameYourShotOverlayState,
    );

    return { frameYourShotOverlay, setFrameYourShotOverlay };
};
