// Copyright 2022 Descript, Inc

import { stringMd5Hex_Spark } from '@descript/descript-model';
import { DescriptError, ErrorCategory } from '@descript/errors';
import * as SparkMD5 from 'spark-md5';

export interface Hasher {
    maxConcurrency: number;
    fileMd5Hex(filename: string): Promise<string>;
    bufferMd5Hex(buffer: ArrayBuffer): Promise<string>;
    stringMd5Hex(str: string): Promise<string>;
    stringMd5HexSync(str: string): string;
}

let _hasher: Hasher = {
    maxConcurrency: 1,
    fileMd5Hex: () => {
        throw new DescriptError('fileMd5Hex not implemented', ErrorCategory.VideoMediaEngine);
    },
    bufferMd5Hex: async (buffer: ArrayBuffer) => arrayBufferMd5Hex(buffer),
    stringMd5Hex: async (str: string) => stringMd5Hex_Spark(str),
    stringMd5HexSync: stringMd5Hex_Spark,
};

export function setHasher(hasher: Hasher): void {
    _hasher = hasher;
}
export function getHasher(): Hasher {
    return _hasher;
}

export const arrayBufferMd5Hex: (buffer: ArrayBuffer) => string = SparkMD5.ArrayBuffer.hash;
