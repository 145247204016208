import { DailyEventObjectMeetingSessionStateUpdated } from '@daily-co/daily-js';
import { useDailyEvent } from '@daily-co/daily-react';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';

// update this atom every time meeting-session-state-updated is called
const sessionDataState = atom({
    key: 'meeting-session-data-state',
    default: {}, // don't know what this looks like
});

interface UseMeetingSessionDataArgs {
    onMeetingSessionStateUpdated?(event: DailyEventObjectMeetingSessionStateUpdated): void;
}

export const useMeetingSessionData = <T = unknown>({
    onMeetingSessionStateUpdated,
}: UseMeetingSessionDataArgs = {}) => {
    useDailyEvent(
        'meeting-session-state-updated',
        useRecoilCallback(
            ({ set }) =>
                (event) => {
                    set(sessionDataState, event.meetingSessionState.data);
                    onMeetingSessionStateUpdated?.(event);
                },
            [onMeetingSessionStateUpdated],
        ),
    );

    const sessionData = useRecoilValue(sessionDataState) as T;

    return {
        sessionData,
    };
};
