// Copyright 2024 Descript, Inc
import { useParticipantProperty } from '@daily-co/daily-react';
import { useEffect, useState } from 'react';
import { ReactNull } from '@descript/react-utils';

export const useAvatarImage = (sessionId: string) => {
    const userData = useParticipantProperty(sessionId, 'userData') as unknown;
    const [isValid, setIsValid] = useState(false);

    const url = userData?.p?.descriptUser?.profileImageCdnUrl;

    useEffect(() => {
        if (!url) {
            return;
        }
        const image = new Image();
        image.onload = () => {
            setIsValid(true);
        };
        image.onerror = () => {
            setIsValid(false);
            console.warn(`Could not load avatar image at ${url}`);
        };
        image.src = url;
    }, [url]);

    return Boolean(url) && isValid ? url : ReactNull;
};
