// Copyright 2022 Descript, Inc

import { AssetTracingMetadata } from '@descript/analytics';
import { TextMapPropagator, TracerProvider, propagation } from '@opentelemetry/api';
import {
    CompositePropagator,
    W3CBaggagePropagator,
    W3CTraceContextPropagator,
} from '@opentelemetry/core';
import { Resource } from '@opentelemetry/resources';
import {
    AlwaysOnSampler,
    ParentBasedSampler,
    Sampler,
    SimpleSpanProcessor,
    SpanProcessor,
} from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { InMemoryTransmission } from '../InMemoryTransmission';
import * as SpanNames from '../names';
import { LegacyTransmissionExporter } from './LegacyTransmissionExporter';
import { RatioSampler, SpanNameSampler } from './sampler';
import { addConsoleSpanProcessor, addDefaultAttributesProcessor } from './spanProcessors';

function makeApiSamplers(sampler: Sampler) {
    const result: Record<string, Sampler> = {};
    for (const name of [
        SpanNames.ApiClient_Reauthorize,
        SpanNames.ApiClient_Request,
        SpanNames.ApiClient_PublicApiRequest,
        SpanNames.ApiClient_RawResponseRequest,
        SpanNames.ApiClient_BlobRequest,
    ]) {
        result[name] = sampler;
    }
    return result;
}

export function makeBrowserSampler(): Sampler {
    const ALWAYS = new AlwaysOnSampler();
    return new SpanNameSampler({
        nameSamplers: {
            [SpanNames.Redux_Dispatch]: new RatioSampler(1000),
            [SpanNames.Redux_ActionsBatch]: new RatioSampler(10000),
            // Live Collab
            [SpanNames.LiveCollab_PersistDocument]: new RatioSampler(100),
            [SpanNames.LiveCollab_InvalidState]: new RatioSampler(10), // match project load to compare
            [SpanNames.Editor_ExportBackup]: new RatioSampler(10),

            [SpanNames.LiveCollab_CommitLifeCycle]: new RatioSampler(100),
            [SpanNames.LiveCollab_CommitLifeCycle_create]: ALWAYS,
            [SpanNames.LiveCollab_CommitLifeCycle_localSave]: ALWAYS,
            [SpanNames.LiveCollab_CommitLifeCycle_remoteSend]: ALWAYS,
            [SpanNames.LiveCollab_CommitLifeCycle_remoteAck]: ALWAYS,

            [SpanNames.LiveCollab_Diff]: new RatioSampler(1000),
            [SpanNames.LiveCollab_Patch]: new RatioSampler(1000),
            [SpanNames.LiveCollab_Merge]: new RatioSampler(1000),

            // Load Project (all child spans are set to ALWAYS)
            [SpanNames.Editor_LoadProject]: new RatioSampler(10),
            [SpanNames.Editor_LoadProject_fetchProject]: ALWAYS,
            [SpanNames.Editor_LoadProject_connectLiveCollab]: ALWAYS,
            [SpanNames.Editor_LoadProject_readyLocalRead]: ALWAYS,
            [SpanNames.Editor_LoadProject_readyRemoteRead]: ALWAYS,

            // Drive View
            [SpanNames.DriveView_PageLoad]: new RatioSampler(10),
            [SpanNames.DriveView_RowLoad]: new RatioSampler(100),

            // Web Media
            [SpanNames.WebMedia_AddMedia]: new RatioSampler(10),
            [SpanNames.WebMedia_ReadMediaMetadata]: new RatioSampler(10),
            [SpanNames.WebMedia_SyncMetadata_FirstSync]: ALWAYS,
            [SpanNames.WebMedia_SyncMetadata]: new RatioSampler(1000),
            [SpanNames.WebMedia_ExamineFileGops]: ALWAYS,

            [SpanNames.PlaybackSaga_StartPlayback]: new RatioSampler(10),
            [SpanNames.PlaybackSaga_SeekToPlayback]: new RatioSampler(10),
            [SpanNames.PlaybackSaga_Scrub]: new RatioSampler(100),

            // AI Feature Processing
            [SpanNames.AIFeature_BackgroundRemoval]: new RatioSampler(5),
            [SpanNames.AIFeature_CorrectionWizard]: new RatioSampler(5),
            [SpanNames.AIFeature_Diarization]: new RatioSampler(5),
            [SpanNames.AIFeature_EyeContact]: new RatioSampler(5),
            [SpanNames.AIFeature_RoomTone]: new RatioSampler(5),
            [SpanNames.AIFeature_StudioSound]: new RatioSampler(5),

            [SpanNames.ExportMediaSaga_ExportCompositionAsMedia]: ALWAYS,
            [SpanNames.ExportTimelineSaga_ExportCompositionAsTimeline]: ALWAYS,
            [SpanNames.EditorRecorder_Workflow]: ALWAYS,
            [SpanNames.Recorder_RecoveryWorkflow]: ALWAYS,

            // Recorder Workflow v2
            [SpanNames.EditorRecorder_Setup]: ALWAYS,
            [SpanNames.EditorRecorder_Recording]: ALWAYS,
            [SpanNames.EditorRecorder_Import]: ALWAYS,
            [SpanNames.WebRecorder_Session]: ALWAYS,
            [SpanNames.WebRecorder_SetupInputs]: ALWAYS,
            [SpanNames.WebRecorder_InitRecorder]: ALWAYS,
            [SpanNames.WebRecorder_StartWorkflow]: ALWAYS,
            [SpanNames.WebRecorder_Setup]: ALWAYS,
            [SpanNames.WebRecorder_CreateAsset]: ALWAYS,
            [SpanNames.WebRecorder_CreateArtifact]: ALWAYS,
            [SpanNames.WebRecorder_DataAvailableSetup]: ALWAYS,
            [SpanNames.WebRecorder_DataAvailableUpload]: ALWAYS,
            [SpanNames.WebRecorder_ReadMetadata]: ALWAYS,
            [SpanNames.WebRecorder_ReifyArtifact]: ALWAYS,
            [SpanNames.WebRecorder_CommitArtifact]: ALWAYS,
            [SpanNames.WebRecorder_WaitForWorkflow]: ALWAYS,
            [SpanNames.WebRecorder_AutoRecovery]: ALWAYS,
            [SpanNames.WebRecorder_RecoveryDownload]: ALWAYS,
            [SpanNames.WebRecorder_RecoverySession]: ALWAYS,

            // AI Speech
            [SpanNames.AISpeech_CreateTTS]: new RatioSampler(5),
            [SpanNames.AISpeech_CreateOverdub]: ALWAYS,
            [SpanNames.AISpeech_CreateRegenerate]: ALWAYS,

            [SpanNames.EditorRecorder_StandaloneWorkflow]: ALWAYS,
            [SpanNames.LiveTranscription_Workflow]: ALWAYS,
            [SpanNames.AlignmentSaga_ProcessAlignmentJob]: new RatioSampler(10),
            [SpanNames.Editor_GetEnergies]: new RatioSampler(100),
            [SpanNames.TemplateProjectSaga_ApplyTemplate]: ALWAYS,
            [SpanNames.TemplateProjectSaga_InsertCardFromTemplate]: ALWAYS,
            [SpanNames.Template_LoadPreview]: new RatioSampler(10),
            [SpanNames.PublishSaga_StartPublishContent]: new RatioSampler(100),
            [SpanNames.PublishSaga_ExecutePublishContent]: ALWAYS,
            [SpanNames.YouTube_StartPublish]: ALWAYS,
            [SpanNames.GoogleDrive_StartPublish]: ALWAYS,
            [SpanNames.TemplateProjectSaga_SaveCardToTemplate]: ALWAYS,

            // Plans and payments
            [SpanNames.ManageSubscriptionModal_load]: new RatioSampler(10),
            [SpanNames.ManageSubscriptionModal_close]: new RatioSampler(10),
            [SpanNames.ManageSubscriptionModal_submit]: ALWAYS,

            [SpanNames.NonDriveOwnerModal_load]: new RatioSampler(10),
            [SpanNames.NonDriveOwnerModal_close]: new RatioSampler(10),

            [SpanNames.ManageIncompleteSubscriptionModal_load]: new RatioSampler(10),
            [SpanNames.ManageIncompleteSubscriptionModal_close]: new RatioSampler(10),
            [SpanNames.ManageIncompleteSubscriptionModal_submit]: ALWAYS,

            [SpanNames.EditCreditCardModal_load]: new RatioSampler(10),
            [SpanNames.EditCreditCardModal_close]: new RatioSampler(10),
            [SpanNames.EditCreditCardModal_submit]: ALWAYS,

            [SpanNames.PlansAndPayments_CreateNewDrive]: ALWAYS,
            [SpanNames.PlansAndPayments_PaymentElementNotLoaded]: ALWAYS,
            [SpanNames.PlansAndPayments_SetupPaymentMethod]: ALWAYS,
            [SpanNames.PlansAndPayments_UpdateDriveSubscription]: ALWAYS,
            [SpanNames.PlansAndPayments_UpdatePlan]: ALWAYS,

            [SpanNames.PlansPage_load]: new RatioSampler(10),
            [SpanNames.CheckoutPage_load]: new RatioSampler(10),
            [SpanNames.UpdatePaymentMethodPage_load]: new RatioSampler(10),
            [SpanNames.UpdatePaymentMethodPage_submit]: ALWAYS,
            [SpanNames.CreateNewDrivePage_load]: new RatioSampler(10),
            [SpanNames.InvitesForNewDrivePage_load]: new RatioSampler(10),
            [SpanNames.InvitesForNewDrivePage_complete]: new RatioSampler(10),

            [SpanNames.SubscriptionSettingsRedesign_load]: new RatioSampler(10),

            [SpanNames.Onboarding_pricing_complete]: new RatioSampler(10),
            [SpanNames.Onboarding_pricing_skippedDueToPlansLoadError]: ALWAYS,

            // web vitals
            [SpanNames.WebVitals_INP]: ALWAYS,

            // Add file process
            [SpanNames.AddMediaFiles]: ALWAYS,
            [SpanNames.AddMediaFile]: ALWAYS,
            [SpanNames.AddMediaFilesToDocument]: ALWAYS,
            [SpanNames.GainCalculationSaga_AddDefaultGainToFile]: ALWAYS,

            // Video player; spans names are not shared due to being in the web-media-engine package
            'VideoPlayer.contextLost': new RatioSampler(10),

            // Will always sample a span named 'Test'
            Test: ALWAYS,

            ...makeApiSamplers(new RatioSampler(1000)),
        },
        defaultSampler: new RatioSampler(100),
    });
}

export function makeMainSampler(): Sampler {
    const SAMPLE_1000 = new RatioSampler(1000);
    const ALWAYS = new AlwaysOnSampler();
    return new SpanNameSampler({
        nameSamplers: {
            ...makeApiSamplers(SAMPLE_1000),
            [SpanNames.Fetch]: SAMPLE_1000,
            [SpanNames.Recorder_RecoveryWorkflow]: ALWAYS,
            [SpanNames.EditorRecorder_Setup]: ALWAYS,
            [SpanNames.EditorRecorder_Recording]: ALWAYS,
            [SpanNames.QuickRecorder_CommitArtifact]: ALWAYS,
            [SpanNames.QuickRecorder_UploadWait]: ALWAYS,
            [SpanNames.QuickRecorder_ReadMetadata]: ALWAYS,
            [SpanNames.QuickRecorder_ReifyArtifact]: ALWAYS,
            [SpanNames.App_CheckForUpdate]: new RatioSampler(100),
        },
        // sample 20% of traces on Main, like we were doing when we used to determine sampling
        // purely at the user level via the feature flag
        defaultSampler: new RatioSampler(5),
    });
}

/**
 * Makes a tracer provider for use on web or an electron renderer
 */
export function makeTracerProvider(serviceName: string, sampler: Sampler): WebTracerProvider {
    return new WebTracerProvider({
        resource: new Resource({
            [SemanticResourceAttributes.SERVICE_NAME]: serviceName,
        }),
        sampler: new ParentBasedSampler({
            root: sampler,
        }),
    });
}

export function makePropagator(): TextMapPropagator {
    return new CompositePropagator({
        propagators: [new W3CTraceContextPropagator(), new W3CBaggagePropagator()],
    });
}

/**
 * Choose a tracer provider based on environmental configs
 *
 * Note, install app settings (and sync feature flags) first if
 * you want this to be gateable dynamically on the client.
 *
 * The environmental inputs here are (all boolean):
 *
 * - 'client-event-logging' LaunchDarkly gate
 * - process.env.REACT_APP_FEATURE_SPAN_TRACING
 * - process.env.REACT_APP_FEATURE_SPAN_TRACING_API
 */
export function configureBrowserTracerProvider({
    serviceName,
    clientEventLoggingFlag,
    getDefaultSpanMetadata,
    // send traces to the server (and on to honeycomb)
    spanTransmissionProcessor = new SimpleSpanProcessor(
        new LegacyTransmissionExporter(InMemoryTransmission.getShared()),
    ),
}: {
    serviceName: string;
    clientEventLoggingFlag: boolean;
    getDefaultSpanMetadata: () => AssetTracingMetadata;
    spanTransmissionProcessor?: SpanProcessor;
}): TracerProvider | undefined {
    if (DescriptFeatures.SPAN_TRACING || clientEventLoggingFlag) {
        const provider = makeTracerProvider(serviceName, makeBrowserSampler());
        addDefaultAttributesProcessor(provider, getDefaultSpanMetadata);

        propagation.setGlobalPropagator(makePropagator());

        if (DescriptFeatures.SPAN_TRACING_API || clientEventLoggingFlag) {
            provider.addSpanProcessor(spanTransmissionProcessor);
        }

        if (DescriptFeatures.SPAN_TRACING) {
            // log traces to the console
            addConsoleSpanProcessor(provider);
        }

        return provider;
    } else {
        return undefined;
    }
}

/**
 * Immediately send queued OpenTelemetry events to server
 */
export async function forceFlushTransmission(): Promise<void> {
    return await InMemoryTransmission.getShared().flush();
}
