// Copyright 2020 Descript, Inc

import { useEffect, useLayoutEffect } from 'react';

// Used to suppress SSR errors when useLayoutEffect is used in a component.
// We can use this on a case-by-case basis as a clean way to handle this error.
// Source: https://github.com/reduxjs/react-redux/issues/1373#issuecomment-519177210
export const useIsomorphicLayoutEffect =
    typeof window !== 'undefined' &&
    typeof window.document !== 'undefined' &&
    typeof window.document.createElement !== 'undefined'
        ? useLayoutEffect
        : useEffect;
