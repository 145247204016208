import * as React from 'react';

import { useTheme } from '../../contexts/Theme';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function StarIcon({
    fill = 'var(--nav-icon-fill)',
    stroke = 'var(--nav-icon-stroke)',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    const { colors } = useTheme();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
            <path
                d="M7.19861 2.02916L7.19525 2.0368L7.19214 2.04454L5.86454 5.35655L2.27499 5.78642L2.27499 5.78642L2.27279 5.78669C1.50116 5.88258 1.26034 6.82934 1.77401 7.32719L1.77388 7.32733L1.78428 7.33687L4.49749 9.82193L3.66759 13.4123L3.66535 13.422L3.66349 13.4318C3.53175 14.1266 4.26076 14.7922 4.96395 14.3797L8 12.5986L11.036 14.3797C11.7392 14.7922 12.4682 14.1266 12.3365 13.4318L12.3347 13.422L12.3324 13.4123L11.5025 9.82193L14.2157 7.33687L14.2159 7.33701L14.226 7.32719C14.7397 6.82934 14.4988 5.88258 13.7272 5.78669L13.7272 5.78668L13.7245 5.78636L10.1021 5.35655L8.80946 2.04859L8.80563 2.03879L8.80139 2.02916C8.49088 1.32361 7.50913 1.32361 7.19861 2.02916Z"
                strokeLinecap="butt"
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    fill: ${fill ?? colors.system.grey};
                    height: ${size}px;
                    stroke: ${stroke ?? colors.system.greyDark};
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
