// Copyright 2023 Descript, Inc
import { useMemo } from 'react';

import * as React from 'react';
import { raf } from '@react-spring/rafz';
import { Portal } from '@radix-ui/react-portal';
import { useEventCallback, useOnOff, useThrottleWithRaf, ReactNull } from './index';
import { DescriptError, ErrorCategory } from '@descript/errors';

/** Request to enabled pointer lock in the browser. */
async function requestPointerLock() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const body = document.body as any;

    if (body.requestPointerLock) {
        await body.requestPointerLock();
    } else if (body.mozRequestPointerLock) {
        await body.mozRequestPointerLock();
    } else if (body.webkitRequestPointerLock) {
        await body.webkitRequestPointerLock();
    }

    throw new DescriptError('Pointer lock not supported', ErrorCategory.AppArchitecture);
}

function isSafari() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Boolean((window as any).safari);
}

/** Request to exit pointer lock in the browser. */
function exitPointerLock() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const doc = document as any;

    if (doc.exitPointerLock) {
        doc.exitPointerLock();
    } else if (doc.mozExitPointerLock) {
        doc.mozExitPointerLock();
    } else if (doc.webkitExitPointerLock) {
        doc.webkitExitPointerLock();
    }
}

type Point = { x: number; y: number };
export type PointerMoveInfo = {
    cursor: Point;
    delta: Point;
    shiftKey: boolean;
    metaKey: boolean;
};
type PointerMoveHandler = (pointerMoveInfo: PointerMoveInfo) => void;

/**
 * A hook that returns a set of functions to enable and disable pointer lock.
 */
export function createPointerLock() {
    let didSafariFail = false;
    let enabled = false;

    /**
     * Run a rAF loop that waits for `enabled` to be true before enabling pointer lock.
     * This is necessary because Safari will not enable pointer lock unless the user
     * initiates it from and click event. `pointermove` does not work. If it did this hack
     * is not longer needed.
     */
    function waitForPointerLock() {
        if (enabled) {
            didSafariFail = false;
            requestPointerLock().catch(() => {
                didSafariFail = true;
            });
            // Stop waiting
            return false;
        }

        // Continue looping
        return true;
    }

    function request() {
        // On safari the pointer lock request cannot be made during a pointermove, so
        // doing this in attach doesn't work. The rAF here is a hack to make it work.
        if (isSafari()) {
            raf(waitForPointerLock);
        }
    }

    function cancelRequest() {
        raf.cancel(waitForPointerLock);
    }

    function attach({
        event,
        onMoveEnd,
        onMove,
    }: {
        event: React.MouseEvent;
        /** Called when pointer lock is exited. */
        onMoveEnd?: (event: PointerEvent) => void;
        /** Called when the mouse moves while pointer lock is enabled. */
        onMove: PointerMoveHandler;
    }) {
        // eslint-disable-next-line @descript-eslint/no-force-reflow
        const maxWidth = window.innerWidth;
        // eslint-disable-next-line @descript-eslint/no-force-reflow
        const maxHeight = window.innerHeight;
        let x = event.pageX;
        let y = event.pageY;

        function onExitPointerLock(exitEvent: PointerEvent) {
            onMoveEnd?.(exitEvent);
            exitPointerLock();
            enabled = false;
        }

        let lastX = x;
        let lastY = y;
        let hasMoved = false;

        function onLockMove(moveEvent: PointerEvent) {
            if (moveEvent.movementX === 0) {
                return;
            }

            let { movementX, movementY } = moveEvent;

            if (movementX === undefined && movementY === undefined) {
                movementX = moveEvent.pageX - lastX;
                movementY = moveEvent.pageY - lastY;
            }

            // On windows sometimes the intiial movement will be some super large value
            // which makes the cursor jump to a position far outside the field.
            // This code accounts for that by limiting the intial movement value on the first
            // movement.
            if (!hasMoved && (Math.abs(movementX) >= 10 || Math.abs(movementY) >= 10)) {
                movementX = movementX === 0 ? 0 : 1 * (movementX / Math.abs(movementX));
                movementY = movementY === 0 ? 0 : 1 * (movementY / Math.abs(movementY));
            }

            hasMoved = true;
            x = x + movementX;
            y = y + movementY;
            lastX = x;
            lastY = y;

            x %= maxWidth;

            if (x < 0) {
                x += maxWidth;
            }

            y %= maxHeight;

            if (y < 0) {
                y += maxHeight;
            }

            onMove({
                cursor: { x, y },
                delta: { x: movementX, y: movementY },
                shiftKey: moveEvent.shiftKey,
                metaKey: moveEvent.metaKey,
            });
        }

        /**
         * This callback is called once the pointer lock has actually been enabled and the mouse is hidden.
         * https://developer.mozilla.org/en-US/docs/Web/API/Document/pointerlockchange_event
         */
        function onLockChange() {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const doc = document as any;
            const pointerLockElement =
                doc.pointerLockElement ||
                doc.mozPointerLockElement ||
                doc.webkitPointerLockElement;

            if (pointerLockElement === document.body) {
                document.addEventListener('pointermove', onLockMove, false);
            } else {
                document.removeEventListener('pointermove', onLockMove, false);
                document.removeEventListener('pointerlockchange', onLockChange, false);
                document.removeEventListener('mozpointerlockchange', onLockChange, false);
            }
        }

        document.addEventListener('pointerlockchange', onLockChange, false);
        document.addEventListener('mozpointerlockchange', onLockChange, false);
        document.addEventListener('pointerup', onExitPointerLock, { once: true });
        enabled = true;

        function attachFallbackBehavior() {
            // If pointer lock fails, we'll just use the pointermove event.
            // This is used for old browsers and the playwright tests.
            document.addEventListener('pointermove', onLockMove, false);
            document.addEventListener(
                'pointerup',
                () => document.removeEventListener('pointermove', onLockMove, false),
                { once: true },
            );
        }

        if (isSafari()) {
            // eslint-disable-next-line @descript-eslint/no-force-reflow
            const initialHeight = window.innerHeight;

            function adjustForBannerHeight() {
                // eslint-disable-next-line @descript-eslint/no-force-reflow
                const newHeight = window.innerHeight - initialHeight;
                document.documentElement.style.transform = `translate(0, ${newHeight}px)`;
            }

            window.addEventListener('resize', adjustForBannerHeight);
            document.addEventListener(
                'pointerup',
                () => document.removeEventListener('resize', adjustForBannerHeight),
                { once: true },
            );

            if (didSafariFail) {
                attachFallbackBehavior();
            }
        } else {
            requestPointerLock().catch(attachFallbackBehavior);
        }
    }

    return {
        /**
         * Start waiting for pointer lock to be enabled.
         * This function must be called from a user event handler
         * such as onClick.
         */
        request,
        /**
         * Stop waiting for pointer lock to be enabled.
         */
        cancelRequest,
        /**
         * Attach events that watch the pointer moving while in pointer lock.
         * This function will call onMove with the x position of the pointer.
         */
        attach,
    };
}

export function usePointerLock({
    cursor,
    onMove: onMoveProp,
    onMoveStart,
    onMoveEnd: onMoveEndProp,
}: {
    cursor?: React.RefObject<PointerLockHandle>;
    onMove: PointerMoveHandler;
    onMoveStart?: (event: React.PointerEvent | PointerEvent) => void | boolean;
    onMoveEnd?: (info: PointerMoveInfo, event: React.PointerEvent | PointerEvent) => void;
}) {
    const [isLocked, lock, unlock] = useOnOff();
    const [isPointerDown, pointerDown, pointerUp] = useOnOff();

    const moveInfoRef = React.useRef<PointerMoveInfo | undefined>(undefined);
    const [onMove, cancelMove] = useThrottleWithRaf({
        update: (info: PointerMoveInfo) => {
            onMoveProp(info);
            moveInfoRef.current = info;
            cursor?.current?.updatePosition(info.cursor);
        },
    });
    const onMoveEnd = useEventCallback((event: React.PointerEvent | PointerEvent) => {
        pointerUp();
        unlock();
        cursor?.current?.updateVisibility(false);
        cursor?.current?.updatePosition(undefined);

        if (!moveInfoRef.current) {
            return;
        }

        onMoveEndProp?.(moveInfoRef.current, event);
    });

    const { request, cancelRequest, attach } = useMemo(createPointerLock, []);
    const onPointerDown = useEventCallback((event: React.PointerEvent | PointerEvent) => {
        cursor?.current?.updateVisibility(false);
        cursor?.current?.updatePosition(undefined);
        moveInfoRef.current = undefined;

        const shouldPointerLock = onMoveStart?.(event);

        if (shouldPointerLock === false) {
            return;
        }

        pointerDown();
        request();
    });
    const onPointerMove = useEventCallback((event: React.MouseEvent) => {
        if (isPointerDown && !isLocked) {
            lock();
            attach({ event, onMove, onMoveEnd });
            cursor?.current?.updateVisibility(true);
        }
    });
    const onPointerUp = useEventCallback((event: React.PointerEvent | PointerEvent) => {
        cancelMove();
        onMoveEnd(event);
        cancelRequest();
    });

    return {
        isLocked,
        isPointerDown,
        pointerLockProps: {
            onPointerDown,
            onPointerMove,
            onPointerUp,
        },
    };
}

export interface PointerLockHandle {
    updatePosition: (point: Point | undefined) => void;
    updateVisibility: (visible: boolean) => void;
}

export const PointerLockCursor = React.forwardRef(function PointerLockCursor(
    {
        children,
        cursorPoint,
    }: {
        children: React.ReactNode;
        /** A point within the cursor element that should correspond to the tip of the cursor  */
        cursorPoint?: Point;
    },
    ref?: React.Ref<PointerLockHandle>,
) {
    const { x = 0, y = 0 } = cursorPoint || {};
    const [position, setPosition] = React.useState<Point | undefined>(undefined);
    const [visible, setVisible] = React.useState(false);

    React.useImperativeHandle(
        ref,
        () => ({
            updatePosition: setPosition,
            updateVisibility: setVisible,
        }),
        [],
    );

    if (!visible || !position) {
        return ReactNull;
    }

    return (
        <Portal>
            <div
                className="absolute portal-layer top-0 left-0"
                style={{ transform: `translate(${position.x - x}px, ${position.y - y}px)` }}
            >
                {children}
            </div>
        </Portal>
    );
});
