import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function SpeakerViewIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M2 2.5H7C7.27614 2.5 7.5 2.72386 7.5 3V13C7.5 13.2761 7.27614 13.5 7 13.5H2C1.72386 13.5 1.5 13.2761 1.5 13V3C1.5 2.72386 1.72386 2.5 2 2.5Z"
                stroke={color}
            />
            <path d="M11 6.5L15 6.5" stroke={color} />
            <path d="M11 3.5L15 3.5" stroke={color} />
            <path d="M11 9.5L15 9.5" stroke={color} />
            <path d="M11 12.5L15 12.5" stroke={color} />
        </svg>
    );
}
