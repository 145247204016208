// Copyright 2024 Descript, Inc
import classnames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';

import { useTheme } from '../../contexts/Theme';
import { useResize } from '../../hooks/useResize';
import { Portal } from '../Portal';
import { Text } from '../Text';
import { ReactNull } from '@descript/react-utils';
import { Button } from '../Button';
import { CloseIcon } from '../Icons';

interface Props {
    border?: boolean;
    delay?: number;
    disableListeners?: boolean;
    disabled?: boolean;
    id: string;
    open?: boolean;
    placement?: 'top' | 'bottom';
    showCloseButton?: boolean;
    style?: React.CSSProperties;
    tabIndex?: number;
    title: string;
    toggleStyle?: React.CSSProperties;
}

type TooltipAlign = 'center' | 'left' | 'right';
interface TooltipPosition {
    left?: number;
    top?: number;
}

/**
 * Offset for the arrow to not live on the corner.
 */
const ALIGN_OFFSET = 8;
/**
 * The spacing between the toggle and the tooltip.
 */
const SPACING = 8;

/**
 * const outside component to avoid {} being recreated on every render,
 * which would lead to an inifite render loop for the realign useEffect.
 */
const defaultStyle = {};

export function Tooltip({
    border = false,
    children,
    delay = 0,
    disableListeners = false,
    disabled = false,
    id,
    open = false,
    placement = 'top',
    showCloseButton = false,
    style = defaultStyle,
    tabIndex = 0,
    title,
    toggleStyle = ReactNull,
}: React.PropsWithChildren<Props>) {
    const { zIndex, isDarkMode } = useTheme();
    const [isOpen, setIsOpen] = useState(open);
    const toggleRef = useRef<HTMLSpanElement>(ReactNull);
    const tooltipRef = useRef<HTMLDivElement>(ReactNull);
    const [align, setAlign] = useState<TooltipAlign>('center');
    const [position, setPosition] = useState<TooltipPosition | null>(ReactNull);

    const handleClick = () => setIsOpen(true);
    const handleFocus = (ev: React.FocusEvent) => {
        /*
         * When navigating with a keyboard, focus usually shifts from one element to another.
         * The previously focused element is referenced in relatedTarget.
         * For programmatic focus shift or when focus happens due to a mouse click,
         * there isn't necessarily a relatedTarget set.
         * While this doesn't cover 100% of cases, it gets us close enough to only
         * open the tooltip, when focused via keyboard navigation.
         */
        if (!ev.relatedTarget) {
            return;
        }
        setIsOpen(true);
    };

    const handleKeyDown = (ev: React.KeyboardEvent) => {
        if (ev.key !== 'Enter') {
            return;
        }
        setIsOpen(true);
    };
    const handlePointerEnter = () => setIsOpen(true);
    const handlePointerLeave = () => setIsOpen(false);

    const placeBelow = placement === 'bottom';

    /**
     * Reset isOpen state to open, unless tooltip is disabled.
     */
    useEffect(() => {
        setIsOpen(open && !disabled);
    }, [disabled, open]);

    const realign = useCallback(() => {
        if (!isOpen || disabled) {
            setPosition(ReactNull);
            return;
        }
        if (!toggleRef.current || !tooltipRef.current) {
            return;
        }

        const maybeUpdatePosition = (p: TooltipPosition) => {
            setPosition((prev) => {
                if (prev?.left === p?.left && prev?.top === p?.top) {
                    return prev;
                }
                return p;
            });
        };

        // eslint-disable-next-line @descript-eslint/no-force-reflow
        const toggleRect = toggleRef.current.getBoundingClientRect();
        // eslint-disable-next-line @descript-eslint/no-force-reflow
        const tooltipRect = tooltipRef.current.getBoundingClientRect();
        // eslint-disable-next-line @descript-eslint/no-force-reflow
        const main = document.querySelector('main');
        // eslint-disable-next-line @descript-eslint/no-force-reflow
        const mainRect = main?.getBoundingClientRect();
        const center = toggleRect.left + toggleRect.width / 2;
        const leftEdge = center - tooltipRect.width / 2;
        const rightEdge = center + tooltipRect.width / 2;
        // eslint-disable-next-line @descript-eslint/no-force-reflow
        let top = window.scrollY + toggleRect.top - tooltipRect.height - SPACING;

        if (placeBelow) {
            // eslint-disable-next-line @descript-eslint/no-force-reflow
            top = window.scrollY + toggleRect.bottom + SPACING;
        }

        // eslint-disable-next-line @descript-eslint/no-force-reflow
        if (rightEdge > (mainRect?.left || 0) + (mainRect?.width || window.innerWidth)) {
            setAlign('right');
            maybeUpdatePosition({
                left: toggleRect.right + ALIGN_OFFSET - tooltipRect.width,
                top,
            });
        } else if (leftEdge < (mainRect?.left || 0)) {
            setAlign('left');
            maybeUpdatePosition({
                left: toggleRect.left - ALIGN_OFFSET,
                top,
            });
        } else {
            setAlign('center');
            maybeUpdatePosition({
                left:
                    toggleRect.left +
                    (toggleRect.right - toggleRect.left) / 2 -
                    tooltipRect.width / 2,
                top,
            });
        }
    }, [disabled, isOpen, placeBelow]);

    useResize(realign);
    useEffect(() => {
        realign();
    }, [isOpen, open, realign, style, title, toggleStyle]);

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <span
            ref={toggleRef}
            aria-describedby={disabled ? undefined : id}
            onBlur={disableListeners || disabled ? ReactNull : handlePointerLeave}
            onClick={disableListeners || disabled ? ReactNull : handleClick}
            onFocus={disableListeners || disabled ? ReactNull : handleFocus}
            onKeyDown={disableListeners || disabled ? ReactNull : handleKeyDown}
            onPointerEnter={disableListeners || disabled ? ReactNull : handlePointerEnter}
            onPointerLeave={disableListeners || disabled ? ReactNull : handlePointerLeave}
            tabIndex={disabled ? -1 : tabIndex}
            style={toggleStyle}
        >
            {!disabled && (
                <Portal>
                    <div
                        ref={tooltipRef}
                        className={classnames('tooltip', align, {
                            border,
                            open: isOpen,
                            placeBelow,
                            flex: showCloseButton,
                        })}
                        id={id}
                        role="tooltip"
                        aria-hidden={!isOpen}
                        style={
                            {
                                ...style,
                                ...position,
                                // RECORDER TEAM THIS IS BAD
                                // eslint-disable-next-line @descript-eslint/no-force-reflow-in-render
                                '--toggle-width': `${toggleRef.current?.clientWidth}px`,
                            } as React.CSSProperties
                        }
                    >
                        <Text color={isDarkMode ? 'inverse' : 'white'}>{title}</Text>
                        {showCloseButton && (
                            <Button
                                onClick={() => setIsOpen(false)}
                                variant="ghost"
                                style={{
                                    marginLeft: '16px',
                                }}
                            >
                                <CloseIcon
                                    variant="thick"
                                    color={
                                        isDarkMode ? 'var(--text-inverse)' : 'var(--text-white)'
                                    }
                                    size={16}
                                />
                            </Button>
                        )}
                    </div>
                </Portal>
            )}
            {children}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                .tooltip.open {
                    transition-delay: ${delay}ms;
                }
            `}</style>
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                span {
                    border-radius: 2px;
                    box-shadow: 0 0 0 0 var(--focus-shadow);
                    display: block;
                    outline: none;
                    position: relative;
                    transition: box-shadow 200ms ease;
                }
                // span:not([tabindex='-1']):focus-within {
                //     box-shadow: 0 0 0 2px var(--focus-shadow);
                // }
                .tooltip {
                    background-color: ${isDarkMode ? '#FFFFFF' : '#1A1A1A'};
                    border-radius: 8px;
                    box-shadow: var(--tooltip-shadow);
                    color: var(--tooltip-color);
                    max-width: min(320px, 80vw);
                    opacity: 0;
                    padding: 8px 16px;
                    pointer-events: none;
                    position: absolute;
                    text-align: center;
                    top: -99em;
                    transition: opacity 200ms ease;
                    z-index: ${zIndex.tooltip};
                }
                .tooltip.flex {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                .tooltip.border {
                    border: 1px solid var(--tooltip-border);
                }
                .tooltip.open {
                    opacity: 1;
                }
                /* ::after is the arrow with the tooltips background color */
                .tooltip::after {
                    --tt-arrow-size: 7px;
                    --tt-arrow-color: ${isDarkMode ? '#FFFFFF' : '#1A1A1A'};
                }
                /* ::before is the arrow with the tooltips border color. */
                /* It simulates the border around the arrow. */
                .tooltip.border::before {
                    --tt-arrow-size: 6px;
                    --tt-arrow-color: var(--tooltip-border);
                }
                .tooltip::after,
                .tooltip.border::before {
                    border: var(--tt-arrow-size) solid transparent;
                    border-top-color: var(--tt-arrow-color);
                    content: '';
                    height: 0;
                    position: absolute;
                    top: 100%;
                    transform: scaleY(0.8);
                    transform-origin: top center;
                    width: 0;
                }
                .tooltip.placeBelow::after,
                .tooltip.placeBelow.border::before {
                    top: 0;
                    transform: scaleY(-0.8);
                }
                .tooltip.center::after,
                .tooltip.center.border::before {
                    left: calc(50% - var(--tt-arrow-size));
                }
                .tooltip.left::after,
                .tooltip.left.border::before {
                    left: calc(
                        ${ALIGN_OFFSET}px + calc(var(--toggle-width) / 2) - var(--tt-arrow-size)
                    );
                }
                .tooltip.right::after,
                .tooltip.right.border::before {
                    right: calc(
                        ${ALIGN_OFFSET}px + calc(var(--toggle-width) / 2) - var(--tt-arrow-size)
                    );
                }
            `}</style>
        </span>
    );
}
