import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
    variant?: 'thin' | 'thick';
}

export function ArrowRightIcon({
    color = 'currentColor',
    size = 24,
    variant = 'thin',
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M2 8H14" stroke={color} strokeWidth={variant === 'thick' ? 2 : 1} />
            <path d="M8 2L14 8L8 14" stroke={color} strokeWidth={variant === 'thick' ? 2 : 1} />
        </svg>
    );
}
