import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function GridViewIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M9.5 14.5V9.5H14.5V14.5H9.5Z" stroke={color} />
            <path d="M1.5 14.5V9.5H6.5V14.5H1.5Z" stroke={color} />
            <path d="M9.5 6.5V1.5H14.5V6.5H9.5Z" stroke={color} />
            <path d="M1.5 6.5V1.5H6.5V6.5H1.5Z" stroke={color} />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
