import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function ArchiveIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 7C5 5.89543 5.89543 5 7 5H17C18.1046 5 19 5.89543 19 7V9C19 9.74708 18.5904 10.3985 17.9835 10.7419C17.9944 10.8264 18 10.9125 18 11V17C18 18.1046 17.1046 19 16 19H8C6.89543 19 6 18.1046 6 17V11C6 10.9125 6.00561 10.8264 6.0165 10.7419C5.40961 10.3985 5 9.74707 5 9V7ZM8 11H16V17H8V11ZM7 9V7H17V9H7Z"
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    fill: ${color};
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
