// Copyright 2024 Descript, Inc
import { useLocalSessionId, useMediaTrack } from '@daily-co/daily-react';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';

const COOKIE_KEY = 'echo-cancellation-enabled';

const echoCancellationState = atom<boolean>({
    key: COOKIE_KEY,
    default: false,
    effects: [
        /**
         * Synchronizes atom state with cookie.
         */
        ({ onSet, setSelf, trigger }) => {
            if (trigger === 'get') {
                const storedEchoCancellationEnabled = Cookies.get(COOKIE_KEY);
                if (storedEchoCancellationEnabled) {
                    setSelf(
                        storedEchoCancellationEnabled === 'true'
                            ? true
                            : storedEchoCancellationEnabled === 'false'
                              ? false
                              : false,
                    );
                }
            }
            onSet((newEchoCancellationEnabled) => {
                Cookies.set(COOKIE_KEY, newEchoCancellationEnabled ? 'true' : 'false', {
                    expires: new Date('Tue, 19 Jan 2038 03:14:07 GMT'),
                    sameSite: 'none',
                    secure: true,
                });
            });
        },
    ],
});

export const useEchoCancellationEnabled = () => useRecoilState(echoCancellationState);

export const useEchoCancellation = () => {
    const [echoCancellationEnabled] = useEchoCancellationEnabled();
    const localSessionId = useLocalSessionId();
    const mediaTrack = useMediaTrack(localSessionId, 'audio');

    useEffect(() => {
        if (!mediaTrack.track) {
            return;
        }

        const constraints = mediaTrack.track.getConstraints() ?? {
            echoCancellation: false,
        };

        if (echoCancellationEnabled !== constraints.echoCancellation) {
            mediaTrack.track
                .applyConstraints({
                    ...constraints,
                    echoCancellation: echoCancellationEnabled,
                })
                .catch(() => console.error('Failed to set echo cancellation'));
        }
    }, [mediaTrack.track, echoCancellationEnabled]);
};
