import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function InfoIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
            <path
                d="M8 13.5C4.96243 13.5 2.5 11.0376 2.5 8C2.5 4.96243 4.96243 2.5 8 2.5C11.0376 2.5 13.5 4.96243 13.5 8C13.5 11.0376 11.0376 13.5 8 13.5Z"
                stroke={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00368 5.99984C8.36798 5.99984 8.66671 5.70697 8.66671 5.33702C8.66671 4.95937 8.36798 4.6665 8.00368 4.6665C7.6321 4.6665 7.33337 4.95937 7.33337 5.33702C7.33337 5.70697 7.6321 5.99984 8.00368 5.99984Z"
                fill={color}
            />
            <path
                d="M8 7.3335V10.6668"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    fill: none;
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
