// Copyright 2024 Descript, Inc
import classnames from 'classnames';
import * as React from 'react';

type Props = React.HTMLAttributes<HTMLDivElement>;

export function StackItem({ children, className, ...props }: React.PropsWithChildren<Props>) {
    return (
        <div className={classnames('stackItem', className)} {...props}>
            {children}
        </div>
    );
}

StackItem.displayName = 'StackItem';
