import * as React from 'react';

import { useTheme } from '../../contexts/Theme';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function CameraIcon({
    fill = 'var(--nav-icon-fill)',
    stroke = 'var(--nav-icon-stroke)',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    const { colors } = useTheme();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
            <path d="M3 3.5C1.61929 3.5 0.5 4.61929 0.5 6V10C0.5 11.3807 1.61929 12.5 3 12.5H8C9.38071 12.5 10.5 11.3807 10.5 10V6C10.5 4.61929 9.38071 3.5 8 3.5H3Z" />
            <path d="M13.7929 4.5L11.3536 6.93934C10.7678 7.52513 10.7678 8.47487 11.3536 9.06066L13.7929 11.5C14.4229 12.13 15.5 11.6838 15.5 10.7929V5.20711C15.5 4.3162 14.4229 3.87003 13.7929 4.5Z" />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    fill: ${fill ?? colors.system.grey};
                    height: ${size}px;
                    stroke: ${stroke ?? colors.system.greyDark};
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
