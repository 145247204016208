// Copyright 2022 Descript, Inc

import {
    Context,
    Link,
    Sampler,
    SamplingDecision,
    SamplingResult,
    SpanAttributes,
    SpanKind,
} from '@opentelemetry/api';
import { TraceIdRatioBasedSampler } from '@opentelemetry/core';
import { SpanTag } from '@descript/analytics';
import { DescriptError, ErrorCategory } from '@descript/errors';

interface ISpanNameSamplerOptions {
    nameSamplers: Readonly<Record<string, Sampler>>;
    defaultSampler: Sampler;
}

export class RatioSampler extends TraceIdRatioBasedSampler {
    private samplerate: number;

    /**
     * Samples traces with ratio `1 / denominator`
     * If `denominator = 0`, skips all events
     */
    constructor(denominator: number) {
        if (denominator < 0 || Math.trunc(denominator) !== denominator) {
            throw new DescriptError(
                'denominator must be a non-negative integer',
                ErrorCategory.AppArchitecture,
            );
        }
        super(denominator === 0 ? 0 : 1 / denominator);
        this.samplerate = denominator;
    }

    override shouldSample(context: unknown, traceId: string): SamplingResult {
        let samplingResult = super.shouldSample(context, traceId);
        if (samplingResult.decision === SamplingDecision.RECORD_AND_SAMPLED) {
            samplingResult = {
                ...samplingResult,
                attributes: {
                    ...samplingResult.attributes,
                    [SpanTag.sampleRate]: this.samplerate,
                },
            };
        }
        return samplingResult;
    }
}

/**
 * Composite Sampler that chooses a sampler for a span based on its name
 */
export class SpanNameSampler implements Sampler {
    private readonly nameSamplers: Readonly<Record<string, Sampler>>;
    private readonly defaultSampler: Sampler;

    constructor({ nameSamplers, defaultSampler }: ISpanNameSamplerOptions) {
        this.nameSamplers = nameSamplers;
        this.defaultSampler = defaultSampler;
    }

    shouldSample(
        context: Context,
        traceId: string,
        spanName: string,
        spanKind: SpanKind,
        attributes: SpanAttributes,
        links: Link[],
    ): SamplingResult {
        const sampler = this.nameSamplers[spanName] || this.defaultSampler;
        return sampler.shouldSample(context, traceId, spanName, spanKind, attributes, links);
    }

    toString(): string {
        return 'SpanNameSampler';
    }
}
