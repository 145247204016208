import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function ChatIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11.8889 11.3889H12.096L12.2424 11.5353L14.5 13.7929V2.55556C14.5 2.2756 14.3888 2.00712 14.1908 1.80917C13.9929 1.61121 13.7244 1.5 13.4444 1.5H2.55556C2.27561 1.5 2.00712 1.61121 1.80917 1.80917C1.61121 2.00712 1.5 2.27561 1.5 2.55556V10.3333C1.5 10.6133 1.61121 10.8818 1.80917 11.0797C2.00712 11.2777 2.27561 11.3889 2.55556 11.3889H11.8889Z"
                stroke={color}
            />
        </svg>
    );
}
