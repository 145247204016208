import { useEffect } from 'react';
import { useEventCallback } from './useEventCallback';

/** Run an effect when a component unmounts */
export function useUnmountEffect(effect: () => void) {
    const saveOnUnmount = useEventCallback(effect);

    useEffect(() => {
        return saveOnUnmount;
    }, [saveOnUnmount]);
}
