import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function ClipboardIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
            <path d="M7.5 5.5C7.77614 5.5 8 5.27614 8 5C8 4.72386 7.77614 4.5 7.5 4.5V5.5ZM9 4.5C8.72386 4.5 8.5 4.72386 8.5 5C8.5 5.27614 8.72386 5.5 9 5.5V4.5ZM10.5 11.5H5.5V12.5H10.5V11.5ZM5 11V6H4V11H5ZM5.5 5.5H7.5V4.5H5.5V5.5ZM11 6V11H12V6H11ZM10.5 4.5H9V5.5H10.5V4.5ZM12 6C12 5.17157 11.3284 4.5 10.5 4.5V5.5C10.7761 5.5 11 5.72386 11 6H12ZM5.5 11.5C5.22386 11.5 5 11.2761 5 11H4C4 11.8284 4.67157 12.5 5.5 12.5V11.5ZM10.5 12.5C11.3284 12.5 12 11.8284 12 11H11C11 11.2761 10.7761 11.5 10.5 11.5V12.5ZM5 6C5 5.72386 5.22386 5.5 5.5 5.5V4.5C4.67157 4.5 4 5.17157 4 6H5Z" />
            <path d="M6 4.5V5.5C6 5.77614 6.22386 6 6.5 6H9.5C9.77614 6 10 5.77614 10 5.5V4.5C10 4.22386 9.77614 4 9.5 4C9.22386 4 9 3.77614 9 3.5C9 3.22386 8.77614 3 8.5 3H7.5C7.22386 3 7 3.22386 7 3.5C7 3.77614 6.77614 4 6.5 4C6.22386 4 6 4.22386 6 4.5Z" />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    fill: ${color};
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
