import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function BreakoutIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.5 9.5V6.5H9.5V9.5H4.5ZM3 6C3 5.44772 3.44772 5 4 5H10C10.5523 5 11 5.44772 11 6V10C11 10.5523 10.5523 11 10 11H4C3.44772 11 3 10.5523 3 10V6ZM4 13C3.44772 13 3 13.4477 3 14V18C3 18.5523 3.44772 19 4 19H10C10.5523 19 11 18.5523 11 18V14C11 13.4477 10.5523 13 10 13H4ZM14.5 6.5V9.5H19.5V6.5H14.5ZM14 5C13.4477 5 13 5.44772 13 6V10C13 10.5523 13.4477 11 14 11H20C20.5523 11 21 10.5523 21 10V6C21 5.44772 20.5523 5 20 5H14ZM14.5 17.5V14.5H19.5V17.5H14.5ZM13 14C13 13.4477 13.4477 13 14 13H20C20.5523 13 21 13.4477 21 14V18C21 18.5523 20.5523 19 20 19H14C13.4477 19 13 18.5523 13 18V14Z"
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    fill: ${color};
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
