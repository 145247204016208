// Copyright 2024 Descript, Inc
import { SidebarView } from '@daily-co/daily-js';
import { atom, useRecoilState } from 'recoil';
import { ReactNull } from '@descript/react-utils';

/**
 * General UI state.
 * These are general purpose shared UI states.
 */

/**
 * Mobile state.
 * In case the user browses with a mobile device, this state enforces mobile UI
 * and overall mobile adjustments (like increased font-size and icons).
 */
export const isMobileState = atom<boolean>({
    key: 'is-mobile',
    default: false,
});
/**
 * Returns whether this call is in mobile mode and a setter to change it.
 */
export const useIsMobile = () => useRecoilState(isMobileState);

const sidebarViewState = atom<SidebarView | null>({
    key: 'sidebar-view',
    default: ReactNull,
});
/**
 * Used to control visibility and tabs for Sidebar.
 */
export const useSidebarView = () => useRecoilState(sidebarViewState);

const attendeeInteractedWithUIState = atom<boolean>({
    key: 'attendee-interacted-with-ui',
    default: false,
});
/**
 * Returns a flag indicating whether a broadcast attendee already interacted with the UI.
 */
export const useAttendeeInteractedWithUI = () => useRecoilState(attendeeInteractedWithUIState);
