import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function EmojiReactionsIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M13.9205 3.06089C13.5784 2.72455 13.1722 2.45775 12.7252 2.27572C12.2782 2.09369 11.799 2 11.3151 2C10.8312 2 10.3521 2.09369 9.90504 2.27572C9.45801 2.45775 9.05185 2.72455 8.70976 3.06089L7.99982 3.75857L7.28988 3.06089C6.5989 2.38184 5.66172 2.00035 4.68453 2.00035C3.70733 2.00035 2.77016 2.38184 2.07917 3.06089C1.38819 3.73994 1 4.66092 1 5.62124C1 6.58157 1.38819 7.50255 2.07917 8.1816L2.78911 8.87928L7.99982 14L13.2105 8.87928L13.9205 8.1816C14.2627 7.84543 14.5342 7.44628 14.7194 7.00697C14.9047 6.56765 15 6.09678 15 5.62124C15 5.14571 14.9047 4.67484 14.7194 4.23552C14.5342 3.79621 14.2627 3.39706 13.9205 3.06089V3.06089Z"
                stroke={color}
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
