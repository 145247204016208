// Copyright 2024 Descript, Inc
import { DailyCustomTrayButtons } from '@daily-co/daily-js';
import { atom, useRecoilState } from 'recoil';

/**
 * This file contains UI state that can managed from a call frame instance.
 * I.e. state that can be changed through daily-js frame methods
 */

const customTrayButtonsState = atom<DailyCustomTrayButtons>({
    key: 'custom-tray-buttons',
    default: {},
});
/**
 * Returns Array of custom tray buttons and a setter to change them.
 */
export const useCustomTrayButtons = () => useRecoilState(customTrayButtonsState);

/**
 * Call configuration states.
 */
const maxGridTilesPerPageState = atom<number>({
    key: 'max-grid-tiles-per-page',
    default: 10,
});
/**
 * Returns the maximum amount of tiles per page in grid view and a setter to change it.
 */
export const useMaxGridTilesPerPage = () => useRecoilState(maxGridTilesPerPageState);

const minGridTilesPerPageState = atom<number>({
    key: 'min-grid-tiles-per-page',
    default: 10,
});
/**
 * Returns the minimum amount of tiles per page in grid view and a setter to change it.
 */
export const useMinGridTilesPerPage = () => useRecoilState(minGridTilesPerPageState);

const showLocalVideoState = atom<boolean>({
    key: 'show-local-video',
    default: true,
});
/**
 * Returns a flag indicating whether the call is configured to show the local user's view
 * and a setter to change it.
 */
export const useShowLocalVideo = () => useRecoilState(showLocalVideoState);

const showParticipantsBarState = atom<boolean>({
    key: 'show-participants-bar',
    default: true,
});
/**
 * Returns a flag indicating whether to show the ParticipantBar in SpeakerView.
 */
export const useShowParticipantsBar = () => useRecoilState(showParticipantsBarState);

const showNamesState = atom<boolean>({
    key: 'show-names',
    default: true,
});
/**
 * Returns a flag indicating whether to show names on video tiles.
 */
export const useShowNames = () => useRecoilState(showNamesState);

const enableJoinSoundState = atom<boolean>({
    key: 'enable-join-sound',
    default: true,
});
/**
 * Returns a flag indicating whether to play a join sound.
 */
export const useEnableJoinSound = () => useRecoilState(enableJoinSoundState);

const isFullscreenState = atom<boolean>({
    key: 'is-fullscreen',
    default: false,
});
/**
 * Returns a flag indicating whether the call is in fullscreen mode.
 */
export const useIsFullscreen = () => useRecoilState(isFullscreenState);

const showLeaveButtonState = atom<boolean>({
    key: 'show-leave-button',
    default: false,
});
/**
 * Returns a flag indicating whether a leave button should be displayed.
 */
export const useShowLeaveButton = () => useRecoilState(showLeaveButtonState);

const showFullscreenButtonState = atom<boolean>({
    key: 'show-fullscreen-button',
    default: false,
});
/**
 * Returns a flag indicating whether a fullscreen button should be displayed.
 */
export const useShowFullscreenButton = () => useRecoilState(showFullscreenButtonState);

const showUserNameChangeUIState = atom<boolean>({
    key: 'show-username-change-ui-state',
    default: true,
});
/**
 * Returns a flag indicating whether to show the Username change UI.
 */
export const useShowUserNameChangeUI = () => useRecoilState(showUserNameChangeUIState);
