import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function DownloadIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M4 5L8 9L12 5" stroke={color} />
            <path d="M8 9L8 1" stroke={color} />
            <path
                d="M14.5 10.5L14.5 13.1667C14.5 13.5203 14.3478 13.8594 14.0769 14.1095C13.806 14.3595 13.4386 14.5 13.0556 14.5L2.94444 14.5C2.56135 14.5 2.19395 14.3595 1.92307 14.1095C1.65218 13.8594 1.5 13.5203 1.5 13.1667L1.5 10.5"
                stroke={color}
            />
        </svg>
    );
}
