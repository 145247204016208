import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function LowerHandIcon({ color = 'currentColor', size = 24, ...props }: Props) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12 7.5L12 5.25C12 4.55964 12.5596 4 13.25 4V4C13.9404 4 14.5 4.55913 14.5 5.24949C14.5 6.01296 14.5 6.97264 14.5 7.66667C14.5 8.41052 14.5 9.33333 14.5 9.33333"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.5 7.5V2.75C9.5 2.05964 10.0596 1.5 10.75 1.5V1.5C11.4404 1.5 12 2.05913 12 2.74949C12 3.51296 12 4.47264 12 5.16667C12 5.91052 12 6.83333 12 6.83333"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7 7.5V2.25C7 1.55964 7.55964 1 8.25 1V1C8.94036 1 9.5 1.55913 9.5 2.24949C9.5 3.01296 9.5 3.97264 9.5 4.66667C9.5 5.41052 9.5 6.33333 9.5 6.33333"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.49935 9.5L4.16696 9.87352C4.31408 10.0044 4.52435 10.0368 4.70403 9.95619C4.88371 9.87557 4.99933 9.69699 4.99935 9.50005L4.49935 9.5ZM4.50001 3.25L5.00001 3.25005V3.25H4.50001ZM6.50001 7.33333C6.50001 7.60948 6.72387 7.83333 7.00001 7.83333C7.27615 7.83333 7.50001 7.60948 7.50001 7.33333H6.50001ZM3.11812 8.27084L2.77329 8.63291C2.77737 8.6368 2.78152 8.64061 2.78573 8.64436L3.11812 8.27084ZM1.64364 9.73378L1.23327 10.0194L1.64364 9.73378ZM1.6197 8.33903L1.24341 8.00978H1.24341L1.6197 8.33903ZM5.24936 14.0227L4.90831 14.3884L5.24936 14.0227ZM4.99935 9.50005L5.00001 3.25005L4.00001 3.24995L3.99935 9.49995L4.99935 9.50005ZM5.75001 2.5C6.16449 2.5 6.50001 2.83554 6.50001 3.24949H7.50001C7.50001 2.28272 6.71624 1.5 5.75001 1.5V2.5ZM5.00001 3.25C5.00001 2.83579 5.3358 2.5 5.75001 2.5V1.5C4.78351 1.5 4.00001 2.2835 4.00001 3.25H5.00001ZM1.99599 8.66829C2.19787 8.43756 2.55129 8.42148 2.77329 8.63291L3.46295 7.90877C2.82903 7.30504 1.81987 7.35097 1.24341 8.00978L1.99599 8.66829ZM1.24341 8.00978C0.686589 8.64615 0.850342 9.46933 1.23327 10.0194L2.054 9.44813C1.82665 9.12151 1.84938 8.83583 1.99599 8.66829L1.24341 8.00978ZM14.0001 10C14.0001 12.7614 11.7615 15 9.00007 15V16C12.3138 16 15.0001 13.3137 15.0001 10H14.0001ZM15.0001 10V8.5H14.0001V10H15.0001ZM9.00007 15C7.68201 15 6.48402 14.4907 5.59041 13.6571L4.90831 14.3884C5.97972 15.3878 7.41895 16 9.00007 16V15ZM6.50001 3.24949V7.33333H7.50001V3.24949H6.50001ZM2.78573 8.64436L4.16696 9.87352L4.83175 9.12648L3.45051 7.89733L2.78573 8.64436ZM5.59041 13.6571C4.26094 12.417 3.07342 10.9126 2.054 9.44813L1.23327 10.0194C2.27337 11.5136 3.5068 13.0811 4.90831 14.3884L5.59041 13.6571Z"
                fill={color}
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
