// Copyright 2023 Descript, Inc
import { ArtifactStatus, MediaMetadata, TypeChecking } from '@descript/descript-model';
import * as ApiClient from '../Api/ApiClient';
import {
    Artifact,
    Asset,
    CommitUploadResponse,
    CreateArtifactResponse,
    CreateAssetResponse,
    CreatePlaceholderArtifactResponse,
    CreateWriteUrlResponse,
    GetWriteUrlResponse,
    ListArtifactsResponse,
    ListAssetsExpandedResponse,
    ListAssetsResponse,
    RemoteArtifact,
    RemoteArtifactExpanded,
    RemoteAsset,
    RemoteAssetStore,
    RemotePlaceholderArtifact,
    RemoteStoreNewArtifact,
    RemoteStoreNewAsset,
    RemoteStoreNewPlaceholderArtifact,
    Result,
    StartUploadResponse,
    SyncedArtifact,
    WithOptional,
} from './Types';
import { mapRequestError } from './errors';
import { AsyncContext, featureFlagSpanTag, SpanTag, withSpanAsync } from '@descript/analytics';
import { AssetUploadNetworkingFlag } from '../MediaLibrary/gating';
import { Microseconds, addAbortListener } from '@descript/descript-core';
import {
    MtsAudioPlaylist,
    MtsAudioPlaylistRequest,
    MtsImage,
    MtsImageRequest,
    MtsVideoPlaylist,
    MtsVideoPlaylistRequest,
} from '@descript/web-media-engine';
import { DescriptError, ErrorCategory, Errors } from '@descript/errors';

/**
 * To see more about these fields, see the comments for `Asset` and `Artifact`
 */
export interface ApiAsset {
    id: string; // Corresponds to `Asset.guid`
    lookup_key: string;
    created_at: string; // ISO Date
    created_by: string;
    metadata: Record<string, unknown>;
}

export type ApiArtifact = {
    asset_id: string; // Corresponds to `Artifact.assetGuid`
    id: string; // Corresponds to `Artifact.guid`
    lookup_key: string;
    status: ArtifactStatus;
    created_at: string; // ISO Date
    uploaded_by: string;
    file_extension?: string;
    md5?: string;
    size: number;
    metadata: Record<string, unknown>;
    is_segmented: boolean;
    transformation?: string;
    content_type?: string;
    quality?: string;
    failure_reason?: string;
};

export type ApiSegments = {
    artifact_id: string;
    sequence: number;
    md5: string;
    size: number;
    file_extension: string;
    start_time: Microseconds;
    is_init: boolean;
};

export type SignedApiSegments = {
    segments: ApiSegments[];
    base_url: string;
    signed_url_suffix: string;
};

export type ApiPlaceholderArtifact = Omit<ApiArtifact, 'md5' | 'size'>;

export type ApiAssetExpanded = ApiAsset & { artifacts: ApiArtifactExpanded[] };

/**
 * An ApiArtifact with additional optional fields
 */
export type ApiArtifactExpanded = ApiArtifact & { read_url?: string };

export type CreateAssetApiPayload = WithOptional<
    Omit<ApiAsset, 'id' | 'created_at' | 'created_by'>,
    'metadata'
> & { id_hint?: string };

// TODO:
// - Handle permissions errors
// - Handle conflict errors for artifact upload
// - Handle network errors

export type AssetApiResponse = {
    asset: ApiAsset;
};
type CreateAssetApiResponse = AssetApiResponse;

type ListAssetsApiParams = {
    cursor?: string;
    include_placeholder?: boolean;
    include_artifacts?: boolean;
};

type ListAssetsApiResponse = {
    data: ApiAsset[];
    cursor: string;
};

type ListAssetsExpandedApiResponse = {
    data: Array<ApiAsset & { artifacts: ApiArtifactExpanded[] }>;
    cursor: string;
};

type CreateArtifactApiRequestPayload = WithOptional<
    Omit<ApiArtifact, 'asset_id' | 'id' | 'status' | 'created_at' | 'uploaded_by'>,
    'metadata'
> & { id_hint?: string };

export type CreatePlaceholderArtifactApiRequestPayload = Omit<
    CreateArtifactApiRequestPayload,
    'md5' | 'size'
>;

type ArtifactApiResponse = {
    artifact: ApiArtifact;
};
type CreateArtifactApiResponse = ArtifactApiResponse;

type PlaceholderArtifactApiResponse = {
    placeholderArtifact: ApiPlaceholderArtifact;
};
export type CreatePlaceholderArtifactApiResponse = PlaceholderArtifactApiResponse;

export type CommitUploadApiSuccessResponse = ArtifactApiResponse;
export type CommitUploadApiConflictResponse = {
    artifact: ApiArtifact;
    conflict:
        | {
              type: 'md5';
              uploaded_md5: string;
          }
        | {
              type: 'size';
              uploaded_size: number;
          }
        | {
              type: 'status';
          }
        | {
              type: 'expired';
          }
        | {
              type: 'no_file';
          };
};

type GetSegmentsListSuccessResponse = {
    segments: ApiSegments[];
    base_url: string;
    signed_url_suffix: string;
};

type GetWriteUrlApiSuccessResponse = {
    artifact: ApiArtifact;
    write_url: string;
};

type GetWriteUrlApiConflictResponse = {
    artifact: ApiArtifact;
    conflict: { type: 'status' };
};

type ListArtifactsApiResponse = {
    data: ApiArtifact[];
};

type StartUploadApiSuccessResponse = {
    artifact: ApiArtifact;
    upload_token: string;
    uploaded_parts: number[];
};
type StartUploadApiConflictResponse = {
    artifact: ApiArtifact;
    conflict: { type: 'status' };
};

type CreateWriteUrlApiSuccessResponse = {
    artifact: ApiArtifact;
    part_number: number;
    write_url: string;
};

type CreateWriteUrlApiConflictResponse = {
    artifact: ApiArtifact;
    part_number: number;
    conflict: { type: 'status' } | { type: 'expired' };
};

export type BasicResponseStatus = {
    statusCode: number;
    statusMessage: string;
};

export type UploadAndGetBasicResponseStatus = (params: {
    writeUrl: string;
    data: Uint8Array;
    headers: Record<string, string>;
    onPartProgress?: (partProgress: number) => void;
    signal?: AbortSignal;
}) => Promise<BasicResponseStatus>;

export function mapApiAssetToRemoteAsset(asset: ApiAsset): RemoteAsset {
    return {
        guid: asset.id,
        lookupKey: asset.lookup_key,
        createdAt: Date.parse(asset.created_at),
        createdBy: asset.created_by,
        metadata: asset.metadata,
    };
}

export function mapApiArtifactToRemoteArtifact(
    artifact: ApiArtifactExpanded,
): RemoteArtifactExpanded;
export function mapApiArtifactToRemoteArtifact(artifact: ApiArtifact): RemoteArtifact;
export function mapApiArtifactToRemoteArtifact(
    artifact: ApiArtifact | ApiArtifactExpanded,
): RemoteArtifact | RemoteArtifactExpanded {
    const remoteArtifact = {
        assetGuid: artifact.asset_id,
        guid: artifact.id,
        lookupKey: artifact.lookup_key,
        createdAt: Date.parse(artifact.created_at),
        uploadedBy: artifact.uploaded_by,
        metadata: artifact.metadata,
        status: artifact.status,
        // TODO: Change this to undefined instead of empty string
        // https://linear.app/descript/issue/MEDIA-966/change-artifacts-fileextension-type-to-be-optional
        fileExtension: artifact.file_extension || '',
        md5: artifact.md5,
        size: artifact.size,
        isSegmented: artifact.is_segmented,
        transformation: artifact.transformation,
        contentType: artifact.content_type,
        quality: artifact.quality,
        failureReason: artifact.failure_reason,
    };
    const read_url = TypeChecking.getMaybeProperty(artifact, 'read_url');
    if (read_url) {
        (remoteArtifact as RemoteArtifactExpanded).readUrl = read_url;
    }
    return remoteArtifact;
}

export function mapApiPlaceholderArtifactToRemotePlaceholderArtifact(
    placeholderArtifact: ApiPlaceholderArtifact,
): RemotePlaceholderArtifact {
    return {
        assetGuid: placeholderArtifact.asset_id,
        guid: placeholderArtifact.id,
        lookupKey: placeholderArtifact.lookup_key,
        createdAt: Date.parse(placeholderArtifact.created_at),
        uploadedBy: placeholderArtifact.uploaded_by,
        metadata: placeholderArtifact.metadata,
        status: placeholderArtifact.status,
        // TODO: Change this to undefined instead of empty string
        // https://linear.app/descript/issue/MEDIA-966/change-artifacts-fileextension-type-to-be-optional
        fileExtension: placeholderArtifact.file_extension || '',
        isSegmented: placeholderArtifact.is_segmented,
        transformation: placeholderArtifact.transformation,
        contentType: placeholderArtifact.content_type,
        quality: placeholderArtifact.quality,
    };
}

function isOkStatusCode(statusCode: number) {
    // Based on https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
    return statusCode >= 200 && statusCode < 300;
}

export function handleApiError(error: Error): never {
    if (Errors.isRequestError(error)) {
        throw mapRequestError(error);
    } else {
        throw error;
    }
}

export const putFetch: UploadAndGetBasicResponseStatus = async function putFetch({
    writeUrl,
    headers,
    data,
    signal,
}) {
    const res = await fetch(writeUrl, {
        method: 'PUT',
        headers: new Headers(headers),
        body: data,
        signal,
    });
    return {
        statusCode: res.status,
        statusMessage: res.statusText,
    };
};

function handleFinishedXhr(
    xhr: XMLHttpRequest,
    resolve: (res: BasicResponseStatus) => void,
    reject: (e: unknown) => void,
): void {
    if (xhr.status === 0) {
        reject(
            Errors.networkError(
                new DescriptError(
                    `Request error: ${xhr.responseText}`,
                    ErrorCategory.GlobalAssetSync,
                ),
            ),
        );
    } else if (xhr.status < 400) {
        resolve({ statusCode: xhr.status, statusMessage: xhr.statusText });
    } else {
        const error = new DescriptError(
            `RequestError ${xhr.status}: ${xhr.responseText}`,
            ErrorCategory.GlobalAssetSync,
        );
        if (xhr.status === 502 || xhr.status === 503) {
            reject(Errors.networkError(error));
        } else {
            reject(error);
        }
    }
}

export const putXhr: UploadAndGetBasicResponseStatus = function putXhr({
    writeUrl,
    headers,
    data,
    onPartProgress,
    signal,
}): Promise<BasicResponseStatus> {
    return new Promise((resolve, reject) => {
        let lastProgress = 0;
        // We use XMLHttpRequest for progress callbacks
        const xhr = new XMLHttpRequest();
        const unsubscribeAbort = addAbortListener(signal, () => xhr.abort());
        xhr.upload.addEventListener('loadstart', (_evt) => {
            onPartProgress?.(lastProgress);
        });
        xhr.upload.addEventListener('progress', (evt) => {
            if (evt.lengthComputable) {
                lastProgress = evt.loaded / evt.total;
            } else if (data.byteLength && evt.loaded) {
                lastProgress = evt.loaded / data.byteLength;
            } else {
                // Unable to compute progress information since the total size is unknown,
                // so make up something
                lastProgress = 1 - (1 - lastProgress) * 0.8;
            }
            onPartProgress?.(lastProgress);
        });
        xhr.upload.addEventListener('load', () => {
            onPartProgress?.(1);
        });
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                unsubscribeAbort?.();
                handleFinishedXhr(xhr, resolve, reject);
            }
        };
        xhr.open('PUT', writeUrl, true);
        for (const [k, v] of Object.entries(headers)) {
            // Content-Length is set automatically by the browser
            if (k === 'Content-Length') {
                continue;
            }
            xhr.setRequestHeader(k, v);
        }
        xhr.send(data);
    });
};

export function formatUploadFileHttpHeaders(md5base64: string): Record<string, string> {
    return {
        'Content-Type': 'application/octet-stream',
        // AWS requires a base64 encoded md5 string
        'Content-MD5': md5base64,
    };
}

export async function createAssetRequest(
    ctx: AsyncContext,
    projectId: string,
    requestPayload: CreateAssetApiPayload,
): Promise<{ result: Result; asset: RemoteAsset }> {
    try {
        const response = await ApiClient.request<CreateAssetApiResponse>(
            ctx,
            ApiClient.RequestType.POST,
            `/projects/${projectId}/media_assets`,
            undefined,
            requestPayload,
        );
        return {
            result: 'ok',
            asset: mapApiAssetToRemoteAsset(response.asset),
        };
    } catch (e) {
        const error = e as Error;
        if (
            Errors.isRequestError(error) &&
            error.statusCode === 409 &&
            error.json.data &&
            error.json.data.conflict
        ) {
            const conflictResponse = error.json.data as CreateAssetApiResponse;
            return {
                result: 'ok',
                asset: mapApiAssetToRemoteAsset(conflictResponse.asset),
            };
        } else {
            handleApiError(error);
        }
    }
}

// This is an abstract class so that onlineInvariant can implemented differently
// for web and electron
export abstract class AssetSyncApi implements RemoteAssetStore {
    abstract isAvailable(): boolean;
    abstract onlineInvariant(): void;

    protected delegateAuth: string | undefined;

    async createAsset(
        ctx: AsyncContext,
        projectId: string,
        assetProps: RemoteStoreNewAsset,
    ): Promise<CreateAssetResponse> {
        this.onlineInvariant();
        const requestPayload: CreateAssetApiPayload = {
            id_hint: assetProps.idHint,
            lookup_key: assetProps.lookupKey,
            metadata: assetProps.metadata ?? {},
        };

        try {
            return await this.createAssetRequest(ctx, projectId, requestPayload);
        } catch (e) {
            const error = e as Error;
            if (
                Errors.isRequestError(error) &&
                error.statusCode === 409 &&
                error.json.data &&
                error.json.data.conflict
            ) {
                const conflictResponse = error.json.data as CreateAssetApiResponse;
                return {
                    result: 'ok',
                    asset: mapApiAssetToRemoteAsset(conflictResponse.asset),
                };
            } else {
                handleApiError(error);
            }
        }
    }

    protected async createAssetRequest(
        ctx: AsyncContext,
        projectId: string,
        requestPayload: CreateAssetApiPayload,
    ): Promise<{ result: Result; asset: RemoteAsset }> {
        const response = await ApiClient.request<CreateAssetApiResponse>(
            ctx,
            ApiClient.RequestType.POST,
            `/projects/${projectId}/media_assets`,
            undefined,
            requestPayload,
            undefined,
            ApiClient.JSON_TYPE_HEADERS,
            undefined,
            false,
            this.delegateAuth,
        );
        return {
            result: 'ok',
            asset: mapApiAssetToRemoteAsset(response.asset),
        };
    }

    async updateAsset(
        ctx: AsyncContext,
        {
            projectId,
            assetGuid,
            changes,
        }: {
            projectId: string;
            assetGuid: string;
            artifactGuid: string;
            changes: Pick<Asset, 'metadata'>;
        },
    ): Promise<RemoteAsset> {
        this.onlineInvariant();
        try {
            const response = await ApiClient.request<CreateAssetApiResponse>(
                ctx,
                ApiClient.RequestType.PATCH,
                `/projects/${projectId}/media_assets/${assetGuid}`,
                undefined,
                changes,
                undefined,
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
            return mapApiAssetToRemoteAsset(response.asset);
        } catch (e) {
            const error = e as Error;
            throw handleApiError(error as Error);
        }
    }

    async getAsset(
        ctx: AsyncContext,
        {
            projectId,
            assetGuid,
        }: {
            projectId: string;
            assetGuid: string;
        },
    ): Promise<RemoteAsset> {
        this.onlineInvariant();
        try {
            const response = await ApiClient.request<AssetApiResponse>(
                ctx,
                ApiClient.RequestType.GET,
                `/projects/${projectId}/media_assets/${assetGuid}`,
                undefined,
                undefined,
                undefined,
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
            return mapApiAssetToRemoteAsset(response.asset);
        } catch (e) {
            const error = e as Error;
            handleApiError(error);
        }
    }

    async copyAssetFromProject(
        ctx: AsyncContext,
        params: {
            projectId: string;
            assetGuid: string;
            sourceProjectId: string;
        },
    ): Promise<void> {
        this.onlineInvariant();
        const { projectId, assetGuid, sourceProjectId } = params;
        try {
            await ApiClient.request(
                ctx,
                ApiClient.RequestType.POST,
                `/projects/${projectId}/media_assets/${assetGuid}`,
                undefined,
                {
                    source_project_id: sourceProjectId,
                },
                undefined,
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
        } catch (e) {
            const error = e as Error;
            handleApiError(error);
        }
    }

    async copyAssetFromDrive(
        ctx: AsyncContext,
        params: {
            projectId: string;
            assetGuid: string;
            sourceDriveId: string;
        },
    ): Promise<void> {
        this.onlineInvariant();
        const { projectId, assetGuid, sourceDriveId } = params;
        try {
            await ApiClient.request(
                ctx,
                ApiClient.RequestType.POST,
                `/projects/${projectId}/media_assets/${assetGuid}`,
                undefined,
                {
                    source_drive_id: sourceDriveId,
                },
                undefined,
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
        } catch (e) {
            const error = e as Error;
            handleApiError(error);
        }
    }

    async removeAssetFromProject(
        ctx: AsyncContext,
        params: {
            projectId: string;
            assetGuid: string;
        },
    ): Promise<void> {
        this.onlineInvariant();
        const { projectId, assetGuid } = params;
        try {
            await ApiClient.request(
                ctx,
                ApiClient.RequestType.DELETE,
                `/projects/${projectId}/media_assets/${assetGuid}`,
                undefined,
                undefined,
                undefined,
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
        } catch (e) {
            const error = e as Error;
            handleApiError(error);
        }
    }

    async listAssets(
        ctx: AsyncContext,
        projectId: string,
        cursor?: string,
    ): Promise<ListAssetsResponse> {
        this.onlineInvariant();
        try {
            const response = await ApiClient.request<ListAssetsApiResponse>(
                ctx,
                ApiClient.RequestType.GET,
                `/projects/${projectId}/media_assets`,
                cursor ? ({ cursor } as ListAssetsApiParams) : undefined,
                undefined,
                undefined,
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
            return {
                result: 'ok',
                assets: response.data.map(mapApiAssetToRemoteAsset),
                cursor: response.cursor,
            };
        } catch (e) {
            const error = e as Error;
            handleApiError(error);
        }
    }

    async listAssetsExpanded(
        ctx: AsyncContext,
        projectId: string,
        cursor?: string,
        includePlaceholder?: boolean,
    ): Promise<ListAssetsExpandedResponse> {
        this.onlineInvariant();
        const queryParams: ListAssetsApiParams = {
            include_artifacts: true,
        };
        if (cursor) {
            queryParams.cursor = cursor;
        }
        if (includePlaceholder) {
            queryParams.include_placeholder = includePlaceholder;
        }
        try {
            const response = await ApiClient.request<ListAssetsExpandedApiResponse>(
                ctx,
                ApiClient.RequestType.GET,
                `/projects/${projectId}/media_assets`,
                queryParams,
                undefined,
                undefined,
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
            return {
                result: 'ok',
                assetsExpanded: response.data.map(({ artifacts, ...asset }) => ({
                    asset: mapApiAssetToRemoteAsset(asset),
                    artifacts: artifacts.map((a) => mapApiArtifactToRemoteArtifact(a)),
                })),
                cursor: response.cursor,
            };
        } catch (e) {
            const error = e as Error;
            handleApiError(error);
        }
    }

    async listArtifacts(
        ctx: AsyncContext,
        projectId: string,
        assetGuid: string,
        includeArtifactReadUrls?: boolean,
    ): Promise<ListArtifactsResponse> {
        this.onlineInvariant();
        try {
            const response = await ApiClient.request<ListArtifactsApiResponse>(
                ctx,
                ApiClient.RequestType.GET,
                `/projects/${projectId}/media_assets/${assetGuid}/artifacts`,
                {
                    include_artifact_read_urls: true,
                },
                undefined,
                undefined,
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
            return {
                result: 'ok',
                artifacts: response.data.map(mapApiArtifactToRemoteArtifact),
            };
        } catch (e) {
            const error = e as Error;
            handleApiError(error);
        }
    }

    async createArtifact(
        ctx: AsyncContext,
        projectId: string,
        assetGuid: string,
        newArtifact: RemoteStoreNewArtifact,
    ): Promise<CreateArtifactResponse> {
        this.onlineInvariant();
        const requestPayload: CreateArtifactApiRequestPayload = {
            id_hint: newArtifact.idHint,
            lookup_key: newArtifact.lookupKey,
            file_extension: newArtifact.fileExtension,
            md5: newArtifact.md5,
            metadata: newArtifact.metadata ?? {},
            is_segmented: false,
            size: newArtifact.size,
        };
        try {
            const response = await ApiClient.request<CreateArtifactApiResponse>(
                ctx,
                ApiClient.RequestType.POST,
                `/projects/${projectId}/media_assets/${assetGuid}/artifacts`,
                undefined,
                requestPayload,
                undefined,
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
            return {
                result: 'ok',
                artifact: mapApiArtifactToRemoteArtifact(response.artifact),
            };
        } catch (e) {
            const error = e as Error;
            // This means that the data was inconsistent with the already extant data
            if (Errors.isRequestError(error) && error.statusCode === 409 && error.json) {
                const conflictResponse = error.json.data as CreateArtifactApiResponse;
                return {
                    result: 'conflict',
                    artifact: mapApiArtifactToRemoteArtifact(conflictResponse.artifact),
                };
            } else {
                throw handleApiError(error);
            }
        }
    }

    async updateArtifact(
        ctx: AsyncContext,
        {
            projectId,
            assetGuid,
            artifactGuid,
            changes,
        }: {
            projectId: string;
            assetGuid: string;
            artifactGuid: string;
            changes: Pick<Artifact, 'metadata'>;
        },
    ): Promise<RemoteArtifact> {
        this.onlineInvariant();
        try {
            const response = await ApiClient.request<CreateArtifactApiResponse>(
                ctx,
                ApiClient.RequestType.PATCH,
                `/projects/${projectId}/media_assets/${assetGuid}/artifacts/${artifactGuid}`,
                undefined,
                changes,
                undefined,
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
            return mapApiArtifactToRemoteArtifact(response.artifact);
        } catch (e) {
            const error = e as Error;
            throw handleApiError(error as Error);
        }
    }

    async updateArtifactProgress(
        ctx: AsyncContext,
        {
            projectId,
            assetGuid,
            artifactGuid,
            progress,
        }: {
            projectId: string;
            assetGuid: string;
            artifactGuid: string;
            progress: number;
        },
    ): Promise<void> {
        this.onlineInvariant();
        try {
            await ApiClient.request(
                ctx,
                ApiClient.RequestType.POST,
                `/projects/${projectId}/media_assets/${assetGuid}/artifacts/${artifactGuid}/progress`,
                undefined,
                {
                    progress,
                },
                undefined,
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
        } catch (e) {
            const error = e as Error;
            throw handleApiError(error as Error);
        }
    }

    async getArtifact(
        ctx: AsyncContext,
        {
            projectId,
            assetGuid,
            artifactGuid,
        }: {
            projectId: string;
            assetGuid: string;
            artifactGuid: string;
        },
    ): Promise<RemoteArtifact> {
        this.onlineInvariant();
        try {
            const response = await ApiClient.request<ArtifactApiResponse>(
                ctx,
                ApiClient.RequestType.GET,
                `/projects/${projectId}/media_assets/${assetGuid}/artifacts/${artifactGuid}`,
                undefined,
                undefined,
                undefined,
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
            return mapApiArtifactToRemoteArtifact(response.artifact);
        } catch (e) {
            const error = e as Error;
            throw handleApiError(error as Error);
        }
    }

    async getReadUrl(
        ctx: AsyncContext,
        {
            projectId,
            assetGuid,
            artifactGuid,
        }: {
            projectId: string;
            assetGuid: string;
            artifactGuid: string;
        },
    ): Promise<string> {
        this.onlineInvariant();
        try {
            const urlResponse = await ApiClient.request<{ url: string }>(
                ctx,
                ApiClient.RequestType.GET,
                `/projects/${projectId}/media_assets/${assetGuid}/artifacts/${artifactGuid}/file`,
                undefined,
                undefined,
                undefined,
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
            return urlResponse.url;
        } catch (e) {
            const error = e as Error;
            throw handleApiError(error as Error);
        }
    }

    async getSegments(
        ctx: AsyncContext,
        projectId: string,
        assetGuid: string,
        artifactGuid: string,
    ): Promise<SignedApiSegments> {
        this.onlineInvariant();
        try {
            const response = await ApiClient.request<GetSegmentsListSuccessResponse>(
                ctx,
                ApiClient.RequestType.GET,
                `/projects/${projectId}/media_assets/${assetGuid}/artifacts/${artifactGuid}/segments`,
                undefined,
                undefined,
                undefined,
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
            return {
                segments: response.segments,
                base_url: response.base_url,
                signed_url_suffix: response.signed_url_suffix,
            };
        } catch (e) {
            const error = e as Error;
            throw handleApiError(error as Error);
        }
    }

    async getMtsVideoPlaylist(
        ctx: AsyncContext,
        { projectId, assetId, ...rest }: MtsVideoPlaylistRequest,
    ): Promise<MtsVideoPlaylist> {
        this.onlineInvariant();
        try {
            return await ApiClient.request<MtsVideoPlaylist>(
                ctx,
                ApiClient.RequestType.POST,
                `/mts/${projectId}/asset/${assetId}/video_playlist`,
                undefined,
                rest,
                undefined,
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
        } catch (e) {
            const error = e as Error;
            throw handleApiError(error as Error);
        }
    }

    async getMtsAudioPlaylist(
        ctx: AsyncContext,
        { projectId, assetId, ...rest }: MtsAudioPlaylistRequest,
    ): Promise<MtsAudioPlaylist> {
        this.onlineInvariant();
        try {
            return await ApiClient.request<MtsAudioPlaylist>(
                ctx,
                ApiClient.RequestType.POST,
                `/mts/${projectId}/asset/${assetId}/audio_playlist`,
                undefined,
                rest,
                undefined,
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
        } catch (e) {
            const error = e as Error;
            throw handleApiError(error as Error);
        }
    }

    async getMtsImage(
        ctx: AsyncContext,
        { projectId, assetId, ...rest }: MtsImageRequest,
    ): Promise<MtsImage> {
        this.onlineInvariant();
        try {
            return await ApiClient.request<MtsImage>(
                ctx,
                ApiClient.RequestType.POST,
                `/mts/${projectId}/asset/${assetId}/image`,
                undefined,
                rest,
                undefined,
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
        } catch (e) {
            const error = e as Error;
            throw handleApiError(error as Error);
        }
    }
    async getWriteUrl(
        ctx: AsyncContext,
        projectId: string,
        assetGuid: string,
        artifact: SyncedArtifact,
    ): Promise<GetWriteUrlResponse> {
        this.onlineInvariant();
        try {
            const response = await ApiClient.request<GetWriteUrlApiSuccessResponse>(
                ctx,
                ApiClient.RequestType.GET,
                `/projects/${projectId}/media_assets/${assetGuid}/artifacts/${artifact.guid}/write_url`,
                undefined,
                undefined,
                undefined,
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
            return {
                result: 'ok',
                artifact: mapApiArtifactToRemoteArtifact(response.artifact),
                writeUrl: response.write_url,
            };
        } catch (e) {
            const error = e as Error;
            if (
                Errors.isRequestError(error) &&
                error.statusCode === 409 &&
                error.json.data?.conflict?.type === 'status'
            ) {
                const conflictResponse = error.json.data as GetWriteUrlApiConflictResponse;
                return {
                    result: 'conflict',
                    artifact: mapApiArtifactToRemoteArtifact(conflictResponse.artifact),
                    conflict: { type: 'status' },
                };
            } else {
                throw handleApiError(error);
            }
        }
    }

    /**
     *
     * @param uploadToken Used for multi-part uploads in NodeRemoteAssetStore
     */
    async commitUpload(
        ctx: AsyncContext,
        {
            projectId,
            assetGuid,
            artifactGuid,
            uploadToken,
        }: {
            projectId: string;
            assetGuid: string;
            artifactGuid: string;
            uploadToken?: string;
        },
    ): Promise<CommitUploadResponse> {
        this.onlineInvariant();

        try {
            const payload = uploadToken ? { upload_token: uploadToken } : undefined;
            return await this.commitUploadRequest(
                ctx,
                projectId,
                assetGuid,
                artifactGuid,
                payload,
            );
        } catch (e) {
            const error = e as Error;
            if (
                Errors.isRequestError(error) &&
                error.statusCode === 409 &&
                error.json.data &&
                error.json.data.conflict
            ) {
                const conflictResponse = error.json.data as CommitUploadApiConflictResponse;
                let conflictData: Extract<
                    CommitUploadResponse,
                    { result: 'conflict' }
                >['conflict'];
                switch (conflictResponse.conflict.type) {
                    case 'md5':
                        conflictData = {
                            type: 'md5',
                            uploadedMd5: conflictResponse.conflict.uploaded_md5,
                        };
                        break;
                    case 'size':
                        conflictData = {
                            type: 'size',
                            uploadedSize: conflictResponse.conflict.uploaded_size,
                        };
                        break;
                    case 'status':
                        conflictData = {
                            type: 'status',
                        };
                        break;
                    case 'expired':
                        conflictData = {
                            type: 'expired',
                        };
                        break;
                    case 'no_file':
                        conflictData = {
                            type: 'no_file',
                        };
                        break;
                    default:
                        TypeChecking.staticAssertNever(conflictResponse.conflict);
                }
                return {
                    result: 'conflict',
                    artifact: mapApiArtifactToRemoteArtifact(conflictResponse.artifact),
                    conflict: conflictData,
                };
            } else {
                handleApiError(error);
            }
        }
    }

    protected async commitUploadRequest(
        ctx: AsyncContext,
        projectId: string,
        assetGuid: string,
        artifactGuid: string,
        payload: { upload_token: string } | undefined,
    ): Promise<CommitUploadResponse> {
        const response = await ApiClient.request<CommitUploadApiSuccessResponse>(
            ctx,
            ApiClient.RequestType.POST,
            `/projects/${projectId}/media_assets/${assetGuid}/artifacts/${artifactGuid}/commit`,
            undefined,
            payload,
            undefined,
            ApiClient.JSON_TYPE_HEADERS,
            undefined,
            false,
            this.delegateAuth,
        );
        return {
            result: 'ok',
            artifact: mapApiArtifactToRemoteArtifact(response.artifact),
        };
    }

    async failUpload(
        ctx: AsyncContext,
        assetGuid: string,
        artifact: SyncedArtifact,
        reason: string | undefined,
    ): Promise<void> {
        this.onlineInvariant();
        try {
            await ApiClient.request(
                ctx,
                ApiClient.RequestType.POST,
                `/media_assets/${assetGuid}/artifacts/${artifact.guid}/fail`,
                undefined,
                reason ? { reason } : undefined,
                {
                    // Since we call this on page unload, we want keepalive
                    // https://stackoverflow.com/questions/63157089/sending-post-request-with-fetch-after-closing-the-browser-with-beforeunload
                    // https://developer.mozilla.org/en-US/docs/Web/API/fetch#keepalive
                    keepalive: true,
                },
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
        } catch (e) {
            const error = e as Error;
            // Don't do any special error/conflict handling, as we can just fail if need be
            handleApiError(error);
        }
    }

    async createPlaceholderArtifact(
        ctx: AsyncContext,
        projectId: string,
        assetGuid: string,
        newPlaceholderArtifact: RemoteStoreNewPlaceholderArtifact,
    ): Promise<CreatePlaceholderArtifactResponse> {
        this.onlineInvariant();
        const requestPayload: CreatePlaceholderArtifactApiRequestPayload = {
            id_hint: newPlaceholderArtifact.idHint,
            lookup_key: newPlaceholderArtifact.lookupKey,
            metadata: newPlaceholderArtifact.metadata ?? {},
            is_segmented: newPlaceholderArtifact.isSegmented,
        };
        // Only set fileExtension if the artifact is not segmented
        if (!newPlaceholderArtifact.isSegmented) {
            requestPayload.file_extension = newPlaceholderArtifact.fileExtension;
        }
        try {
            return await this.createPlaceholderRequest(
                ctx,
                projectId,
                assetGuid,
                requestPayload,
            );
        } catch (e) {
            const error = e as Error;
            throw handleApiError(error as Error);
        }
    }

    protected async createPlaceholderRequest(
        ctx: AsyncContext,
        projectId: string,
        assetGuid: string,
        requestPayload: CreatePlaceholderArtifactApiRequestPayload,
    ): Promise<CreatePlaceholderArtifactResponse> {
        const response = await ApiClient.request<CreatePlaceholderArtifactApiResponse>(
            ctx,
            ApiClient.RequestType.POST,
            `/projects/${projectId}/media_assets/${assetGuid}/placeholder_artifacts`,
            undefined,
            requestPayload,
            undefined,
            ApiClient.JSON_TYPE_HEADERS,
            undefined,
            false,
            this.delegateAuth,
        );
        return {
            result: 'ok',
            placeholderArtifact: mapApiPlaceholderArtifactToRemotePlaceholderArtifact(
                response.placeholderArtifact,
            ),
        };
    }

    async startUpload(
        ctx: AsyncContext,
        {
            projectId,
            assetGuid,
            artifactGuid,
        }: {
            projectId: string;
            assetGuid: string;
            artifactGuid: string;
            partSize: number;
        },
    ): Promise<StartUploadResponse> {
        this.onlineInvariant();
        try {
            const response = await ApiClient.request<StartUploadApiSuccessResponse>(
                ctx,
                ApiClient.RequestType.POST,
                `/projects/${projectId}/media_assets/${assetGuid}/artifacts/${artifactGuid}/upload`,
                undefined,
                undefined,
                undefined,
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
            return {
                result: 'ok',
                artifact: mapApiArtifactToRemoteArtifact(response.artifact),
                uploadToken: response.upload_token,
                uploadedParts: response.uploaded_parts,
            };
        } catch (e) {
            const error = e as Error;
            // This means that the data was inconsistent with the already extant data
            if (Errors.isRequestError(error) && error.statusCode === 409 && error.json) {
                const conflictResponse = error.json.data as StartUploadApiConflictResponse;
                return {
                    result: 'conflict',
                    artifact: mapApiArtifactToRemoteArtifact(conflictResponse.artifact),
                    conflict: { type: conflictResponse.conflict.type },
                };
            } else {
                throw handleApiError(error);
            }
        }
    }

    async createWriteUrl(
        ctx: AsyncContext,
        {
            projectId,
            assetGuid,
            artifactGuid,
            partNumber,
            partMd5,
            uploadToken,
        }: {
            projectId: string;
            assetGuid: string;
            artifactGuid: string;
            partNumber: number;
            partMd5: string;
            uploadToken: string;
        },
    ): Promise<CreateWriteUrlResponse> {
        this.onlineInvariant();
        try {
            const response = await ApiClient.request<CreateWriteUrlApiSuccessResponse>(
                ctx,
                ApiClient.RequestType.POST,
                `/projects/${projectId}/media_assets/${assetGuid}/artifacts/${artifactGuid}/write_url`,
                undefined,
                {
                    part_number: partNumber,
                    part_md5: partMd5,
                    upload_token: uploadToken,
                },
                undefined,
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
            return {
                result: 'ok',
                artifact: mapApiArtifactToRemoteArtifact(response.artifact),
                partNumber,
                writeUrl: response.write_url,
            };
        } catch (e) {
            const error = e as Error;
            // This means that the data was inconsistent with the already extant data
            if (Errors.isRequestError(error) && error.statusCode === 409 && error.json) {
                const conflictResponse = error.json.data as CreateWriteUrlApiConflictResponse;
                return {
                    result: 'conflict',
                    artifact: mapApiArtifactToRemoteArtifact(conflictResponse.artifact),
                    conflict: { type: conflictResponse.conflict.type },
                };
            } else {
                throw handleApiError(error);
            }
        }
    }

    async uploadPart(
        ctx: AsyncContext,
        {
            assetUploadNetworkingFlag,
            data,
            partMd5,
            partSize,
            writeUrl,
            signal,
            putFn = putXhr,
            onPartProgress,
        }: {
            assetUploadNetworkingFlag: AssetUploadNetworkingFlag;
            data: Uint8Array;
            partSize: number;
            partMd5: string;
            writeUrl: string;
            putFn?: UploadAndGetBasicResponseStatus;
            onPartProgress?: (partProgress: number) => void;
            signal?: AbortSignal;
        },
    ): Promise<void> {
        this.onlineInvariant();
        try {
            const url = new URL(writeUrl);
            const response = await withSpanAsync(
                'fetch',
                {
                    ctx,
                    attributes: {
                        [SpanTag.httpMethod]: 'put',
                        [SpanTag.httpPath]: url.pathname,
                        [SpanTag.appAssetUrl]: writeUrl,
                        [SpanTag.httpRequestContentLength]: partSize,
                        [featureFlagSpanTag('asset-upload-networking')]:
                            assetUploadNetworkingFlag,
                    },
                },
                async (newCtx) => {
                    const res = await putFn({
                        writeUrl,
                        headers: {
                            'Content-Length': `${partSize}`,
                            // AWS requires a base64 encoded md5 string
                            'Content-MD5': Buffer.from(partMd5, 'hex').toString('base64'),
                        },
                        data,
                        signal,
                        onPartProgress,
                    });
                    newCtx.span.setAttribute(SpanTag.httpStatusCode, res.statusCode);
                    return res;
                },
            );
            const { statusCode } = response;
            if (!isOkStatusCode(statusCode)) {
                throw new Errors.RequestError('Failed to PUT part', statusCode, {
                    error: response.statusMessage,
                });
            }
        } catch (e) {
            const error = e as Error;
            handleApiError(error);
        }
    }

    async createWebProxy(
        ctx: AsyncContext,
        params: {
            projectId: string;
            assetGuid: string;
            artifactGuid: string;
        },
    ): Promise<void> {
        this.onlineInvariant();
        const { projectId, assetGuid, artifactGuid } = params;
        try {
            await ApiClient.request(
                ctx,
                ApiClient.RequestType.POST,
                `/projects/${projectId}/media_assets/${assetGuid}/artifacts/${artifactGuid}/web_proxy`,
                undefined,
                undefined,
                undefined,
                ApiClient.JSON_TYPE_HEADERS,
                undefined,
                false,
                this.delegateAuth,
            );
        } catch (e) {
            const error = e as Error;
            handleApiError(error);
        }
    }

    async getWriteUrlSegment(
        ctx: AsyncContext,
        params: {
            projectId: string;
            assetGuid: string;
            artifactGuid: string;
            sequence: number;
            fileExtension: string;
            size: number;
            md5: string;
        },
    ): Promise<string> {
        this.onlineInvariant();
        const { projectId, assetGuid, artifactGuid, size, md5, fileExtension } = params;
        try {
            return await this.writeUrlSegment(
                ctx,
                projectId,
                assetGuid,
                artifactGuid,
                params.sequence,
                fileExtension,
                size,
                md5,
            );
        } catch (e) {
            const error = e as Error;
            handleApiError(error);
        }
    }

    async writeUrlSegment(
        ctx: AsyncContext,
        projectId: string,
        assetGuid: string,
        artifactGuid: string,
        sequence: number,
        fileExtension: string,
        size: number,
        md5: string,
    ) {
        const response = await ApiClient.request<{ url: string }>(
            ctx,
            ApiClient.RequestType.POST,
            `/projects/${projectId}/media_assets/${assetGuid}/artifacts/${artifactGuid}/segments/${sequence}/write_url`,
            undefined,
            {
                size,
                md5,
                file_extension: fileExtension,
            },
            undefined,
            ApiClient.JSON_TYPE_HEADERS,
            undefined,
            false,
            this.delegateAuth,
        );
        return response.url;
    }

    async createSegment(
        ctx: AsyncContext,
        params: {
            projectId: string;
            assetGuid: string;
            artifactGuid: string;
            sequence: number;
            isInit: boolean;
            fileExtension: string;
            size: number;
            md5: string;
            duration: number; // microseconds
            startTime: number; // microseconds
        },
    ): Promise<ApiSegments> {
        this.onlineInvariant();
        const {
            projectId,
            assetGuid,
            artifactGuid,
            md5,
            duration,
            size,
            fileExtension,
            sequence,
            isInit,
            startTime,
        } = params;
        try {
            return await this.createSegmentRequest(
                ctx,
                projectId,
                assetGuid,
                artifactGuid,
                sequence,
                isInit,
                fileExtension,
                size,
                md5,
                duration,
                startTime,
            );
        } catch (e) {
            const error = e as Error;
            handleApiError(error);
        }
    }

    protected async createSegmentRequest(
        ctx: AsyncContext,
        projectId: string,
        assetGuid: string,
        artifactGuid: string,
        sequence: number,
        isInit: boolean,
        fileExtension: string,
        size: number,
        md5: string,
        duration: number, // microseconds
        startTime: number, // microseconds
    ) {
        const response = await ApiClient.request<{ segment: ApiSegments }>(
            ctx,
            ApiClient.RequestType.POST,
            `/projects/${projectId}/media_assets/${assetGuid}/artifacts/${artifactGuid}/segments`,
            undefined,
            {
                file_extension: fileExtension,
                size,
                md5,
                duration,
                sequence,
                is_init: isInit,
                start_time: startTime,
            },
            undefined,
            ApiClient.JSON_TYPE_HEADERS,
            undefined,
            false,
            this.delegateAuth,
        );
        return response.segment;
    }

    async reifySegmentedPlaceholder(
        ctx: AsyncContext,
        params: {
            projectId: string;
            assetGuid: string;
            artifactGuid: string;
            segmentCount: number;
            mediaMetadata: MediaMetadata | undefined;
        },
    ): Promise<RemoteArtifact> {
        this.onlineInvariant();
        const { projectId, assetGuid, artifactGuid, segmentCount, mediaMetadata } = params;
        try {
            return await this.reifySegmentedPlaceholderRequest(
                ctx,
                projectId,
                assetGuid,
                artifactGuid,
                segmentCount,
                mediaMetadata,
            );
        } catch (e) {
            const error = e as Error;
            handleApiError(error);
        }
    }

    protected async reifySegmentedPlaceholderRequest(
        ctx: AsyncContext,
        projectId: string,
        assetGuid: string,
        artifactGuid: string,
        segmentCount: number,
        mediaMetadata: MediaMetadata | undefined,
    ): Promise<RemoteArtifact> {
        const response = await ApiClient.request<{ artifact: RemoteArtifact }>(
            ctx,
            ApiClient.RequestType.POST,
            `/projects/${projectId}/media_assets/${assetGuid}/artifacts/${artifactGuid}/reify_segmented`,
            undefined,
            {
                segment_count: segmentCount,
                metadata: { media: mediaMetadata },
            },
            undefined,
            ApiClient.JSON_TYPE_HEADERS,
            undefined,
            false,
            this.delegateAuth,
        );
        return response.artifact;
    }
}
