// Copyright 2022 Descript, Inc

import SparkMD5 from 'spark-md5';

/**
 * Calculate MD5 for a string.
 *
 * This can be used from the browser. If in NodeJS/Browser, prefer the built-in
 * crypto module instead
 */
export function stringMd5Hex_Spark(str: string): string {
    return SparkMD5.hash(str);
}

interface ModelHasher {
    stringMd5HexSync: (str: string) => string;
}
let _hasher: ModelHasher = {
    stringMd5HexSync: stringMd5Hex_Spark,
};
export function setModelHasher(hasher: ModelHasher): void {
    _hasher = hasher;
}
export function getModelHasher() {
    return _hasher;
}
