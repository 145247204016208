// Copyright 2024 Descript, Inc
import { useTheme } from '@daily/shared/src/contexts/Theme';
import getConfig from 'next/config';
import Head from 'next/head';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '@lib/i18n';

import { useCallState } from '@contexts/CallProvider';
import { useIsMobile } from '@lib/state/core';

export function Meta() {
    const { t } = useTranslation('en', { i18n });
    const callState = useCallState();
    const { assetPrefix } = getConfig().publicRuntimeConfig;
    const { colors } = useTheme();
    const [isMobile] = useIsMobile();

    const title = useMemo(() => {
        switch (callState?.state) {
            case 'awaiting-args':
            case 'joining':
            case 'lobby':
                return t('meta.title.idle', {
                    defaultValue: 'Descript Rooms | Get ready for your call',
                    fallbackLng: 'en',
                });
            case 'joined':
                return t('meta.title.joined', {
                    defaultValue: "Descript Rooms | You're in a call",
                    fallbackLng: 'en',
                });
            case 'ended':
                return t('meta.title.ended', {
                    defaultValue: 'Descript Rooms | The meeting has ended',
                    fallbackLng: 'en',
                });
            case 'error':
                return t('meta.title.error', {
                    defaultValue: 'Descript Rooms | Something went wrong',
                    fallbackLng: 'en',
                });
            case 'expired':
                return t('meta.title.expired', {
                    defaultValue: 'Descript Rooms | This meeting is no longer available',
                    fallbackLng: 'en',
                });
            case 'full':
                return t('meta.title.full', {
                    defaultValue: 'Descript Rooms | The meeting is full',
                    fallbackLng: 'en',
                });
            case 'left':
                return t('meta.title.left', {
                    defaultValue: 'Descript Rooms | You have left the call',
                    fallbackLng: 'en',
                });
            case 'nbf':
                return t('meta.title.nbf', {
                    defaultValue: 'Descript Rooms | This meeting is not available yet',
                    fallbackLng: 'en',
                });
            case 'not-allowed':
                return t('meta.title.notAllowed', {
                    defaultValue: 'Descript Rooms | You are not allowed to join this meeting',
                    fallbackLng: 'en',
                });
            case 'not-found':
                return t('meta.title.notFound', {
                    defaultValue:
                        "Descript Rooms | The meeting you're trying to join does not exist",
                    fallbackLng: 'en',
                });
            case 'not-secure':
                return t('meta.title.notSecure', {
                    defaultValue: 'Descript Rooms | Could not connect using HTTP',
                    fallbackLng: 'en',
                });
            case 'removed-from-call':
                return t('meta.title.removed', {
                    defaultValue: 'Descript Rooms | You were removed from the call',
                    fallbackLng: 'en',
                });
            default:
                break;
        }
    }, [callState?.state, t]);

    return (
        <Head>
            <title>{title}</title>
            <meta name="robots" content="noindex" />
            <meta property="og:title" content="Capture a high-quality recording with others" />
            <meta property="og:type" content="Website" />
            <meta property="og:site_name" content="Descript Rooms" />
            <meta property="og:description" content="Click to join the call" />
            <meta
                property="og:image"
                content="https://cdn.prod.website-files.com/5d761d627a6dfa6a5b28ab12/66bcc21e23599f1577bbab4a_og_rooms.jpg"
            ></meta>
            <meta property="twitter:card" content="summary" />
            <meta property="twitter:site" content="Descript Rooms" />
            <meta
                property="twitter:title"
                content="Capture a high-quality recording with others"
            />
            <meta property="twitter:description" content="Click to join the call" />
            <meta
                name="twitter:image"
                content="https://cdn.prod.website-files.com/5d761d627a6dfa6a5b28ab12/66bcc21e23599f1577bbab4a_og_rooms.jpg"
            ></meta>
            <meta name="color-scheme" content="dark light" />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href={`${assetPrefix}/apple-touch-icon.png`}
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href={`${assetPrefix}/favicon-16x16.png`}
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href={`${assetPrefix}/favicon-32x32.png`}
            />
            <link rel="manifest" href={`${assetPrefix}/site.webmanifest`} />
            <link
                rel="mask-icon"
                href={`${assetPrefix}/safari-pinned-tab.svg`}
                color="#0045e5"
            />
            <meta name="msapplication-config" content={`${assetPrefix}/browserconfig.xml`} />
            <meta
                name="theme-color"
                content={
                    isMobile
                        ? callState?.state === 'lobby'
                            ? colors.background
                            : colors.custom.mainAreaBg
                        : colors.background
                }
            />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
        </Head>
    );
}
