// Copyright 2022 Descript, Inc

import { AssetTracingMetadata } from '@descript/analytics';

let globalDefaultSpanAttributes: AssetTracingMetadata = {};

export function getGlobalDefaultMetadata(): AssetTracingMetadata {
    return globalDefaultSpanAttributes;
}

export function setGlobalDefaultMetadata(metadata: AssetTracingMetadata): void {
    globalDefaultSpanAttributes = metadata;
}
