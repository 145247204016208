import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    id: string;
    muted?: boolean;
    transition?: boolean;
    size?: number;
}

export function CamIcon({
    color = 'currentColor',
    id,
    muted = false,
    transition = true,
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    if (muted) {
        return (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className={transition ? 'transition' : ''}
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path d="M1 1L15 15" stroke={color} />
                <path
                    d="M1.46667 3.5H9.53333C10.0797 3.5 10.5 3.92806 10.5 4.42857V11.5714C10.5 12.0719 10.0797 12.5 9.53333 12.5H1.46667C0.920305 12.5 0.5 12.0719 0.5 11.5714V4.42857C0.5 3.92806 0.920305 3.5 1.46667 3.5Z"
                    stroke={color}
                />
                <path
                    d="M15.5 4L10.5 8L15.5 12V4Z"
                    stroke={color}
                    strokeLinejoin="round"
                    fill="none"
                />
                {/* eslint-disable-next-line react/no-unknown-property */}
                <style jsx>{`
                    svg {
                        height: ${size}px;
                        width: ${size}px;
                        display: block;
                    }
                `}</style>
            </svg>
        );
    }

    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            className={transition ? 'transition' : ''}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M15.5 4L10.5 8L15.5 12V4Z"
                stroke={color}
                strokeLinejoin="round"
                fill="none"
            />
            <path
                d="M1.46667 3.5H9.53333C10.0797 3.5 10.5 3.92806 10.5 4.42857V11.5714C10.5 12.0719 10.0797 12.5 9.53333 12.5H1.46667C0.920305 12.5 0.5 12.0719 0.5 11.5714V4.42857C0.5 3.92806 0.920305 3.5 1.46667 3.5Z"
                stroke={color}
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
