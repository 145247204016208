// Copyright 2019 Descript, Inc

import { useCallback, useEffect, useRef } from 'react';

type ClickHandler = (e: React.MouseEvent) => void;
const MANY_CLICK_INDEX = 2;

function getMaxClickIndex(...args: (ClickHandler | undefined)[]): number {
    for (let i = args.length - 1; i >= 0; i--) {
        if (args[i]) {
            return i;
        }
    }
    return -1;
}

/**
 * Helps prevent a click handler from firing when a user is actually double/multi clicking. Note: this is not a perfect
 * implementation as the double click speed can be configured at the OS level, which we are not able to easily detect.
 * It is recommended to have an alternative method for achieving the effect of the double click handler.
 *
 * Returns unified click handler
 */
export function useMulticlickPreventionHandler({
    onClick,
    onDoubleClick,
    onTripleOrMoreClick,
    stopPropagation,
    waitTime = 200,
}: {
    onClick?: ClickHandler;
    onDoubleClick?: ClickHandler;
    onTripleOrMoreClick?: ClickHandler;
    stopPropagation?: boolean;
    waitTime?: number;
} = {}): (e: React.MouseEvent) => void {
    const clickTimer = useRef(0);
    const clickFns = useRef([onClick, onDoubleClick, onTripleOrMoreClick]);
    const maxClickFnsIndex = useRef(
        getMaxClickIndex(onClick, onDoubleClick, onTripleOrMoreClick),
    );
    useEffect(() => {
        clickFns.current = [onClick, onDoubleClick, onTripleOrMoreClick];
        maxClickFnsIndex.current = getMaxClickIndex(...clickFns.current);
    }, [onClick, onDoubleClick, onTripleOrMoreClick]);

    const combinedClickHandler = useCallback(
        (event: React.MouseEvent) => {
            if (stopPropagation) {
                event.stopPropagation();
            }

            const clickCount = event.detail;
            let fnIndex = Math.min(clickCount - 1, MANY_CLICK_INDEX);

            let fn = clickFns.current[fnIndex];
            while (fnIndex >= 0 && !fn) {
                fn = clickFns.current[fnIndex];
                fnIndex--;
            }

            window.clearTimeout(clickTimer.current);

            if (!fn) {
                // Only true if there are no click handlers provided
                return;
            }

            const handler = fn;

            if (fnIndex >= maxClickFnsIndex.current) {
                // no handlers are registered for greater numbers of clicks; handle now
                handler(event);
            } else {
                event.persist();
                clickTimer.current = window.setTimeout(() => {
                    handler(event);
                }, waitTime);
            }
        },
        [stopPropagation, waitTime],
    );

    return combinedClickHandler;
}
