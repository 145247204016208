// Copyright © 2024 Descript, Inc. All rights reserved.

import { Modifiers, useModifierKeys } from './useModifierKeys';
import { useDebounce } from './useDebounce';
import { useMemo } from 'react';
import { hasCommandModifier } from './KeyboardEvent';

/**
 * A hook that provides the current state of the modifier keys with a delay before release
 *
 * This is useful for things like dragging where the user releases mouse and keyboard around the same time
 * but the keyboard state should persist for a short time after released
 *
 * @param usageContextName passed to useModifierKeys()
 * @param exitDelayMs the delay in milliseconds before the modifier keys transition from pressed to not pressed
 */
export function useModifierKeysDelayed(
    usageContextName: string,
    exitDelayMs: number = 250,
): Modifiers {
    const { altKey, ctrlKey, metaKey, shiftKey } = useModifierKeys(usageContextName);

    // Debounce the modifier keys so that they don't immediately disable when the user releases them
    // This is useful for things like dragging where the user might release the modifier key while still dragging
    // When they press the modifier key, it will immediately enable (0ms debounce)
    const altKeyDebounced = useDebounce(altKey, altKey ? 0 : exitDelayMs);
    const shiftKeyDebounced = useDebounce(shiftKey, shiftKey ? 0 : exitDelayMs);
    const ctrlKeyDebounced = useDebounce(ctrlKey, ctrlKey ? 0 : exitDelayMs);
    const metaKeyDebounced = useDebounce(metaKey, metaKey ? 0 : exitDelayMs);
    return useMemo(() => {
        const modifiers = {
            altKey: altKeyDebounced,
            shiftKey: shiftKeyDebounced,
            ctrlKey: ctrlKeyDebounced,
            metaKey: metaKeyDebounced,
            cmdKey: false,
        };
        // Special-case command because we want to derive it from the other keys
        modifiers.cmdKey = hasCommandModifier(modifiers);
        return modifiers;
    }, [altKeyDebounced, shiftKeyDebounced, ctrlKeyDebounced, metaKeyDebounced]);
}
