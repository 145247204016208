import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function ScreenshareIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1.75 10.5L14.25 10.5C14.9404 10.5 15.5 10.0523 15.5 9.5L15.5 2.5C15.5 1.94771 14.9404 1.5 14.25 1.5L1.75 1.5C1.05964 1.5 0.5 1.94771 0.5 2.5L0.5 9.5C0.5 10.0523 1.05964 10.5 1.75 10.5Z"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M12 14.5H4" stroke={color} strokeLinejoin="round" />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
