// Copyright 2024 Descript, Inc
export function isCloudRecordingType(recType: string): boolean {
    if (!recType) {
        return false;
    }
    const validTypes = ['cloud', 'cloud-beta', 'raw-tracks'];
    const typeArray = recType.split(',');
    for (const type of typeArray) {
        if (validTypes.includes(type)) {
            return true;
        }
    }
    return false;
}
