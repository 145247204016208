import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function EffectsIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M3.50002 2.50006H1.5L1.50002 4.50007"
                stroke={color}
                strokeLinejoin="round"
            />
            <path d="M6 2.5H10" stroke={color} strokeLinejoin="round" />
            <path d="M6 13.5001H10" stroke={color} strokeLinejoin="round" />
            <path d="M1.5 6.00003V10" stroke={color} strokeLinejoin="round" />
            <path d="M14.5 6.00003V10" stroke={color} strokeLinejoin="round" />
            <path
                d="M12.5 2.5C13.2811 2.5 14.5 2.5 14.5 2.5V4.50001"
                stroke={color}
                strokeLinejoin="round"
            />
            <path d="M3.50002 13.5001H1.5V11.5001" stroke={color} strokeLinejoin="round" />
            <path
                d="M12.5 13.5001C13.2811 13.5001 14.5 13.5001 14.5 13.5001L14.5 11.5001"
                stroke={color}
                strokeLinejoin="round"
            />
            <path
                d="M10.3889 11.65C10.3889 10.5443 9.2933 9.64999 7.94445 9.64999C6.59559 9.64999 5.5 10.5443 5.5 11.65"
                stroke={color}
                strokeLinejoin="round"
            />
            <path
                d="M8 8C8.39783 8 8.77936 7.84196 9.06066 7.56066C9.34197 7.27936 9.5 6.89782 9.5 6.5C9.5 6.10218 9.34197 5.72064 9.06066 5.43934C8.77936 5.15804 8.39783 5 8 5C7.60218 5 7.22065 5.15804 6.93934 5.43934C6.65804 5.72064 6.5 6.10218 6.5 6.5C6.5 6.89782 6.65804 7.27936 6.93934 7.56066C7.22065 7.84196 7.60218 8 8 8Z"
                stroke={color}
                strokeLinejoin="round"
            />
        </svg>
    );
}
