// Copyright 2022 Descript, Inc
import {
    MessageRequest,
    WorkerRequester,
    WorkerRequestOf,
    WorkerResponder,
} from './WorkerTypes';
import { MessageResponseOf } from './WorkerClient';

export class WebWorkerRequester<CommandCreators> implements WorkerRequester<CommandCreators> {
    constructor(private readonly webWorker: Worker) {
        // NOP
    }

    onMessage(handler: (data: MessageResponseOf<CommandCreators>) => void): void {
        this.webWorker.addEventListener('message', (event: MessageEvent) => {
            return handler(event.data);
        });
    }

    postMessage(
        message: MessageRequest<WorkerRequestOf<CommandCreators>>,
        transferable?: Transferable[],
    ): void {
        if (transferable !== undefined) {
            return this.webWorker.postMessage(message, transferable);
        } else {
            return this.webWorker.postMessage(message);
        }
    }
}

export class WebWorkerResponder<CommandCreators> implements WorkerResponder<CommandCreators> {
    constructor(private readonly webWorker: Worker) {
        // NOP
    }

    onMessage(handler: (data: MessageRequest<WorkerRequestOf<CommandCreators>>) => void): void {
        this.webWorker.addEventListener('message', (event: MessageEvent) => {
            return handler(event.data);
        });
    }

    postMessage(
        message: MessageResponseOf<CommandCreators>,
        transferable?: Transferable[],
    ): void {
        if (transferable !== undefined) {
            return this.webWorker.postMessage(message, transferable);
        } else {
            return this.webWorker.postMessage(message);
        }
    }
}
