// Copyright 2022 Descript, Inc

import * as React from 'react';

export const colorSchemes = ['light', 'dark', 'system'] as const;
export type ColorScheme = (typeof colorSchemes)[number];
export type ActiveColorScheme = Exclude<ColorScheme, 'system'>;
export const ColorSchemeContext = React.createContext<ActiveColorScheme>('light');

export function useColorScheme() {
    return React.useContext(ColorSchemeContext);
}
