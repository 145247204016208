import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function AdminIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 5.42857C8 4.22222 8.5 2 11 2C13.5 2 14 4.22222 14 5.42857C14 8.28571 13 10 11 10C9 10 8 8.28571 8 5.42857ZM11 11.5C9.5 11.5 9 11 9 11C5.33328 12.5 3 13.5 3 17C3 17.5 3.5 18 4.00005 18H11C11 15.2386 13.2386 13 16 13C16.4381 13 16.863 13.0563 17.2679 13.1622C16.2316 12.3326 14.7714 11.7247 13 11C13 11 12.5 11.5 11 11.5ZM15.5555 14H16.4444L16.6462 15.4125C16.905 15.4771 17.149 15.5797 17.3719 15.714L18.5141 14.8573L19.1426 15.4859L18.286 16.628C18.4202 16.8509 18.5228 17.0949 18.5875 17.3538L20 17.5555V18.4444L18.5875 18.6462C18.5229 18.9051 18.4203 19.1491 18.286 19.3719L19.1427 20.5141L18.5141 21.1427L17.3719 20.286C17.1491 20.4203 16.9051 20.5229 16.6462 20.5875L16.4444 22H15.5555L15.3537 20.5875C15.0949 20.5228 14.8509 20.4203 14.6281 20.286L13.4858 21.1427L12.8572 20.5142L13.714 19.3719C13.5797 19.149 13.4771 18.9051 13.4125 18.6462L12 18.4444V17.5556L13.4125 17.3538C13.4771 17.0949 13.5797 16.851 13.714 16.6281L12.8572 15.4858L13.4858 14.8573L14.6281 15.714C14.8509 15.5797 15.0949 15.4772 15.3537 15.4125L15.5555 14ZM16 19.3334C15.2647 19.3334 14.6666 18.7353 14.6666 18C14.6666 17.2648 15.2647 16.6667 16 16.6667C16.7352 16.6667 17.3333 17.2648 17.3333 18C17.3333 18.7353 16.7352 19.3334 16 19.3334Z"
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    fill: ${color};
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
