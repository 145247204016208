// Copyright 2024 Descript, Inc
import { ReactNull } from '@descript/react-utils';
import { useEffect, useRef } from 'react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface Props extends React.HTMLAttributes<HTMLElement> {
    el?: string;
}

const canUseDOM = !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
);

export function Portal({ children, el = 'div', ...rest }: Props) {
    const portal = useRef(canUseDOM ? document.createElement(el) : ReactNull);

    useEffect(() => {
        if (!portal.current) {
            return;
        }

        // assigning all the HTMLElement properties to the element
        const portalEl = Object.assign(portal.current, rest);

        document.body.appendChild(portalEl);
        return () => {
            document.body.removeChild(portalEl);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return canUseDOM
        ? (ReactDOM.createPortal(children, portal.current) as JSX.Element)
        : ReactNull;
}
