import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function SidebarIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M2.55556 1.5H13.4444C14.0274 1.5 14.5 1.97259 14.5 2.55556V13.4444C14.5 14.0274 14.0274 14.5 13.4444 14.5H2.55556C1.97259 14.5 1.5 14.0274 1.5 13.4444V2.55556C1.5 1.97259 1.97259 1.5 2.55556 1.5Z"
                stroke={color}
            />
            <path d="M10.5 1V15" stroke={color} />
        </svg>
    );
}
