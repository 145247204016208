// Copyright 2022 Descript, Inc

import { useLayoutEffect } from 'react';
import { DescriptError, ErrorCategory } from '@descript/errors';

/**
 * Add and remove a CSS classname from the #root element
 *
 * @param active should this classname be active?
 * @param className the classname to add to #root
 */
export function useRootClassname(active: boolean, className: string) {
    useLayoutEffect(() => {
        if (!active) {
            return undefined;
        }
        // eslint-disable-next-line @descript-eslint/no-force-reflow
        const root = document.getElementById('root');
        if (!root) {
            throw new DescriptError('No #root found.', ErrorCategory.AppArchitecture);
        }

        // Modals are not rendered under #root, so classnames need to be added separately
        const modalRoots = [
            ...document.getElementsByClassName('RadixDialogOverlay'),
            ...document.getElementsByClassName('ReactModalPortal'),
            ...document.getElementsByClassName('portal-layer'),
            // eslint-disable-next-line @descript-eslint/no-force-reflow
            ...document.querySelectorAll('[data-dialog]'),
        ];
        const roots = [root, ...modalRoots];
        roots.forEach((r) => r.classList.add(className));
        return () => {
            roots.forEach((r) => r.classList.remove(className));
        };
    }, [active, className]);
}
