import {
    defaultDarkTheme,
    defaultLightTheme,
    ThemeColors,
} from '@daily/shared/src/contexts/Theme';
import { DailyThemeColors, DailyThemeConfig } from '@daily-co/daily-js';

export type CustomColors = {
    mainAreaBg: string;
    mainAreaBgAccent: string;
    mainAreaText: string;
    descriptGrey0: string;
    descriptGrey5: string;
    descriptGrey100: string;
    descriptGrey400: string;
    descriptGrey800: string;
    descriptGrey900: string;
    descriptBlue500: string;
    descriptGreen500: string;
};

export const defaultLightColors: ThemeColors<CustomColors> = {
    ...defaultLightTheme.colors,
    custom: {
        mainAreaBg: defaultLightTheme.colors.system.blueDark,
        mainAreaBgAccent: defaultLightTheme.colors.system.blue,
        mainAreaText: defaultLightTheme.colors.system.white,
        // Custom Descript color palette
        descriptGrey0: defaultLightTheme.colors.system.descript.lightMode.grey[0],
        descriptGrey5: defaultLightTheme.colors.system.descript.lightMode.grey[5],
        descriptGrey100: defaultLightTheme.colors.system.descript.lightMode.grey[100],
        descriptGrey400: defaultLightTheme.colors.system.descript.lightMode.grey[400],
        descriptGrey800: defaultLightTheme.colors.system.descript.lightMode.grey[800],
        descriptGrey900: defaultLightTheme.colors.system.descript.lightMode.grey[900],
        descriptBlue500: defaultLightTheme.colors.system.descript.lightMode.blue[500],
        descriptGreen500: defaultLightTheme.colors.system.descript.lightMode.green[500],
    },
};
export const defaultDarkColors: ThemeColors<CustomColors> = {
    ...defaultDarkTheme.colors,
    custom: {
        mainAreaBg: defaultDarkTheme.colors.system.blueDark,
        mainAreaBgAccent: defaultDarkTheme.colors.system.blueLight,
        mainAreaText: defaultDarkTheme.colors.system.white,
        // Custom Descript color palette
        descriptGrey0: defaultLightTheme.colors.system.descript.darkMode.grey[0],
        descriptGrey5: defaultLightTheme.colors.system.descript.darkMode.grey[5],
        descriptGrey100: defaultLightTheme.colors.system.descript.darkMode.grey[100],
        descriptGrey400: defaultLightTheme.colors.system.descript.darkMode.grey[400],
        descriptGrey800: defaultLightTheme.colors.system.descript.darkMode.grey[800],
        descriptGrey900: defaultLightTheme.colors.system.descript.darkMode.grey[900],
        descriptBlue500: defaultLightTheme.colors.system.descript.darkMode.blue[500],
        descriptGreen500: defaultDarkTheme.colors.system.descript.darkMode.green[500],
    },
};

const getThemeColors = (colors: DailyThemeColors, fallback: ThemeColors<CustomColors>) => ({
    accent: colors?.accent ?? fallback.accent,
    accentText: colors?.accentText ?? fallback.accentText,
    background: colors?.background ?? fallback.background,
    backgroundAccent: colors?.backgroundAccent ?? fallback.backgroundAccent,
    baseText: colors?.baseText ?? fallback.baseText,
    border: colors?.border ?? fallback.border,
    supportiveText: colors?.supportiveText ?? fallback.supportiveText,
    custom: {
        mainAreaBg: colors?.mainAreaBg ?? fallback.custom?.mainAreaBg,
        mainAreaBgAccent: colors?.mainAreaBgAccent ?? fallback.custom?.mainAreaBgAccent,
        mainAreaText: colors?.mainAreaText ?? fallback.custom?.mainAreaText,
        // Custom Descript color palette
        descriptGrey0: fallback.custom?.descriptGrey0,
        descriptGrey5: fallback.custom?.descriptGrey5,
        descriptGrey100: fallback.custom?.descriptGrey100,
        descriptGrey400: fallback.custom?.descriptGrey400,
        descriptGrey800: fallback.custom?.descriptGrey800,
        descriptGrey900: fallback.custom?.descriptGrey900,
        descriptBlue500: fallback.custom?.descriptBlue500,
        descriptGreen500: fallback.custom?.descriptGreen500,
    },
    system: fallback.system,
});

export const convertDailyThemeColors = (
    theme: DailyThemeConfig,
): [ThemeColors<CustomColors>, ThemeColors<CustomColors>] => {
    if (!theme) {
        return [defaultLightColors, defaultDarkColors];
    }
    if ('light' in theme && 'dark' in theme) {
        return [
            getThemeColors(theme.light.colors, defaultLightColors),
            getThemeColors(theme.dark.colors, defaultDarkColors),
        ];
    }
    return [
        getThemeColors(theme.colors, defaultLightColors),
        getThemeColors(theme.colors, defaultLightColors),
    ];
};
