import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function UserIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M13.5 15C13.5 12.5121 11.0349 10.5 8 10.5C4.96508 10.5 2.5 12.5121 2.5 15"
                stroke={color}
                strokeLinejoin="round"
            />
            <path
                d="M8 7.5C8.79565 7.5 9.55871 7.18393 10.1213 6.62132C10.6839 6.05871 11 5.29565 11 4.5C11 3.70435 10.6839 2.94129 10.1213 2.37868C9.55871 1.81607 8.79565 1.5 8 1.5C7.20435 1.5 6.44129 1.81607 5.87868 2.37868C5.31607 2.94129 5 3.70435 5 4.5C5 5.29565 5.31607 6.05871 5.87868 6.62132C6.44129 7.18393 7.20435 7.5 8 7.5Z"
                stroke={color}
                strokeLinejoin="round"
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
