import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function LockIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11.8889 7H4.11111C3.49746 7 3 7.65122 3 8.45455V13.5455C3 14.3488 3.49746 15 4.11111 15H11.8889C12.5025 15 13 14.3488 13 13.5455V8.45455C13 7.65122 12.5025 7 11.8889 7Z"
                stroke={color}
            />
            <path
                d="M5 7V4.33333C5 3.44928 5.31607 2.60143 5.87868 1.97631C6.44129 1.35119 7.20435 1 8 1C8.79565 1 9.55871 1.35119 10.1213 1.97631C10.6839 2.60143 11 3.44928 11 4.33333V7"
                stroke={color}
            />
        </svg>
    );
}
