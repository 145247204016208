// Copyright 2024 Descript, Inc
import { ReactNull } from '@descript/react-utils';
import { DailyInputSettings, DailyInputVideoProcessorSettings } from '@daily-co/daily-js';
import { useDaily, useDailyEvent, useInputSettings } from '@daily-co/daily-react';
import deepEqual from 'fast-deep-equal';
import Cookies from 'js-cookie';
import getConfig from 'next/config';
import { useCallback, useState } from 'react';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';

import { useCallConfig } from './useCallConfig';

type Effect =
    | 'none'
    | 'soft-blur'
    | 'strong-blur'
    | 'vb-coffeeshop'
    | 'vb-forest'
    | 'vb-hills'
    | 'vb-library'
    | 'vb-lounge'
    | 'vb-ocean'
    | 'vb-office'
    | 'vb-palms'
    | 'vb-rollercoaster'
    | 'custom';

const sortedEffects: Effect[] = [
    'none',
    'soft-blur',
    'strong-blur',
    'vb-coffeeshop',
    'vb-forest',
    'vb-hills',
    'vb-library',
    'vb-lounge',
    'vb-ocean',
    'vb-office',
    'vb-palms',
    'vb-rollercoaster',
];

const COOKIE_KEY = 'bg-effect';

const selectedBackgroundEffectState = atom<Effect>({
    key: 'selected-background-effect',
    default: 'none',
    effects: [
        /**
         * Synchronizes atom state with cookie.
         */
        ({ onSet, setSelf, trigger }) => {
            if (trigger === 'get') {
                const storedBgEffect = Cookies.get(COOKIE_KEY) as Effect;
                if (sortedEffects.includes(storedBgEffect)) {
                    setSelf(storedBgEffect);
                }
            }
            onSet((newEffect) => {
                if (newEffect === 'none') {
                    Cookies.remove(COOKIE_KEY, {
                        sameSite: 'none',
                        secure: true,
                    });
                } else {
                    Cookies.set(COOKIE_KEY, newEffect, {
                        expires: new Date('Tue, 19 Jan 2038 03:14:07 GMT'),
                        sameSite: 'none',
                        secure: true,
                    });
                }
            });
        },
    ],
});

export const useBackgroundEffect = () => {
    const { enableVideoProcessingUI } = useCallConfig();
    const currentEffect = useRecoilValue(selectedBackgroundEffectState);
    const { assetPrefix } = getConfig().publicRuntimeConfig;
    const daily = useDaily();
    const [loading, setLoading] = useState(false);

    const { inputSettings, updateInputSettings } = useInputSettings({
        onInputSettingsUpdated: useRecoilCallback(
            ({ set }) =>
                (ev) => {
                    if (!enableVideoProcessingUI) {
                        return;
                    }
                    let newEffect: Effect = 'none';

                    const { video } = ev.inputSettings ?? {};
                    if (video?.processor?.type === 'background-blur') {
                        const { config } = video.processor;
                        const level = config?.strength ?? 0;
                        if (level === 0) {
                            newEffect = 'none';
                        } else if (level < 0.5) {
                            newEffect = 'soft-blur';
                        } else {
                            newEffect = 'strong-blur';
                        }
                        set(selectedBackgroundEffectState, newEffect);
                    } else if (video?.processor?.type === 'background-image') {
                        const { config } = video.processor;
                        if (config.source) {
                            if (config.source instanceof ArrayBuffer) {
                                set(selectedBackgroundEffectState, 'custom');
                            } else {
                                const url = new URL(config.source as string);
                                const match = url.pathname.match(
                                    new RegExp(
                                        sortedEffects
                                            .filter((fx) => fx.startsWith('vb-'))
                                            .join('|'),
                                    ),
                                );
                                if (match) {
                                    newEffect = match[0] as Effect;
                                    set(selectedBackgroundEffectState, newEffect);
                                } else {
                                    set(selectedBackgroundEffectState, 'custom');
                                }
                            }
                        }
                    } else {
                        set(selectedBackgroundEffectState, 'none');
                    }
                    setLoading(false);
                },
            [enableVideoProcessingUI],
        ),
        onError: useCallback(() => {
            setLoading(false);
        }, []),
    });

    const selectEffect = useCallback(
        (effect: Effect) => {
            try {
                let type: DailyInputVideoProcessorSettings['type'] = 'none';
                let blurLevel = 0;
                if (effect.endsWith('-blur')) {
                    type = 'background-blur';
                    switch (effect) {
                        case 'strong-blur':
                            blurLevel = 1;
                            break;
                        case 'soft-blur':
                            blurLevel = 0.2;
                            break;
                        default:
                            break;
                    }
                } else if (effect.startsWith('vb-')) {
                    type = 'background-image';
                }

                let settings: DailyInputSettings | null = ReactNull;

                switch (type) {
                    case 'none':
                        settings = {
                            video: {
                                processor: {
                                    type,
                                },
                            },
                        };
                        break;
                    case 'background-blur':
                        settings = {
                            video: {
                                processor: {
                                    type,
                                    config: {
                                        strength: blurLevel,
                                    },
                                },
                            },
                        };
                        break;
                    case 'background-image': {
                        const { hostname, port, protocol } = window.location;
                        // Check if assetPrefix already contains FQDN
                        // eslint-disable-next-line no-useless-escape
                        const prefix = assetPrefix.match(/^https?\:\/\//)
                            ? assetPrefix
                            : `${protocol}//${hostname}:${port}${assetPrefix}`;
                        settings = {
                            video: {
                                processor: {
                                    type,
                                    config: {
                                        source: `${prefix}/backgrounds/${effect}.jpg`,
                                    },
                                },
                            },
                        };
                        break;
                    }
                }

                if (
                    deepEqual(inputSettings, settings) ||
                    (deepEqual(inputSettings, {}) && effect === 'none')
                ) {
                    return;
                }

                setLoading(true);
                updateInputSettings(settings).catch((e) => {
                    console.error(e);
                });
            } catch (e) {
                console.error(e);
            }
        },
        [assetPrefix, inputSettings, updateInputSettings],
    );

    const selectCustomBackground = useCallback(
        async (data: string | ArrayBuffer) => {
            setLoading(true);
            await updateInputSettings({
                video: {
                    processor: {
                        type: 'background-image',
                        config: {
                            source: data,
                        },
                    },
                },
            });
        },
        [updateInputSettings],
    );

    useDailyEvent(
        'started-camera',
        useCallback(async () => {
            if (!enableVideoProcessingUI || !daily) {
                return;
            }
            // Read currently applied inputSettings in daily-js
            const newInputSettings = await daily.getInputSettings();
            // Apply prebuilt bg-effect cookie if and only if no input settings are
            // currently applied in daily-js. This is to prevent overriding settings
            // provided in join(), createFrame(), createCallObject() or by calling
            // updateInputSettings() directly. Without any such settings applied, the
            // inputSettings object will be empty {}.
            if (currentEffect && !newInputSettings?.video?.processor) {
                if (currentEffect !== 'none') {
                    setLoading(true);
                }
                selectEffect(currentEffect);
            }
        }, [currentEffect, daily, enableVideoProcessingUI, selectEffect]),
    );

    return {
        currentEffect,
        effects: sortedEffects,
        loading,
        selectCustomBackground,
        selectEffect,
    };
};
