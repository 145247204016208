import {
    DailyCustomIntegration,
    DailyCustomIntegrations,
    DailyNetworkTopology,
} from '@daily-co/daily-js';
import {
    useDaily,
    useDailyEvent,
    useLocalSessionId,
    useMeetingSessionState,
    useParticipantProperty,
} from '@daily-co/daily-react';
import { useCallback } from 'react';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';

const customIntegrationsState = atom<DailyCustomIntegrations>({
    key: 'custom-integrations',
    default: {},
});

const SESSION_STATE_KEY = 'integrations';

interface IntegrationsState {
    [integrationId: string]: {
        shared: DailyCustomIntegration['shared'];
        startedBy: string;
    };
}

interface MeetingSessionState {
    data: {
        integrations: IntegrationsState;
    };
    topology: DailyNetworkTopology;
}

const integrationsMeetingState = atom<IntegrationsState>({
    key: 'integrations-meeting-state',
    default: {},
});

const locallyRunningIntegrationsState = atom<string[]>({
    key: 'locally-running-integrations',
    default: [],
});

export interface CustomIntegration extends DailyCustomIntegration {
    id: string;
    running?: boolean;
    startedBy?: string;
}

/**
 * Returns custom integrations and a setter to change them.
 */
export const useCustomIntegrations = () => {
    const daily = useDaily();

    const runningLocalIntegrations = useRecoilValue(locallyRunningIntegrationsState);
    const runningSharedIntegrations = useRecoilValue(integrationsMeetingState);
    useDailyEvent(
        'meeting-session-state-updated',
        useRecoilCallback(
            ({ set }) =>
                (ev) => {
                    const state = ev.meetingSessionState as MeetingSessionState;
                    set(integrationsMeetingState, state.data?.integrations ?? {});
                },
            [],
        ),
    );

    const integrations = useRecoilValue(customIntegrationsState);
    const _integrationsAsArray = Object.entries(integrations).map<CustomIntegration>(
        ([id, integration]) => ({
            ...integration,
            id,
        }),
    );

    const localSessionId = useLocalSessionId();
    const [isOwner, userId, userName] = useParticipantProperty(localSessionId, [
        'owner',
        'user_id',
        'user_name',
    ]);
    const replacePlaceholders = (src: string = '') =>
        src
            .replaceAll('{{session_id}}', localSessionId)
            .replaceAll('{{user_id}}', userId)
            .replaceAll('{{user_name}}', userName);

    const isSharedWithLocalParticipant = (integration: CustomIntegration) => {
        if (typeof integration.shared === 'boolean') {
            return integration.shared;
        }
        if (integration.shared === 'owners') {
            return isOwner;
        }
        if (Array.isArray(integration.shared)) {
            return integration.shared.includes(localSessionId);
        }
        return false;
    };

    const meetingSessionState = useMeetingSessionState<MeetingSessionState['data']>();

    const mainIntegrations = _integrationsAsArray
        .filter((integration) => integration.location === 'main')
        .map<CustomIntegration>((integration) => ({
            ...integration,
            src: replacePlaceholders(integration.src),
            running:
                (integration.id in runningSharedIntegrations &&
                    isSharedWithLocalParticipant(integration)) ||
                runningLocalIntegrations.includes(integration.id),
            startedBy: meetingSessionState?.data?.integrations?.[integration.id]?.startedBy,
        }));
    const sidebarIntegrations = _integrationsAsArray
        .filter((integration) => integration.location === 'sidebar')
        .map<CustomIntegration>((integration) => ({
            ...integration,
            src: replacePlaceholders(integration.src),
        }));

    const runningMainIntegrations = mainIntegrations.filter((i) => i.running);

    const setCustomIntegrations = useRecoilCallback(
        ({ set }) =>
            (newVal: DailyCustomIntegrations) => {
                for (const [key, val] of Object.entries(newVal)) {
                    if (!val.controlledBy) {
                        newVal[key].controlledBy = '*';
                    }
                }
                set(customIntegrationsState, newVal);
            },
        [],
    );

    const startIntegration = useRecoilCallback(
        ({ set }) =>
            (id: string) => {
                const integration = mainIntegrations.find((m) => m.id === id);
                if (!integration?.shared) {
                    set(locallyRunningIntegrationsState, (ids) => [...new Set([...ids, id])]);
                    return;
                }
                const state = daily.meetingSessionState() as MeetingSessionState;
                const runningIntegrations = { ...state.data[SESSION_STATE_KEY] };
                runningIntegrations[id] = {
                    shared: integration.shared,
                    startedBy: localSessionId,
                };
                daily.setMeetingSessionData(
                    {
                        [SESSION_STATE_KEY]: runningIntegrations,
                    },
                    'shallow-merge',
                );
            },
        [daily, localSessionId, mainIntegrations],
    );

    const stopIntegration = useRecoilCallback(
        ({ set }) =>
            (id: string) => {
                const state = daily.meetingSessionState() as MeetingSessionState;
                const runningIntegrations = { ...state.data[SESSION_STATE_KEY] };
                if (!runningIntegrations[id]) {
                    set(locallyRunningIntegrationsState, (ids) =>
                        [...ids].filter((i) => i !== id),
                    );
                    return;
                }
                delete runningIntegrations[id];
                daily.setMeetingSessionData(
                    {
                        [SESSION_STATE_KEY]: runningIntegrations,
                    },
                    'shallow-merge',
                );
            },
        [daily],
    );

    const canControlIntegration = useCallback(
        ({ controlledBy }: DailyCustomIntegration) =>
            controlledBy === '*' ||
            (controlledBy === 'owners' && isOwner) ||
            (Array.isArray(controlledBy) && controlledBy.includes(localSessionId)),
        [isOwner, localSessionId],
    );

    return {
        canControlIntegration,
        mainIntegrations,
        runningMainIntegrations,
        runningSharedIntegrations,
        sidebarIntegrations,
        setCustomIntegrations,
        startIntegration,
        stopIntegration,
    };
};
