// Copyright 2019 Descript, Inc

import { useCallback, useState } from 'react';

/**
 * A simple hook that create a boolean state and returns a function to turn it on and turn it off.
 *
 * @example
 * const [isAwesome, turnAwesomeOn, turnAwesomeOff] = useOnOff(true);
 */
export function useOnOff(
    initialValue: boolean | (() => boolean) = false,
): [boolean, () => void, () => void, (newValue: boolean) => void] {
    const [isOn, setOn] = useState(initialValue);
    const turnOn = useCallback(() => setOn(true), []);
    const turnOff = useCallback(() => setOn(false), []);
    return [isOn, turnOn, turnOff, setOn];
}
