// Copyright 2019 Descript, Inc

import { useEffect, useRef } from 'react';
import { HybridKeyboardEvent, isDomNode } from './ReactEventHandlers';
import { isEventInSelectableElement, isEventInTextInputElement } from './EventHelpers';

// const enum so these get compiled out -- there's no overhead
export const enum KeyCode {
    BACKSPACE = 8,
    TAB = 9,
    ENTER = 13,
    CTRL = 17,
    ALT = 18,
    ESCAPE = 27,
    SPACE = 32,
    LEFT_ARROW = 37,
    UP_ARROW = 38,
    RIGHT_ARROW = 39,
    DOWN_ARROW = 40,
    NUMBER_0 = 48,
    NUMBER_1 = 49,
    NUMBER_2 = 50,
    NUMBER_3 = 51,
    NUMBER_4 = 52,
    NUMBER_5 = 53,
    NUMBER_6 = 54,
    NUMBER_7 = 55,
    NUMBER_8 = 56,
    NUMBER_9 = 57,
    A = 65,
    B = 66,
    C = 67,
    D = 68,
    E = 69,
    F = 70,
    G = 71,
    H = 72,
    I = 73,
    J = 74,
    K = 75,
    L = 76,
    M = 77,
    N = 78,
    O = 79,
    P = 80,
    Q = 81,
    R = 82,
    S = 83,
    T = 84,
    U = 85,
    V = 86,
    W = 87,
    X = 88,
    Y = 89,
    Z = 90,
    SEMICOLON = 186,
    EQUAL_SIGN = 187,
    COMMA = 188,
    DASH = 189,
    PERIOD = 190,
    FORWARD_SLASH = 191,
    OPEN_BRACKET = 219,
    BACK_SLASH = 220,
    CLOSE_BRACKET = 221,
    SINGLE_QUOTE = 222,
}

type UseKeyCommandOptions = {
    keyCode: number;
    onPress: ((event: HybridKeyboardEvent) => void) | undefined;
    stopPropagationAndPreventDefault?: boolean;
    capture?: boolean;
    enabled?: boolean;
    disabledInSelectableElement?: boolean;
} & (
    | { disabledInInput?: boolean; disabledInContentEditable?: never }
    | { disabledInInput: true; disabledInContentEditable?: boolean }
);

/**
 * Triggers `onPress` when a global keydown matches `keyCode`.
 * Disabled if `onPress` is undefined.
 */
export function useKeyCommand({
    keyCode,
    onPress,
    stopPropagationAndPreventDefault = true,
    capture = true,
    disabledInInput = false,
    disabledInContentEditable = disabledInInput,
    disabledInSelectableElement,
    enabled = true,
}: UseKeyCommandOptions): void {
    const onPressRef = useRef<((event: HybridKeyboardEvent) => void) | undefined>(onPress);
    useEffect(() => {
        onPressRef.current = onPress;
    }, [onPress]);

    useEffect(() => {
        if (!enabled) {
            return;
        }

        const listener = (event: HybridKeyboardEvent) => {
            if (
                !onPressRef.current ||
                (disabledInInput &&
                    isEventInTextInputElement(event, disabledInContentEditable)) ||
                (disabledInSelectableElement && isEventInSelectableElement(event)) ||
                (isDomNode(event.target) && event.target.closest('[data-radix-menu-content]'))
            ) {
                return;
            }
            if (
                event.keyCode === keyCode &&
                !event.altKey &&
                !event.ctrlKey &&
                !event.shiftKey &&
                !event.metaKey
            ) {
                onPressRef.current(event);
                if (stopPropagationAndPreventDefault) {
                    event.stopPropagation();
                    event.preventDefault();
                }
            }
        };
        window.addEventListener('keydown', listener, capture);
        return () => {
            window.removeEventListener('keydown', listener, capture);
        };
    }, [
        enabled,
        keyCode,
        stopPropagationAndPreventDefault,
        capture,
        disabledInInput,
        disabledInContentEditable,
        disabledInSelectableElement,
    ]);
}
