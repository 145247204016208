// Copyright 2019 Descript, Inc

import crypto from 'crypto';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { IdObject, GenericId, LegacyId } from './IdObject';
import { fastDeepEqual } from '@descript/fast-deep-equal';

export function newId<I extends LegacyId = LegacyId>(): I {
    return uuidv4().toLowerCase() as I;
}

/**
 --- Deterministic IDs ---
    Code edits should be made with extreme caution as uuid-v5's are computed via hashing and any changes to logic or
 input break the comparison between code versions.
    Uuid-V5 is a computed deterministic id based on a string of dependant input and a namespace uuid(v5).  The URL
 namespace id defined in the spec, and an id based on `descript.com` provides a unique root namespace for our codebase.
 In general, new namespaces should be created whenever there is a valid id comparison (comparable objects).  The input
 string should be unique and deterministic based on the same variables that define the determinism of the object, plus a
 unique (per namespace) identifier for the deterministic id function.  Ideally there is only one applicable function
 per object creation so there is no uncertainty of what the id should be.
    It should be preferred to write deterministic id functions in one location like this so there is no contention or
 drift for how any given object's id should be computed.
**/
const ROOT_NAMESPACE = uuidv5.URL;
export const DESCRIPT_NAMESPACE = uuidv5('descript.com', ROOT_NAMESPACE);

/**
 * Generates a deterministic ID based on a set of string seeds.
 * E.g. useful in migrations where determinism is desired.
 *
 * Deprecated in favor of newDeterministicId moving forward.
 */
export function legacy_newDeterministicId<I extends LegacyId = LegacyId>(
    ...seeds: readonly string[]
): I {
    const hash = crypto.createHash('sha256');
    hash.update(seeds.join(':'));
    return uuidv4({ random: new Uint8Array(hash.digest().slice(0, 16)) }) as I;
}

/**
 * Generates a deterministic ID based on a set of string seeds.
 * E.g. useful in migrations where determinism is desired.
 */
export function newDeterministicId<I extends LegacyId = LegacyId>(
    seeds: readonly string[],
    namespace = DESCRIPT_NAMESPACE,
): I {
    return uuidv5(seeds.join(':'), namespace) as I;
}

/**
 * Generates a deterministic ID based on a set of string seeds and checks if
 * the ID already exists. If it does, increments a counter in the seed and tries recursively.
 */
export function makeDeterministicUniqueId<I extends GenericId>({
    seeds,
    doesIdExist,
    counter,
    namespace,
}: {
    seeds: string[];
    doesIdExist: (id: I) => boolean;
    counter?: number;
    namespace?: string;
}): I {
    counter = counter ?? 0;
    namespace = namespace ?? DESCRIPT_NAMESPACE;
    const potentialId = newDeterministicId<I>([...seeds, counter.toString()], namespace);
    if (doesIdExist(potentialId)) {
        // Track collision events TODO SYN-126.
        return makeDeterministicUniqueId<I>({
            seeds,
            doesIdExist,
            counter: counter + 1,
            namespace,
        });
    }
    return potentialId;
}

export function areEqualIgnoringId<T extends IdObject>(a: T, b: T): boolean {
    if (a.id !== b.id) {
        return fastDeepEqual({ ...a, id: b.id }, b);
    }
    return fastDeepEqual(a, b);
}
