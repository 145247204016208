// Copyright 2021 Descript, Inc

import { useEffect, useRef } from 'react';

// Inspired by: https://github.com/donavon/use-event-listener
type Options = boolean | AddEventListenerOptions;

export function useEventListener<K extends keyof HTMLElementEventMap>(
    eventName: K,
    handler: ((event: HTMLElementEventMap[K]) => void) | undefined,
    // allow null to support usage with `useRef<HTMLElement | null>(null)`
    element: HTMLElement | null,
    options?: Options,
): void;
export function useEventListener<K extends keyof DocumentEventMap>(
    eventName: K,
    handler: ((event: DocumentEventMap[K]) => void) | undefined,
    element: Document,
    options?: Options,
): void;
export function useEventListener<K extends keyof WindowEventMap>(
    eventName: K,
    handler: ((event: WindowEventMap[K]) => void) | undefined,
    element?: Window | null,
    options?: Options,
): void;
export function useEventListener<
    K extends keyof (HTMLElementEventMap & DocumentEventMap & WindowEventMap),
>(
    eventName: K,
    handler:
        | ((event: (HTMLElementEventMap & DocumentEventMap & WindowEventMap)[K]) => void)
        | undefined,
    element: HTMLElement | Document | Window | null = window,
    options?: Options,
): void {
    const savedHandler = useRef<
        | ((event: (HTMLElementEventMap & DocumentEventMap & WindowEventMap)[K]) => void)
        | undefined
    >(undefined);

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    // Force useEffect to re-run when handler switches between defined and undefined
    const hasHandler = handler !== undefined;

    useEffect(() => {
        if (!savedHandler.current || !element || !element.addEventListener) {
            return;
        }

        const eventListener = (
            event: (HTMLElementEventMap & DocumentEventMap & WindowEventMap)[K],
        ) => savedHandler.current?.(event);

        element.addEventListener(
            eventName,
            eventListener as EventListenerOrEventListenerObject,
            options,
        );

        return () => {
            element.removeEventListener(
                eventName,
                eventListener as EventListenerOrEventListenerObject,
                options,
            );
        };
    }, [hasHandler, element, eventName, options]);
}
