// Copyright © 2024 Descript, Inc. All rights reserved.

import * as NUserSettings from '@descript/client/src/App/UserSettings';
import { defaultTranscriptionLanguage } from '@descript/descript-model';
import { RecorderMetadata, WebRecordingSession } from '@descript/recorder-base';
import { DescriptError, ErrorCategory } from '@descript/errors';

const sessionMap = new Map<string, WebRecordingSession>();

/**
 * Creates a new web recording session.
 *
 * This function takes a user ID as a required parameter and an optional initial session object.
 * If a session with the given user ID already exists, an error will be thrown.
 **/
export function createWebRecordingSession(
    sessionId: string,
    initialSession: Partial<WebRecordingSession> & { projectId: string },
): WebRecordingSession {
    const sessionExists = sessionMap.has(sessionId);
    if (sessionExists) {
        throw new DescriptError(
            'Could not create Web Recording Session. Session already exists.',
            ErrorCategory.Recording,
        );
    }

    const session: WebRecordingSession = {
        sessionId,
        recorders: new Map(),
        transcriptionLanguage:
            NUserSettings.Application.transcriptionLanguage.get() ||
            defaultTranscriptionLanguage,
        microphoneTrackSettings: NUserSettings.WebRecording.microphoneAudio.get(),
        computerAudioTrackSettings: NUserSettings.WebRecording.computerAudio.get(),
        originalStartTime: new Date(),
        ...initialSession,
    };

    sessionMap.set(sessionId, session);

    return session;
}

/**
 * Updates an existing web recording session for a user.
 *
 * This function takes a user ID and an updates object as parameters. If the session for the given user ID exists,
 * it is updated with the information provided in the updates object. If not, an error is thrown.
 **/
export function updateWebRecordingSession(
    sessionId: string,
    updates: Partial<WebRecordingSession>,
) {
    const session = sessionMap.get(sessionId);
    if (session === undefined) {
        throw new DescriptError(
            'Could not update Web Recording Session. Session not found.',
            ErrorCategory.Recording,
        );
    }

    sessionMap.set(sessionId, {
        ...session,
        ...updates,
        recorders: session.recorders,
    });
}

/**
 * Retrieves a web recording session for a user by their ID.
 * If the user ID is not provided, an error will be thrown.
 **/
export function getWebRecordingSession(sessionId: string) {
    if (!sessionId) {
        throw new DescriptError('No sessionId provided', ErrorCategory.Recording);
    }
    return sessionMap.get(sessionId);
}

/**
 * It retrieves the corresponding web recording session for the user, then updates the properties of
 * the provided web recorder within that session with the supplied properties.
 *
 */
export function updateWebRecordingSessionRecorderMetadata(
    sessionId: string,
    recorderId: string,
    props: RecorderMetadata,
) {
    const session = getWebRecordingSession(sessionId);
    if (!session) {
        throw new DescriptError(
            `Could not update web recording session recorder metadata: no session found to update props.`,
            ErrorCategory.Recording,
        );
    }

    session.recorders.set(recorderId, {
        ...session.recorders.get(recorderId),
        ...props,
    });
}

export function getWebRecordingSessionsForBackendWorkflow(
    backendWorkflowId: string,
): WebRecordingSession[] {
    return Array.from(sessionMap.values()).filter(
        (session) => session.backendWorkflowId === backendWorkflowId,
    );
}
