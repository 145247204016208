import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function HiddenIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M12.5 8.375L14 10.625" stroke={color} strokeLinejoin="round" />
            <path d="M9.5 9.875L10.25 12.125" stroke={color} strokeLinejoin="round" />
            <path d="M6.5 9.875L5.75 12.125" stroke={color} strokeLinejoin="round" />
            <path d="M3.5 8.375L2 10.625" stroke={color} strokeLinejoin="round" />
            <path
                d="M2 6.875C3.05 8.19984 4.975 9.875 8 9.875C11.025 9.875 12.95 8.19984 14 6.875"
                stroke={color}
                strokeLinejoin="round"
            />
        </svg>
    );
}
