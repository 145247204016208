// Copyright 2023 Descript, Inc
import * as React from 'react';
import { ReactNull } from './ReactNull';
import { isDomNode } from './ReactEventHandlers';

/**
 * Detect whether an element is "stuck" in "position: sticky".
 *
 * @example
 *
 * const Example = () => {
 *   const stickyDetector = useStickyDetector();
 *
 *   return (
 *     <>
 *       {stickyDetector}
 *       <div style={{ position: 'sticky' }}>
 *         This element will get the data attribute "data-stuck" applied
 *         when sticky is active.
 *       </div>
 *     </>
 *   );
 * }
 */
export function useStickyDetector() {
    const ref = React.useRef<HTMLDivElement>(ReactNull);

    React.useEffect(() => {
        if (!ref.current) {
            return;
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const stickyHeader = isDomNode(entry.target.nextSibling)
                    ? entry.target.nextSibling
                    : undefined;
                stickyHeader?.toggleAttribute('data-stuck', !entry.isIntersecting);
            });
        });

        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref]);

    return <div ref={ref} />;
}
