// Copyright © 2024 Descript, Inc. All rights reserved.

import { RefObject, useLayoutEffect, useRef } from 'react';
import { fastDeepEqualJson } from '@descript/fast-deep-equal';

/**
 * Calls the onChangeRef callback (if available) when the value changes.
 *
 * Does not call onChangeRef with initial value or if onChangeRef changes.
 *
 * @param value the value to track for changes
 * @param onChangeRef a ref to a callback to call when the value changes
 * @param isEqual specify a comparison function (defaults to fastDeepEqualJson)
 */
export function useOnChange<T>(
    value: T,
    onChangeRef: RefObject<((newValue: T) => void) | undefined>,
    isEqual: (a: T, b: T) => boolean = fastDeepEqualJson,
) {
    const lastValue = useRef<T>(value);
    // When modifiers change, we need to notify the drag handler
    useLayoutEffect(() => {
        if (!isEqual(lastValue.current, value)) {
            onChangeRef.current?.(value);
        }
        lastValue.current = value;
    }, [isEqual, onChangeRef, value]);
}
