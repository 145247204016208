import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function GearIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_1_393)">
                <path
                    d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.73517 0.633436C6.74361 0.557471 6.80782 0.5 6.88426 0.5H9.11574C9.19218 0.5 9.25639 0.557471 9.26483 0.633436L9.45552 2.34972C9.99275 2.48771 10.5 2.70029 10.9657 2.97577L12.3146 1.8967C12.3743 1.84895 12.4603 1.85372 12.5143 1.90776L14.0922 3.48566C14.1463 3.53971 14.1511 3.62575 14.1033 3.68543L13.0242 5.03427C13.2997 5.49996 13.5123 6.00726 13.6503 6.54448L15.3666 6.73517C15.4425 6.74361 15.5 6.80782 15.5 6.88426V9.11574C15.5 9.19218 15.4425 9.25639 15.3666 9.26483L13.6503 9.45552C13.5123 9.99275 13.2997 10.5 13.0242 10.9657L14.1033 12.3146C14.1511 12.3743 14.1463 12.4603 14.0922 12.5143L12.5143 14.0922C12.4603 14.1463 12.3743 14.1511 12.3146 14.1033L10.9657 13.0242C10.5 13.2997 9.99274 13.5123 9.45552 13.6503L9.26483 15.3666C9.25639 15.4425 9.19218 15.5 9.11574 15.5H6.88426C6.80782 15.5 6.74361 15.4425 6.73517 15.3666L6.54448 13.6503C6.00725 13.5123 5.49995 13.2997 5.03426 13.0242L3.68542 14.1033C3.62574 14.151 3.5397 14.1463 3.48565 14.0922L1.90775 12.5143C1.85371 12.4603 1.84894 12.3743 1.89669 12.3146L2.97576 10.9657C2.70028 10.5 2.4877 9.99274 2.34972 9.45552L0.633435 9.26483C0.55747 9.25639 0.5 9.19218 0.5 9.11574V6.88426C0.5 6.80782 0.557471 6.74361 0.633436 6.73517L2.34972 6.54448C2.4877 6.00725 2.70029 5.49995 2.97577 5.03426L1.8967 3.68542C1.84895 3.62574 1.85372 3.5397 1.90776 3.48565L3.48566 1.90775C3.53971 1.85371 3.62575 1.84894 3.68543 1.89669L5.03427 2.97576C5.49996 2.70028 6.00726 2.4877 6.54448 2.34972L6.73517 0.633436Z"
                    stroke={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_1_393">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
