// Copyright 2023 Descript, Inc
export class Timer {
    public static readonly singletonTimer: Timer = new Timer();
    private startTime: number | undefined;
    private pausedTime: number | undefined;

    getElapsedTime() {
        if (!this.startTime) {
            return 0;
        }

        return this.pausedTime ? this.pausedTime - this.startTime : Date.now() - this.startTime;
    }

    MM_SS() {
        const elapsedTime = this.getElapsedTime();
        const minutes = Math.floor(elapsedTime / 60000);
        const seconds = Math.floor((elapsedTime % 60000) / 1000);
        return `${+minutes < 10 ? '0' : ''}${minutes}:${+seconds < 10 ? '0' : ''}${seconds}`;
    }

    SS() {
        const elapsedTime = this.getElapsedTime();
        const seconds = Math.floor((elapsedTime % 60000) / 1000);
        return seconds;
    }

    start() {
        if (this.startTime) {
            return;
        }
        this.startTime = Date.now();
    }

    pause() {
        if (this.pausedTime) {
            return;
        }
        this.pausedTime = Date.now();
    }

    resume() {
        if (!this.pausedTime) {
            return;
        }
        this.startTime = Date.now() - this.getElapsedTime();
        this.pausedTime = undefined;
    }

    stop(): void {
        this.startTime = undefined;
        this.pausedTime = undefined;
    }
}
