// Copyright 2024 Descript, Inc

import { RecorderError } from '../../Storage/QuickRecordingSession';
import { RecordingPhase, recordingPhaseToFormattedString } from './errorHelpers';
import { ErrorCategory, DescriptError } from '@descript/errors';

export class VideoRecorderError extends Error {
    category = ErrorCategory.Recording;
    public static isVideoRecorderError(error: unknown): error is VideoRecorderError {
        return error instanceof VideoRecorderError;
    }

    public readonly errorMessage: string;
    public readonly recordingPhase: RecordingPhase;
    public readonly innerError: Error | undefined;
    public readonly errorObject: Error | RecorderError | undefined;

    public constructor(
        recordingPhase: RecordingPhase,
        error: Error | RecorderError | undefined,
    ) {
        let errorMessage: string;
        let innerError: Error | undefined;
        if (error === undefined) {
            errorMessage = 'Unknown error';
            innerError = undefined;
        } else if (error instanceof Error) {
            errorMessage = error.message;
            innerError = error;
        } else {
            errorMessage = error.name;
            innerError = new DescriptError(error.name, ErrorCategory.AppArchitecture);
        }

        super(
            `Video recorder native process encountered an error ${recordingPhaseToFormattedString(
                recordingPhase,
            )}: ${errorMessage}`,
        );

        this.recordingPhase = recordingPhase;
        this.innerError = innerError;
        this.errorMessage = errorMessage;
        this.errorObject = error;
    }
}
