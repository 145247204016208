// Copyright 2024 Descript, Inc
import { DailyAdvancedConfig, DailyCallOptions } from '@daily-co/daily-js';
import { DailyProvider } from '@daily-co/daily-react';
import { ReactNull } from '@descript/react-utils';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import * as React from 'react';

import { useShowLeaveButton } from '@lib/state/custom';

import { CallConfigProvider } from './CallConfigProvider';
import { CallMode, CallState, useCallMachine } from './useCallMachine';
import { SpeechPipelineLanguage } from '@descript/client';

interface Props {
    customHost?: string;
    domain: string;
    room: string;
    children: React.ReactNode;
    token?: string;
    isEmbedded: boolean;
    bypassRegionDetection: boolean;
    v2CamAndMic: boolean | number | null;
    useLegacyVideoProcessor: boolean;
    disableRecordingIndicator: boolean | null;
    roomsCheckOrigin?: string;
    apiHost?: string;
    micAudioMode?: DailyAdvancedConfig['micAudioMode'];
    projectId: string;
    driveId: string;
}

interface ContextValue {
    disableRecordingIndicator: boolean;
    disableAudio: boolean;
    enableCallButtons: boolean;
    initializeCallArgs(options: DailyCallOptions);
    leaveCall(): void;
    mode: CallMode;
    preJoinNonAuthorized: boolean;
    reloadCall(): void;
    setRedirectOnLeaveCall(redirect: boolean): void;
    state: CallState;
    room: string;
    projectId: string;
    driveId: string;
}

export const CallContext = createContext<ContextValue>(ReactNull);

export interface CallSessionData {
    cs: {
        projectId: string;
        delegateToken: string;
        transcriptionLanguage: SpeechPipelineLanguage;
    };
}

export function CallProvider({
    apiHost = ReactNull,
    bypassRegionDetection = false,
    children,
    customHost,
    disableRecordingIndicator = false,
    domain,
    driveId,
    isEmbedded,
    micAudioMode = 'speech',
    projectId,
    room,
    roomsCheckOrigin = ReactNull,
    token = '',
    useLegacyVideoProcessor,
    v2CamAndMic,
}: React.PropsWithChildren<Props>) {
    const [preJoinNonAuthorized, setPreJoinNonAuthorized] = useState(false);
    const { daily, disableAudio, initializeCallArgs, leave, mode, setRedirectOnLeave, state } =
        useCallMachine({
            domain,
            room,
            token,
            customHost,
            isEmbedded,
            bypassRegionDetection,
            v2CamAndMic,
            useLegacyVideoProcessor,
            roomsCheckOrigin,
            apiHost,
            micAudioMode,
        });

    /**
     * Only enable the call buttons (camera toggle, leave call, etc.) if we're joined
     * or if we've errored out.
     *
     * !!!
     * IMPORTANT: calling callObject.destroy() *before* we get the "joined-meeting"
     * can result in unexpected behavior. Disabling the leave call button
     * until then avoids this scenario.
     * !!!
     */
    const enableCallButtons = ['joined', 'error'].includes(state);

    const reloadCall = useCallback(() => {
        const url = new URL(window.location.href);
        if (token) {
            url.searchParams.set('t', token);
        }
        if (projectId) {
            url.searchParams.set('projectId', projectId);
        }
        location.href = url.toString();
    }, [token, projectId]);

    useEffect(() => {
        if (!daily) {
            return;
        }
        const { access } = daily.accessState();
        if (access === 'unknown') {
            return;
        }
        const requiresPermission = access?.level === 'lobby';
        setPreJoinNonAuthorized(requiresPermission && !token);
    }, [state, daily, token]);

    const [, setShowLeaveButton] = useShowLeaveButton();
    useEffect(() => {
        setShowLeaveButton(mode === 'direct-link');
    }, [mode, setShowLeaveButton]);

    return (
        <CallContext.Provider
            value={{
                disableAudio,
                disableRecordingIndicator,
                enableCallButtons,
                initializeCallArgs,
                leaveCall: leave,
                mode,
                preJoinNonAuthorized,
                reloadCall,
                setRedirectOnLeaveCall: setRedirectOnLeave,
                state,
                room,
                projectId,
                driveId,
            }}
        >
            <DailyProvider callObject={daily} recoilRootProps={{ override: false }}>
                <CallConfigProvider>{children}</CallConfigProvider>
            </DailyProvider>
        </CallContext.Provider>
    );
}

export const useCallState = () => useContext(CallContext);
