import * as React from 'react';

import { useTheme } from '../../contexts/Theme';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function TerminalIcon({
    fill = 'var(--nav-icon-fill)',
    stroke = 'var(--nav-icon-stroke)',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    const { colors } = useTheme();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
            <rect x="0.5" y="2.5" width="15" height="11" rx="3.5" />
            <path d="M10 10L12 8L10 6" strokeLinecap="round" strokeLinejoin="round" />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    fill: ${fill ?? colors.system.grey};
                    height: ${size}px;
                    stroke: ${stroke ?? colors.system.greyDark};
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
