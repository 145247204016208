// Copyright 2024 Descript, Inc

export const autoDetectLanguage = 'auto';
export const defaultTranscriptionLanguage = 'en';
export const unsupportedTranscriptionLanguage = 'unknown';
export const nonDefaultTranscriptionLanguages = [
    'ca',
    'cs',
    'da',
    'de',
    'el',
    'es',
    'fi',
    'fr',
    'hi',
    'hr',
    'hu',
    'it',
    'lv',
    'lt',
    'ms',
    'no',
    'nl',
    'pl',
    'pt',
    'ro',
    'sk',
    'sl',
    'sv',
    'tr',
] as const;

export const transcriptionLanguages = [
    autoDetectLanguage,
    defaultTranscriptionLanguage,
    unsupportedTranscriptionLanguage,
    ...nonDefaultTranscriptionLanguages,
] as const;
export type TranscriptionLanguage = (typeof transcriptionLanguages)[number];

export function isTranscriptionLanguage(language: string): language is TranscriptionLanguage {
    return (
        transcriptionLanguages.find(
            (transcriptionLanguage) => transcriptionLanguage === language,
        ) !== undefined
    );
}
