// Copyright 2020 Descript, Inc

import * as ApiTarget from './ApiTarget';
import { newId, RevisionAssets, TimedWordArray, Word } from '@descript/descript-model';
import { AppConstants } from '../App/Constants';
import { makeAWSFileURL } from './ProjectClient';
import * as ApiClient from './ApiClient';
import { AsyncContext } from '@descript/analytics';
import { PublishedProjectAccessPermission } from './PublishedProjectJson';
import { getTranscriptionLanguageFromNUserSettings } from '../Utilities/SupportedLanguages';
import { RecordingTarget } from '@descript/recorder-base';

type UnsignedAsset = {
    url: string;
};

type SignedAsset = UnsignedAsset & {
    cdn_url: string;
};

export type LiveTranscriptionResult =
    | {
          type: 'done';
          /**
           * One TimedWordArray for each channel
           */
          perChannelAlignments: readonly TimedWordArray<Word>[];
          formattedText: string;
      }
    | { type: 'cancelled' };

export type RecordingAssetJson = {
    document: UnsignedAsset;
    subtitles: UnsignedAsset;
    transcript: UnsignedAsset;
    media: {
        stream: {
            playlist: UnsignedAsset;
            chunks: UnsignedAsset[];
        };
        original: UnsignedAsset;
        thumbnail: UnsignedAsset;
        transcription: UnsignedAsset | undefined;
    };
};

export type SignedRecordingAssetJson = {
    document: SignedAsset;
    subtitles: SignedAsset;
    transcript: SignedAsset;
    media: {
        stream: {
            playlist: SignedAsset;
            chunks: SignedAsset[];
        };
        original: SignedAsset;
        thumbnail: SignedAsset;
        transcription: SignedAsset | undefined;
    };
};

export enum RecordingWorkflowStatus {
    Running = 'running',
    Succeeded = 'succeeded',
    Failed = 'failed',
    Unknown = 'unknown',
}

type WorkflowData = {
    startTime: string;
    status: RecordingWorkflowStatus;
    type: string;
    workflowId: string;
};

export function generateRecordingAssetUrl(
    projectId: string,
    filename: string,
): { url: string } {
    return {
        url: makeAWSFileURL(projectId, filename),
    };
}

export function generateRecordingAssetJson(
    projectId: string,
    includeTranscriptionAsset: boolean,
): RecordingAssetJson {
    const streamAssetKey = newId();
    const streamUrl = generateRecordingAssetUrl(
        projectId,
        RevisionAssets.generateStreamingMediaFilename(streamAssetKey + '.m3u8'),
    );

    const originalFileType = '.mp4';
    const originalFilename = RevisionAssets.generateOriginalMediaFilename(
        projectId + originalFileType,
    );
    const originalUrls = generateRecordingAssetUrl(projectId, originalFilename);
    const thumbnailFilename = RevisionAssets.generateStreamingMediaFilename(
        streamAssetKey + AppConstants.Publish.thumbnailExtension,
    );

    return {
        document: generateRecordingAssetUrl(
            projectId,
            RevisionAssets.generateContentsFilename(),
        ),
        subtitles: generateRecordingAssetUrl(
            projectId,
            RevisionAssets.generateSubtitlesFilename(),
        ),
        transcript: generateRecordingAssetUrl(
            projectId,
            RevisionAssets.generateTranscriptFilename(),
        ),
        media: {
            stream: {
                playlist: streamUrl,
                // The chunks are unknown at creation time
                chunks: [],
            },
            original: originalUrls,
            thumbnail: generateRecordingAssetUrl(projectId, thumbnailFilename),
            transcription: includeTranscriptionAsset
                ? generateRecordingAssetUrl(
                      projectId,
                      RevisionAssets.generateAudioForTranscriptFilename(),
                  )
                : undefined,
        },
    };
}

const recordingBucketHosts = new Set([
    'descript-recordings.s3.amazonaws.com',
    'descript-recordings-testing.s3.amazonaws.com',
]);

/**
 * Returns true if urlString is in the recordings bucket (and therefore is a
 * recording asset), false if not
 * @param urlString
 */
export function isAssetUrlInRecordingsBucket(urlString: string): boolean {
    const url = new URL(urlString);
    return recordingBucketHosts.has(url.host);
}

export function isRecordingBucket(bucketName: string): boolean {
    return bucketName === ApiTarget.awsRecordingBucket();
}

type StartQuickRecordingWorkflowResponse = {
    sessionId: string;
    publishedProjectId: string;
    urlSlug: string;
};

interface StartRecordingResponse {
    sessionId: string;
    startTime?: string;
    projectId?: string;
}

interface GetGuestDelegateTokenResponse {
    access_token: string;
}

export type Progress = {
    totalSteps: number;
    completedSteps: number;
    runningSteps: Array<{
        code: string;
        displayName: string;
        progress: number;
    }>;
    overallProgress: number;
};

export type QueryRecordingWorkflowResponse = {
    status: RecordingWorkflowStatus;
    progress: Progress;
};

export type QuerySyncResponse = {
    date: Date;
};

// request spec: https://github.com/descriptinc/descript-api/blob/main/services/api/source/controllers/recordings/schema.ts#L10
export async function startQuickRecordingWorkflow(
    ctx: AsyncContext,
    {
        projectId,
        assetId,
        artifactId,
        displayName,
        sharePageAccessPermission,
        sharePageTranscriptEnabled,
        commentsEnabled,
        displayAuthor,
        allowEmbeddedSharing,
        runTranscription,
        runStudioSound,
        publishUrlSlug,
        expectedFileExtension,
    }: {
        projectId: string;
        assetId: string;
        artifactId: string;
        displayName: string;
        sharePageAccessPermission: PublishedProjectAccessPermission;
        sharePageTranscriptEnabled?: boolean;
        commentsEnabled?: boolean;
        displayAuthor?: boolean;
        allowEmbeddedSharing?: boolean;
        runTranscription?: boolean;
        runStudioSound?: boolean;
        publishUrlSlug?: string;
        expectedFileExtension?: string;
    },
): Promise<StartQuickRecordingWorkflowResponse> {
    return await ApiClient.request<StartQuickRecordingWorkflowResponse>(
        ctx,
        ApiClient.RequestType.POST,
        '/recordings/quick/start',
        undefined,
        {
            projectId,
            assetId,
            artifactId,
            displayName,
            sharePageAccessPermission,
            sharePageTranscriptEnabled,
            commentsEnabled,
            displayAuthor,
            allowEmbeddedSharing,
            runTranscription,
            runStudioSound,
            language: getTranscriptionLanguageFromNUserSettings(),
            publishUrlSlug,
            expectedFileExtension,
        },
    );
}

export enum SpeechPipelineLanguage {
    ca = 'ca',
    cs = 'cs',
    da = 'da',
    de = 'de',
    el = 'el',
    en = 'en',
    es = 'es',
    fi = 'fi',
    fr = 'fr',
    hi = 'hi',
    hr = 'hr',
    hu = 'hu',
    it = 'it',
    lv = 'lv',
    lt = 'lt',
    ms = 'ms',
    no = 'no',
    nl = 'nl',
    pl = 'pl',
    pt = 'pt',
    ro = 'ro',
    sk = 'sk',
    sl = 'sl',
    sv = 'sv',
    tr = 'tr',
}

interface StartWebRecordingPayload {
    projectId: string;
    tracks: RecorderTrack[];
    runTranscription?: boolean;
    runStudioSound?: boolean;
    baseDocumentRef?: string;
    baseDocumentCommitIndex?: number;
    recovery?: boolean;
    fastAdd?: boolean;
}

interface StartCollaborativeWebRecordingPayloadCommon {
    recordingType?: 'cloud' | 'raw-tracks';
    recordingStartTime?: Date;
}

interface StartCollaborativeWebRecordingPayloadWithDriveId
    extends Omit<StartWebRecordingPayload, 'projectId'>,
        StartCollaborativeWebRecordingPayloadCommon {
    driveId: string;
}

interface StartCollaborativeWebRecordingPayloadWithProjectId
    extends StartWebRecordingPayload,
        StartCollaborativeWebRecordingPayloadCommon {}

type StartCollaborativeWebRecordingPayload =
    | StartCollaborativeWebRecordingPayloadWithDriveId
    | StartCollaborativeWebRecordingPayloadWithProjectId;

interface SignalStopWebRecordingPayload {
    recordingTarget?: RecordingTarget;
    documentCommitIndex?: number;
}

export enum RecorderVideoTrackRole {
    Camera = 'camera',
    Screen = 'screen',
}

/** A media file produced by the recorder */
interface RecorderTrack {
    /** Some for of human-readable description of the contents of the this track (say, "screen" or "mic") */
    displayName: string;

    /** The asset id of the artifact where the media will be uploaded  */
    assetId: string;

    /** The artifact id of the artifact where the media will be uploaded */
    artifactId: string;

    /** Offset in seconds (fractional), of the beginning of this track relative
     * to the beginning of the recording
     * @default 0 */
    offset?: number;

    /** Whether this track includes video */
    hasAudio: boolean;

    /** Whether this track includes audio */
    hasVideo: boolean;

    /** Language of the audio for transcription
     * @default SpeechPipelineLanguage.en */
    language?: SpeechPipelineLanguage;

    /** Expected extension of the resulting file */
    expectedFileExtension?: string;

    videoRole?: RecorderVideoTrackRole;
}
export async function startWebRecordingWorkflow(
    ctx: AsyncContext,
    payload: StartWebRecordingPayload,
): Promise<StartRecordingResponse> {
    return await ApiClient.request<StartRecordingResponse>(
        ctx,
        ApiClient.RequestType.POST,
        '/recordings/web/start',
        undefined,
        payload,
    );
}

export async function startCollaborativeRecordingWorkflow(
    ctx: AsyncContext,
    payload: StartCollaborativeWebRecordingPayload,
    delegateToken?: string,
): Promise<StartRecordingResponse> {
    return await ApiClient.request<StartRecordingResponse>(
        ctx,
        ApiClient.RequestType.POST,
        `/recordings/collaborative/${delegateToken ? 'guest/' : ''}start`,
        undefined,
        payload,
        undefined,
        undefined,
        undefined,
        undefined,
        delegateToken,
    );
}

export async function requestGuestDelegateToken(ctx: AsyncContext, projectId: string) {
    return await ApiClient.request<GetGuestDelegateTokenResponse>(
        ctx,
        ApiClient.RequestType.POST,
        `/projects/${projectId}/write_access_token`,
        undefined,
        undefined,
    );
}

export async function signalWorkflowWebRecordingStopped(
    ctx: AsyncContext,
    backendWorkflowId: string,
    payload: SignalStopWebRecordingPayload,
): Promise<void> {
    await ApiClient.request(
        ctx,
        ApiClient.RequestType.POST,
        `/recordings/web/${backendWorkflowId}/stopped`,
        undefined,
        payload,
    );
}

export async function signalWorkflowCollabRecordingStopped(
    ctx: AsyncContext,
    backendWorkflowId: string,
    payload: SignalStopWebRecordingPayload,
    delegateToken?: string,
): Promise<void> {
    await ApiClient.request(
        ctx,
        ApiClient.RequestType.POST,
        `/recordings/collaborative/${delegateToken ? 'guest/' : ''}${backendWorkflowId}/stopped`,
        undefined,
        payload,
        undefined,
        undefined,
        undefined,
        undefined,
        delegateToken,
    );
}

// request spec: https://github.com/descriptinc/descript-api/blob/main/services/api/source/controllers/recordings/schema.ts#L72
export async function signalWorkflowRecordingStopped(
    ctx: AsyncContext,
    quickRecordingBackendSessionId: string | undefined,
) {
    await ApiClient.request(
        ctx,
        ApiClient.RequestType.POST,
        `/recordings/quick/${quickRecordingBackendSessionId}/stopped`,
        undefined,
        undefined,
    );
}

export async function signalWorkflowMetadataAvailable(
    ctx: AsyncContext,
    quickRecordingBackendSessionId: string | undefined,
) {
    await ApiClient.request(
        ctx,
        ApiClient.RequestType.POST,
        `/recordings/quick/${quickRecordingBackendSessionId}/metadata`,
        undefined,
        undefined,
    );
}

export async function uploadTranscriptionSuccess(
    ctx: AsyncContext,
    quickRecordingBackendSessionId: string | undefined,
    transcriptionResults: LiveTranscriptionResult,
) {
    await ApiClient.request(
        ctx,
        ApiClient.RequestType.POST,
        `/recordings/quick/${quickRecordingBackendSessionId}/transcription/success`,
        undefined,
        transcriptionResults,
        undefined,
        {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/octet-stream',
        },
    );
}

export async function uploadTranscriptionFailure(
    ctx: AsyncContext,
    quickRecordingBackendSessionId: string | undefined,
) {
    await ApiClient.request(
        ctx,
        ApiClient.RequestType.POST,
        `/recordings/quick/${quickRecordingBackendSessionId}/transcription/failure`,
    );
}

export async function queryQuickRecordingWorkflow(
    ctx: AsyncContext,
    quickRecordingBackendSessionId: string | undefined,
): Promise<QueryRecordingWorkflowResponse> {
    return await ApiClient.request<QueryRecordingWorkflowResponse>(
        ctx,
        ApiClient.RequestType.GET,
        `/recordings/quick/${quickRecordingBackendSessionId}`,
    );
}

export async function queryRecordingWorkflow(
    ctx: AsyncContext,
    workflowId: string,
): Promise<QueryRecordingWorkflowResponse> {
    return await ApiClient.request<QueryRecordingWorkflowResponse>(
        ctx,
        ApiClient.RequestType.GET,
        `/recordings/web/${workflowId}`,
    );
}

export async function queryCollaborativeWorkflowsForProject(
    ctx: AsyncContext,
    projectId: string,
): Promise<{ workflows: WorkflowData[] }> {
    return await ApiClient.request<{ workflows: WorkflowData[] }>(
        ctx,
        ApiClient.RequestType.GET,
        `/recordings/collaborative/${projectId}/workflows`,
    );
}

export async function queryCollaborativeRecordingWorkflow(
    ctx: AsyncContext,
    workflowId: string,
    delegateToken?: string,
): Promise<QueryRecordingWorkflowResponse> {
    return await ApiClient.request<QueryRecordingWorkflowResponse>(
        ctx,
        ApiClient.RequestType.GET,
        `/recordings/collaborative/${delegateToken ? 'guest/' : ''}${workflowId}`,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        delegateToken,
    );
}

export async function queryTimeSync(
    ctx: AsyncContext,
    projectId: string,
    delegateToken?: string,
): Promise<QuerySyncResponse> {
    return await ApiClient.request<QuerySyncResponse>(
        ctx,
        ApiClient.RequestType.GET,
        `/recordings/collaborative/${delegateToken ? 'guest/' : ''}${projectId}/sync`,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        delegateToken,
    );
}

export async function signalAddTrack(
    ctx: AsyncContext,
    sessionId: string,
    track: RecorderTrack,
    delegateToken?: string,
): Promise<void> {
    return await ApiClient.request<void>(
        ctx,
        ApiClient.RequestType.POST,
        `/recordings/collaborative/${delegateToken ? 'guest/' : ''}${sessionId}/track`,
        undefined,
        track,
        undefined,
        undefined,
        undefined,
        undefined,
        delegateToken,
    );
}

export async function cancelRunningWorkflow(
    ctx: AsyncContext,
    sessionId: string,
): Promise<void> {
    return await ApiClient.request<void>(
        ctx,
        ApiClient.RequestType.POST,
        `/recordings/collaborative/${sessionId}/cancel-wait`,
        undefined,
    );
}
