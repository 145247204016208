import { DescriptError, ErrorCategory } from '@descript/errors';

// Copyright 2022 Descript, Inc
/**
 * Type assertion that this function can never be called
 *
 * Use to assert (via the Typescript compiler) that the control
 * flow can never reach this point. Useful for things like
 * exhaustiveness checking for enums.
 *
 * ```typescript
 * enum MyEnum {
 *   a,
 *   b,
 * }
 *
 * const x: MyEnum;
 * switch (x: MyEnum) {
 *     case MyEnum.a:
 *         break;
 *     case MyEnum.b:
 *         break;
 *     default:
 *         staticAssertNever(x);
 * }
 * ```
 *
 * Unfortunately this doesn't get compiled away, but if it should
 * never be called, the overhead should be minimal.
 */
export function staticAssertNever(x: never): never {
    throw new DescriptError(
        `Type inference error: unreachable code received ${safeJSONify(x)}`,
        ErrorCategory.DescriptModel,
    );
}

/**
 * Throws a typecheck error if `x` is not of type `T`.
 *
 * Use like this:
 *
 * ```typescript
 * staticAssert<number>(1); // ok
 * staticAssert<string>(1); // error
 * ```
 *
 * This is especially useful for asserting that a function implements a certain interface:
 *
 * ```typescript
 * interface BarGetter {
 *   (): string;
 * }
 * function getBar(): string {
 *   return 'bar';
 * }
 * staticAssert<BarGetter>(getBar);
 * ```
 */
export function staticAssert<T = never>(x: T): T {
    return x;
}

function safeJSONify(x: unknown): string {
    try {
        return JSON.stringify(x);
    } catch (err) {
        return String(x);
    }
}

type NotNullish<T> = Exclude<T, null | undefined>;
export function isNotNullish<T>(x: T): x is NotNullish<T> {
    // eslint-disable-next-line no-null/no-null
    return !(x === undefined || x === null);
}

/**
 * Throw an exception if value is null or undefined
 *
 * This is probably dangerous to use in most places, but
 * fairly useful to use in tests to reduce the need for
 * `!` assertions everywhere.
 */
export function assertNotNullish<T>(x: T): NotNullish<T> {
    // eslint-disable-next-line no-null/no-null
    if (isNotNullish(x)) {
        return x;
    } else {
        throw new DescriptError('Value is nullish', ErrorCategory.DescriptModel);
    }
}

/**
 * A type-narrowing version of Object.hasOwnProperty
 *
 * It's named `hasOwnPropertyTyped` so as not to collide
 * with the name `hasOwnProperty`, which every object has
 */
export function hasOwnPropertyTyped<T, K extends string>(
    x: T,
    property: K,
): x is Extract<T, { [P in K]?: unknown }> {
    if (Object.prototype.hasOwnProperty.call(x, property)) {
        return true;
    } else {
        return false;
    }
}

export function getMaybeProperty<T, K extends string>(
    x: T,
    property: K,
): Extract<T, { [P in K]?: unknown }>[K] | undefined {
    if (hasOwnPropertyTyped(x, property)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (x as any)[property] as Extract<T, { [P in K]?: unknown }>[K];
    } else {
        return undefined;
    }
}

/**
 * "Annotation" newtype that declares a list of keys has been checked for exhaustiveness
 *
 * Never generate any values of this type directly or through typecasting.
 * Use `getExhaustiveKeys<MyInterface>(keyRecord(listOfKeys))`
 *
 * Annotation a type as `ExhaustiveKeys<keyof T>` to require people to have run
 * their list of keys through these methods.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export type ExhaustiveKeys<T> = T & { ___typestamp: 'exhaustive-keys' };

type KeyRecord<T> = T & { ___typestamp: 'key-record' };

/**
 * For typechecking keys
 *
 * Use as follows: `const methods = getExhaustiveKeys<MyInterface>(keyRecord(['method1', 'method2']))`
 * This will be a type error if the array passed in is not exhaustive and matching the
 * keys of `MyInterface`.
 */
export function keyRecord<K extends string>(keys: readonly K[]): KeyRecord<Record<K, true>> {
    return keys.reduce(
        (obj, key) => {
            obj[key] = true;
            return obj;
        },
        {} as Record<K, true>,
    ) as KeyRecord<Record<K, true>>;
}

export function getExhaustiveKeys<T>(
    r: KeyRecord<Record<keyof T, unknown>>,
): ExhaustiveKeys<readonly (keyof T)[]> {
    return Object.keys(r) as ExhaustiveKeys<(keyof T)[]>;
}

/**
 * Check that the given value is one of a list of acceptable values. Returns fallback otherwise
 *
 * @param value This is the value to check
 * @param validValues This is the list of acceptable values
 * @param fallback The value to use if `value` is not one of `validValues`
 */
export function ensureIsOneOf<T>(value: unknown, validValues: readonly T[], fallback: T): T {
    if (validValues.indexOf(value as T) !== -1) {
        return value as T;
    } else {
        return fallback;
    }
}
