import * as React from 'react';

import { useTheme } from '../../contexts/Theme';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function DocumentIcon({
    fill = 'var(--nav-icon-fill)',
    stroke = 'var(--nav-icon-stroke)',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    const { colors } = useTheme();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
            <path
                d="M11 1.5H5C3.61929 1.5 2.5 2.61929 2.5 4V12C2.5 13.3807 3.61929 14.5 5 14.5H9.79289C10.0581 14.5 10.3125 14.3946 10.5 14.2071L13.2071 11.5C13.3946 11.3125 13.5 11.0581 13.5 10.7929V4C13.5 2.61929 12.3807 1.5 11 1.5Z"
                fill={fill ?? colors.system.grey}
                stroke={stroke ?? colors.system.greyDark}
            />
            <path
                d="M5 4.5C5 4.22386 5.22386 4 5.5 4H10.5C10.7761 4 11 4.22386 11 4.5C11 4.77614 10.7761 5 10.5 5H5.5C5.22386 5 5 4.77614 5 4.5Z"
                fill={stroke ?? colors.system.greyDark}
            />
            <path
                d="M9 11.5C9 10.6716 9.67157 10 10.5 10H13V11H10.5C10.2239 11 10 11.2239 10 11.5V14H9V11.5Z"
                fill={stroke ?? colors.system.greyDark}
            />
            <path
                d="M5.5 6C5.22386 6 5 6.22386 5 6.5C5 6.77614 5.22386 7 5.5 7H8.5C8.77614 7 9 6.77614 9 6.5C9 6.22386 8.77614 6 8.5 6H5.5Z"
                fill={stroke ?? colors.system.greyDark}
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
