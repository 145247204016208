import * as React from 'react';

import { useTheme } from '../../contexts/Theme';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function CloudIcon({
    fill = 'var(--nav-icon-fill)',
    stroke = 'var(--nav-icon-stroke)',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    const { colors } = useTheme();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
            <mask
                id="path-1-outside-1"
                maskUnits="userSpaceOnUse"
                x="0"
                y="2"
                width="16"
                height="12"
                fill="black"
            >
                <rect fill="white" y="2" width="16" height="12" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1 9.5C1 11.433 2.567 13 4.5 13H12.5C13.8807 13 15 11.8807 15 10.5C15 9.17329 13.9666 8.08796 12.6608 8.00509C12.8783 7.54917 13 7.0388 13 6.5C13 4.567 11.433 3 9.5 3C7.62111 3 6.08802 4.4805 6.00366 6.33856C5.5481 6.12149 5.03824 6 4.5 6C2.567 6 1 7.567 1 9.5Z"
                />
            </mask>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 9.5C1 11.433 2.567 13 4.5 13H12.5C13.8807 13 15 11.8807 15 10.5C15 9.17329 13.9666 8.08796 12.6608 8.00509C12.8783 7.54917 13 7.0388 13 6.5C13 4.567 11.433 3 9.5 3C7.62111 3 6.08802 4.4805 6.00366 6.33856C5.5481 6.12149 5.03824 6 4.5 6C2.567 6 1 7.567 1 9.5Z"
            />
            <path
                d="M12.6608 8.00509L11.7582 7.57453L11.1214 8.9094L12.5974 9.00308L12.6608 8.00509ZM6.00366 6.33856L5.57352 7.24132L6.93426 7.88968L7.00263 6.38391L6.00366 6.33856ZM4.5 12C3.11929 12 2 10.8807 2 9.5H0C0 11.9853 2.01472 14 4.5 14V12ZM12.5 12H4.5V14H12.5V12ZM14 10.5C14 11.3284 13.3284 12 12.5 12V14C14.433 14 16 12.433 16 10.5H14ZM12.5974 9.00308C13.38 9.05274 14 9.70435 14 10.5H16C16 8.64223 14.5532 7.12317 12.7241 7.00709L12.5974 9.00308ZM12 6.5C12 6.88668 11.9129 7.25022 11.7582 7.57453L13.5633 8.43564C13.8436 7.84811 14 7.19092 14 6.5H12ZM9.5 4C10.8807 4 12 5.11929 12 6.5H14C14 4.01472 11.9853 2 9.5 2V4ZM7.00263 6.38391C7.06285 5.05751 8.15821 4 9.5 4V2C7.08402 2 5.11318 3.90349 5.00469 6.2932L7.00263 6.38391ZM4.5 7C4.88628 7 5.24946 7.08692 5.57352 7.24132L6.4338 5.43579C5.84674 5.15607 5.1902 5 4.5 5V7ZM2 9.5C2 8.11929 3.11929 7 4.5 7V5C2.01472 5 0 7.01472 0 9.5H2Z"
                fill={stroke ?? colors.system.greyDark}
                mask="url(#path-1-outside-1)"
            />
            <path
                d="M7.32867 13C6.5558 13.6254 5.57165 14 4.5 14C2.01472 14 0 11.9853 0 9.5C0 7.01472 2.01472 5 4.5 5C5.75895 5 6.89864 5.5183 7.71424 6.35061C8.14588 6.7911 7.4095 7.45077 6.94949 7C6.31822 6.38141 5.45365 6 4.5 6C2.567 6 1 7.567 1 9.5C1 11.433 2.567 13 4.5 13H7.32867Z"
                fill={stroke ?? colors.system.greyDark}
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    fill: ${fill ?? colors.system.grey};
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
