// Copyright 2024 Descript, Inc

import { namespacedCommandFactory } from '@descript/descript-core';
import { workerRequestCreatorFactory } from '@descript/web-workers';
import {
    ArtifactRecoveryInfo,
    MoveRecoveryTempDirectoryInput,
    SaveRecoverySegmentInput,
} from '@descript/recorder-base';
import { ListDirectoryContentsResultFile } from '@descript/web-utils';
import { LibavProgress } from '@descript/libav-utils';
import { RecoveryJsons } from './types';

const createCommand = namespacedCommandFactory('WEB_RECORDER_RECOVERY')(
    workerRequestCreatorFactory,
);

export const ListRecoveryFiles = createCommand<
    string | undefined,
    { files: ListDirectoryContentsResultFile[]; directories: string[] }
>('LIST_RECOVERY_FILES');

export const SaveRecoveryFile = createCommand<SaveRecoverySegmentInput, void>(
    'SAVE_RECOVERY_SEGMENT',
);

export const MoveTempDirectory = createCommand<MoveRecoveryTempDirectoryInput, void>(
    'MOVE_TEMP_DIRECTORY',
);

export const ReadJson = createCommand<{ artifactId: string; filename: RecoveryJsons }, unknown>(
    'READ_JSON',
);

export const ReadRecoveryJson = createCommand<string, ArtifactRecoveryInfo | undefined>(
    'READ_RECOVERY_JSON',
);

export const DeleteRecoveryJson = createCommand<
    { artifactId: string; segmentNumber: number },
    void
>('DELETE_RECOVERY_JSON');

export const DeleteArtifactDirectory = createCommand<string, void>('DELETE_ARTIFACT_DIRECTORY');

export const GetRecoverySegment = createCommand<
    { artifactId: string; filename: string },
    ArrayBuffer
>('GET_RECOVERY_SEGMENT');

export const StitchSegments = createCommand<{ artifactId: string }, Blob, LibavProgress>(
    'STITCH_SEGMENTS',
);

export const WEB_RECORDER_RECOVERY_COMMANDS = [
    ListRecoveryFiles,
    SaveRecoveryFile,
    MoveTempDirectory,
    ReadRecoveryJson,
    ReadJson,
    GetRecoverySegment,
    DeleteRecoveryJson,
    StitchSegments,
];

export type WebRecorderRecoveryWorkerRequests = (typeof WEB_RECORDER_RECOVERY_COMMANDS)[number];
