// Copyright 2024 Descript, Inc

import { createFile, MP4ArrayBuffer, MP4Info, MP4Sample, MP4Track } from 'mp4box';
import { DescriptError, ErrorCategory } from '@descript/errors';

// eslint-disable-next-line
export let ResolvedBlobClass: any;

if (typeof window !== 'undefined' && typeof window.Blob !== 'undefined') {
    // We're in a web environment, use Web Workers Blob
    ResolvedBlobClass = Blob;
} else if (typeof Buffer !== 'undefined') {
    // We're in a Node.js environment, attempt to use the Blob from 'buffer'
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    ResolvedBlobClass = require('buffer').Blob;
} else {
    throw new DescriptError(
        'Unsupported environment: Blob is not available',
        ErrorCategory.VideoMediaEngine,
    );
}

export type ResolvedBlobType = typeof ResolvedBlobClass;

export interface Segment {
    data: ResolvedBlobType;
    duration: number;
    startTimecode: number;
    isInit: boolean;
    number: number;
}

export interface Block {
    timecode: number;
    keyframe: boolean;
    data: ResolvedBlobType;
}

export interface DemuxResult {
    headerInfo: {
        tracks: Map<number, TrackInfo>;
    };
    blocks: Block[];
}

export interface TrackInfo {
    type: TrackType;
    codec: string; // https://www.matroska.org/technical/codec_specs.html
}

export const enum TrackType {
    UNKNOWN = 0,
    VIDEO = 1,
    AUDIO = 2,
    COMPLEX = 3,
    LOGO = 16,
    SUBTITLE = 17,
    BUTTONS = 18,
    CONTROL = 32,
    METADATA = 33,
}

export class DemuxMP4 {
    mp4boxfile = createFile(false);
    fileStart = 0;
    timecodeStart = new Map<number, number>();
    duration = new Map<number, number>();
    segmentNumber = 0;
    trackForDuration = 1;
    trackDurationType = '';
    headerSent = false;

    constructor() {
        this.timecodeStart = new Map<number, number>();
        this.mp4boxfile.onError = this.onError;
        this.mp4boxfile.onReady = this.onReady;
        this.mp4boxfile.onSamples = this.onSamples;
    }

    public stop(): void {
        this.mp4boxfile.flush();
    }

    public async segment(
        blob: ResolvedBlobType,
        forcedSequenceNumber?: number,
    ): Promise<Segment[]> {
        const buf: MP4ArrayBuffer = (await blob.arrayBuffer()) as MP4ArrayBuffer;
        buf.fileStart = this.fileStart;
        this.fileStart = this.mp4boxfile.appendBuffer(buf);

        let headerBlob;
        let dataBlob;
        if (!this.headerSent) {
            const headerSize = this.mp4boxfile.moov.start + this.mp4boxfile.moov.size;
            headerBlob = blob.slice(0, headerSize, blob.type);
            dataBlob = blob.slice(headerSize, undefined, blob.type);
            this.headerSent = true;
        } else {
            dataBlob = blob;
        }

        const segments = [];
        if (headerBlob) {
            segments.push({
                data: headerBlob,
                isInit: true,
                duration: 0,
                startTimecode: 0,
                number: 0,
            });
        }

        if (dataBlob.size) {
            // If a segment number is provided, use it. Otherwise,
            // increment the internal count and use that
            let segmentNumber = forcedSequenceNumber;
            if (segmentNumber === undefined) {
                this.segmentNumber++;
                segmentNumber = this.segmentNumber;
            }

            segments.push({
                data: dataBlob,
                isInit: false,
                duration: this.duration.get(this.trackForDuration) ?? 0,
                startTimecode: this.timecodeStart.get(this.trackForDuration) ?? 0,
                number: segmentNumber,
            });
        }
        return segments;
    }

    private onSamples = (id: number, track: unknown, samples: MP4Sample[]) => {
        const firstSample = samples[0]!;
        const startTime = firstSample?.cts / firstSample?.timescale;
        this.timecodeStart.set(id, startTime);

        const duration = samples.reduce(
            (acc, sample) => acc + sample.duration / sample.timescale,
            0,
        );
        this.duration.set(id, duration);

        this.mp4boxfile.releaseUsedSamples(id, samples[samples.length - 1]!.number);
    };

    private onReady = (info: MP4Info) => {
        info.tracks.forEach((track: MP4Track) => {
            if (track.type === 'video') {
                this.trackDurationType = 'video';
                this.trackForDuration = track.id;
            } else if (track.type === 'audio' && !this.trackForDuration) {
                this.trackDurationType = 'audio';
                this.trackForDuration = track.id;
            }
            this.mp4boxfile.setExtractionOptions(track.id);
        });
        this.mp4boxfile.start();
    };

    private onError = (e: string) => {
        throw new DescriptError(e, ErrorCategory.VideoMediaEngine);
    };
}
