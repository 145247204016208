import { Text } from '@daily/shared/src/components/Text';
import { useMediaQuery } from '@daily/shared/src/hooks/useMediaQuery';
import { useEffect, useState } from 'react';
import * as React from 'react';

import { useIsMobile } from '@lib/state/core';

type TextVariant = React.ComponentProps<typeof Text>['variant'];

export const useStyleVariants = () => {
    const [isMobile] = useIsMobile();
    const [isTouch, setIsTouch] = useState(isMobile);

    useEffect(() => {
        setIsTouch(navigator.maxTouchPoints > 0);
    }, []);

    const iconVariant: number = isMobile || isTouch ? 24 : 16;

    const largeIconVariant: number = isMobile || isTouch ? 32 : 24;

    const isPortrait = useMediaQuery('(orientation: portrait)');

    const mobileLandscape = isMobile && !isPortrait;

    const mobilePortrait = isMobile && isPortrait;

    const textVariant: TextVariant = isMobile || isTouch ? 'large' : 'base';

    const textVariantSmall: TextVariant = isMobile || isTouch ? 'base' : 'small';

    const textVariantStrong: TextVariant = isMobile || isTouch ? 'largestrong' : 'strong';

    return {
        isPortrait,
        isTouch,
        mobileLandscape,
        mobilePortrait,
        textVariant,
        textVariantSmall,
        textVariantStrong,
        iconVariant,
        largeIconVariant,
    };
};
