// Copyright 2023 Descript, Inc

import { trackEvent } from '@descript/analytics';
import { MediaDeviceKinds, Permission } from '@descript/recorder-base';
import { BehaviorSubject } from 'rxjs';

export const hasPermission$ = new BehaviorSubject<Permission>({ audio: false, video: false });
export const microphoneDevices$ = new BehaviorSubject<MediaDeviceInfo[]>([]);
export const cameraDevices$ = new BehaviorSubject<MediaDeviceInfo[]>([]);
const erroredDevices = new Set<string>();
let subsInitialized = false;
let deviceChangeListenerInitialized = false;

const isFirefox = () => {
    return navigator.userAgent.toLowerCase().includes('firefox');
};

/**
 * Helper to manage updates to hasPermission$
 */
function updateHasPermission({ audio, video }: { audio?: boolean; video?: boolean }) {
    const nextValue = { ...hasPermission$.getValue() };
    if (audio !== undefined) {
        nextValue.audio = audio;
    }
    if (video !== undefined) {
        nextValue.video = video;
    }
    hasPermission$.next(nextValue);
}

export async function initDevices(video: boolean = true, audio: boolean = true) {
    const errors = await requestPermissions(video, audio);
    await setupDeviceSubscriptions(video, audio);

    return errors;
}

/**
 * Sets up the device subscriptions
 */
async function setupDeviceSubscriptions(video: boolean = true, audio: boolean = true) {
    if (subsInitialized) {
        return;
    }
    subsInitialized = true;

    // Setup devices when permissions are changed.
    hasPermission$.subscribe(async (hasPermission) => {
        await setupSystemDevices(video, audio);
    });
    await setupSystemDevices(video, audio);
}

async function safePermissionQuery(
    permission: string,
    defaultValue: boolean = false,
): Promise<boolean> {
    try {
        return (
            (
                await navigator.permissions.query({
                    // @ts-expect-error microphone/camera isn't supported in all browsers
                    name: permission,
                })
            ).state === 'granted'
        );
    } catch (e) {
        // firefox does not support permissions query so we always have to manually check
        if (isFirefox() && e instanceof TypeError) {
            return defaultValue;
        }
        throw e;
    }
}

/**
 * Checks if the user has granted permission to use the microphone and camera.
 * Tries to request permission if it's not granted already. Finally, sets the
 * hasPermission$ observable with the updated permissions. It does not depend
 * on the constraints$ observable intentionally.
 *
 * Will call itself recursively to try to get mic only or camera only permissions if both is not available
 *
 * @returns Promise<void | string> Returns a error message if unsuccessful
 */
export async function requestPermissions(video = true, audio = true) {
    const errors: string[] = [];

    const micPermGranted = await safePermissionQuery('microphone');
    const cameraPermGranted = await safePermissionQuery('camera');

    if (
        // bail early if both permissions are already granted to avoid camera light coming on and off
        (micPermGranted && cameraPermGranted && video && audio) ||
        // bail early if only mic is requested and already granted
        (micPermGranted && audio && !video) ||
        // bail early if only camera is requested and already granted
        (cameraPermGranted && video && !audio)
    ) {
        updateHasPermission({ audio: micPermGranted, video: cameraPermGranted });
        return errors;
    }

    try {
        await createTestUserMediaStream({ audio, video });
        trackEvent('miscellaneous_modal_viewed', {
            context:
                video && audio
                    ? 'microphone_and_camera_permission'
                    : video
                      ? 'camera_permission'
                      : 'microphone_permission',
            target: 'allow',
        });

        // In Electron, creating an audio stream can still pass even if we don't have
        // microphone permissions. To handle this, we only update the permission
        // to true if checking microphone access again reports that we have access
        const latestMicQueryResult = await safePermissionQuery('microphone');

        if (latestMicQueryResult && audio) {
            updateHasPermission({ audio: true });
        }

        if (video) {
            updateHasPermission({ video: true });
        }
    } catch (error) {
        await updatePermissionsOnUserMediaStreamError({
            error: error as Error,
            audio,
            video,
            collectErrorMessages: errors,
        });
    }

    return errors;
}

export async function updatePermissionsOnUserMediaStreamError({
    error,
    audio,
    video,
    collectErrorMessages,
}: {
    error: Error;
    audio?: boolean;
    video?: boolean;

    collectErrorMessages?: string[];
}) {
    if (error.name === 'NotAllowedError') {
        trackEvent('miscellaneous_modal_viewed', {
            context:
                video && audio
                    ? 'microphone_and_camera_permission'
                    : video
                      ? 'camera_permission'
                      : 'microphone_permission',
            target: 'block',
        });
    }

    if (audio && video) {
        // If we tried to request access to both camera and microphone at the same time,
        // then check both devices individually so we can determine whether it's just one
        // device we don't have access to or both and update hasPermission$ accordingly.
        // Failed permissions for either device can result in any one of these errors.
        if (['NotAllowedError', 'NotReadableError', 'NotFoundError'].includes(error.name)) {
            await Promise.all([
                createTestUserMediaStream({ audio: true })
                    .then(() => updateHasPermission({ audio: true }))
                    .catch((e) =>
                        updatePermissionsOnUserMediaStreamError({ error: e, audio: true }),
                    ),
                createTestUserMediaStream({ video: true })
                    .then(() => updateHasPermission({ video: true }))
                    .catch((e) =>
                        updatePermissionsOnUserMediaStreamError({ error: e, video: true }),
                    ),
            ]);
        }
    } else {
        if (error.name === 'NotAllowedError') {
            if (audio) {
                updateHasPermission({ audio: false });
            }

            if (video) {
                updateHasPermission({ video: false });
            }

            // we only care about collecting NotAllowedError messages
            collectErrorMessages?.push(error.message);
        }
    }

    return hasPermission$.getValue();
}

async function createTestUserMediaStream({
    audio = false,
    video = false,
}: {
    audio?: boolean;
    video?: boolean;
}) {
    const mediaStream = await navigator.mediaDevices.getUserMedia({
        video,
        audio,
    });
    mediaStream.getTracks().forEach((track) => track.stop());
}

/**
 * Sets up the systemDevices$ observable with all of the devices
 * Listens for changes to the system devices and updates itself
 *
 * @returns Promise<void>
 */
async function setupSystemDevices(video: boolean = true, audio: boolean = true): Promise<void> {
    if (!deviceChangeListenerInitialized) {
        deviceChangeListenerInitialized = true;
        // When the user system devices are updated, update the UI
        // This is a recursive call, but it's cool because it's only set once
        navigator.mediaDevices.ondevicechange = async () => {
            await setupSystemDevices();
        };
    }

    const devices: MediaDeviceInfo[] = (await navigator.mediaDevices.enumerateDevices()).map(
        (device) => {
            return {
                // Call toJSON method in the MediaDeviceInfo object to get a regular object
                ...device.toJSON(),
                label: cleanDeviceLabel(device.label),
            };
        },
    );

    if (audio) {
        const microphoneDevices = devices.filter(
            (device) =>
                device.kind === MediaDeviceKinds.MICROPHONES &&
                device.deviceId !== '' &&
                device.deviceId !== undefined &&
                !erroredDevices.has(device.kind + device.deviceId),
        );
        microphoneDevices$.next(microphoneDevices);
    }

    if (video) {
        const cameraDevices = devices.filter(
            (device) =>
                device.kind === MediaDeviceKinds.CAMERAS &&
                device.deviceId !== '' &&
                device.deviceId !== undefined &&
                !erroredDevices.has(device.kind + device.deviceId),
        );
        cameraDevices$.next(cameraDevices);
    }
}

function cleanDeviceLabel(label: string) {
    return label.replace(/ \((\d|\w){4}:(\d|\w){4}\)/, '');
}
