// Copyright 2024 Descript, Inc
import { useEffect, useRef } from 'react';

export const usePreviousValue = <T = unknown>(value: T) => {
    const prevValue = useRef<T>();

    useEffect(() => {
        prevValue.current = value;

        return () => {
            prevValue.current = undefined;
        };
    });

    return prevValue.current;
};
