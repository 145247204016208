import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function DescriptIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.2364 9.99504C10.2364 10.6141 10.6433 11.0155 11.2706 11.0155H13.4987C13.7514 10.3942 13.9211 9.71121 14 8.97427H11.2706C10.6433 8.97427 10.2364 9.37571 10.2364 9.99504ZM8.18139 9.99504C8.18139 9.37571 7.7745 8.97427 7.14748 8.97427H2V11.0155H7.14748C7.7745 11.0155 8.18139 10.6141 8.18139 9.99504ZM6.99134 1H3.2728C2.50096 1 2 1.49629 2 2.26164V3.04668H12.258C11.0244 1.74402 9.20978 1 6.99134 1ZM2 13.7386C2 14.5037 2.50096 15 3.2728 15H6.99134C9.20978 15 11.0244 14.256 12.258 12.9536H2V13.7386ZM8.18219 4.98941C7.55489 4.98941 7.148 5.39109 7.148 6.01016C7.148 6.62949 7.55489 7.0309 8.18219 7.0309H14C13.9211 6.29398 13.7515 5.61094 13.4988 4.98941H8.18219ZM5.0929 6.01016C5.0929 5.39109 4.68601 4.98941 4.05899 4.98941H2V7.0309H4.05899C4.68601 7.0309 5.0929 6.62949 5.0929 6.01016Z"
                fill={color}
            />
        </svg>
    );
}
