import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function CheckCircleIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.0001 16.0001C10.7741 16.0001 10.5541 15.9241 10.3751 15.7811L7.87515 13.7811C7.44415 13.4361 7.37415 12.8071 7.71915 12.3751C8.06415 11.9441 8.69515 11.8741 9.12515 12.2191L10.7881 13.5501L14.6781 7.93115C14.9921 7.47715 15.6131 7.36315 16.0691 7.67815C16.5231 7.99215 16.6371 8.61515 16.3222 9.06915L11.8221 15.5691C11.6651 15.7971 11.4191 15.9491 11.1451 15.9891C11.0971 15.9961 11.0482 16.0001 11.0001 16.0001Z"
                fill="white"
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
