import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function ExitIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6.5 13.5L3.83333 13.5C3.47971 13.5 3.14057 13.3712 2.89052 13.142C2.64048 12.9128 2.5 12.6019 2.5 12.2778L2.5 3.72222C2.5 3.39807 2.64048 3.08719 2.89053 2.85798C3.14057 2.62877 3.47971 2.5 3.83333 2.5L6.5 2.5"
                stroke={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.8535 7.83585L10.3536 11.3359L9.64648 10.6288L12.265 8.01013L8.99999 8.01015L8.9983 8.01014L5.9983 8.00001L6.00168 7.00001L8.99999 7.01014H9.00083L12.3207 7.01013L9.66405 4.35353L10.3712 3.64642L13.8535 7.12875L14.207 7.4823L13.8535 7.83585Z"
                fill={color}
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
