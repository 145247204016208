// Copyright 2020 Descript, Inc

import { newId } from '@descript/descript-model';
import {
    CallbackResponseOf,
    MessageAbortRequest,
    MessageCallback,
    MessageRejected,
    MessageRequest,
    MessageResponse,
    OnResponseCallbackFn,
    RequestOf,
    ResponseOf,
    WorkerAbortedError,
    WorkerRequestCreator,
    WorkerRequester,
    WorkerRequestOf,
} from './WorkerTypes';

import { addAbortListener, createScopedLogger } from '@descript/descript-core';

const logger = createScopedLogger({
    name: 'webworkers/workerclient',
});
function isError<Res, CallbackRes>(
    res: MessageResponse<Res, CallbackRes>,
): res is MessageRejected {
    return (res as MessageRejected).err !== undefined;
}

function isCallback<Res, CallbackRes>(
    res: MessageResponse<Res, CallbackRes>,
): res is MessageCallback<CallbackRes> {
    return (res as MessageCallback<CallbackRes>).callback !== undefined;
}

export type MessageResponseOf<C> = MessageResponse<ResponseOf<C>, CallbackResponseOf<C>>;

interface BaseRequestOptions {
    abortController?: AbortController;
    transfer?: Transferable[];
}
export type RequestOptions<C> = BaseRequestOptions &
    (
        | {
              onCallback?: undefined;
              longLivedCallback?: undefined;
          }
        | {
              onCallback: OnResponseCallbackFn<CallbackResponseOf<C>>;
              /**
               * Whether the callback will continue to be called after the original request was resolved.
               * Defaults to `false`.
               */
              longLivedCallback?: boolean;
          }
    );

export class WorkerClient<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Creators extends WorkerRequestCreator<any, any, any>,
> {
    private resolvers: Record<
        string,
        {
            resolve: <C extends Creators>(res: ResponseOf<C>) => void;
            onCallback?: OnResponseCallbackFn<CallbackResponseOf<Creators>>;
            longLivedCallback?: boolean;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            reject: (error: any) => void;
            abortController?: AbortController;
            unsubscribeAbort?: () => void;
        }
    > = {};

    constructor(private readonly requester: WorkerRequester<Creators>) {
        this.requester.onMessage((res) => {
            const { id } = res;
            const resolver = this.resolvers[id];
            if (!resolver) {
                return;
            }

            if (resolver.abortController?.signal.aborted) {
                resolver.unsubscribeAbort?.();
                resolver.reject(new WorkerAbortedError('Already aborted'));
                return;
            }

            if (isCallback(res)) {
                if (!resolver.onCallback) {
                    logger.error(` Got unexpected command callback`, { id: res.id });
                } else {
                    resolver.onCallback(res.callback);
                }
                return;
            }

            // If we're resolving or rejecting, we don't need to listen for aborts anymore
            resolver.unsubscribeAbort?.();

            if (isError(res)) {
                logger.debug(`%cCommand rejected`, {
                    color: 'orange',
                    error: res.err.name,
                    message: res.err.message,
                });
                resolver.reject(res.err);
            } else {
                resolver.resolve(res.value);
            }

            // don't delete if this is a long-lived callback
            if (!resolver.longLivedCallback) {
                delete this.resolvers[id];
            }
        });
    }

    request<C extends Creators>(
        creator: C,
        req: RequestOf<C>,
        { abortController, transfer, onCallback, longLivedCallback }: RequestOptions<C> = {},
    ): Promise<ResponseOf<C>> {
        return new Promise((resolve, reject) => {
            const id = newId();
            // If we have an AbortController, make sure to inform the WorkerServer when aborts happen
            const unsubscribeAbort = addAbortListener(abortController, () => {
                const abortMessage: MessageAbortRequest = { type: 'abort', id };
                this.requester.postMessage(abortMessage);
            });
            this.resolvers[id] = {
                resolve,
                onCallback,
                longLivedCallback,
                reject,
                abortController,
                unsubscribeAbort,
            };

            const message: MessageRequest<WorkerRequestOf<C>> = {
                type: 'new',
                id,
                req: creator(req) as WorkerRequestOf<C>,
                hasCallback: Boolean(onCallback),
                hasAbortController: Boolean(abortController),
            };
            if (transfer) {
                this.requester.postMessage(message, transfer);
            } else {
                this.requester.postMessage(message);
            }
        });
    }
}
