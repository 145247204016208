import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function SpeakerIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12.6865 3C13.8477 4.32622 14.5 6.12472 14.5 8C14.5 9.87528 13.8477 11.6738 12.6865 13M10.5 5.49646C11.0806 6.15957 11.4068 7.05883 11.4068 7.99646C11.4068 8.9341 11.0806 9.83335 10.5 10.4965"
                stroke={color}
            />
            <path d="M6.5 3L3.16667 5.85714H0.5V10.1429H3.16667L6.5 13V3Z" stroke={color} />
        </svg>
    );
}
