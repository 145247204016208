import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function HelpIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_2919_15)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.48394 4.21244C8.00295 4.12062 7.50419 4.16758 7.04992 4.34817C6.59551 4.52882 6.20383 4.83605 5.92671 5.23409C5.6494 5.63241 5.5 6.10298 5.5 6.5862H6.5C6.5 6.31013 6.58519 6.03847 6.74741 5.80546C6.90981 5.57218 7.14285 5.38735 7.41934 5.27743C7.69598 5.16746 8.00148 5.1384 8.29642 5.1947C8.59128 5.25099 8.85944 5.38948 9.06799 5.58964C9.27633 5.78959 9.41573 6.04181 9.47197 6.31319C9.52819 6.58442 9.49954 6.86578 9.38869 7.12264C9.27772 7.37976 9.08833 7.60287 8.84123 7.76133C8.59393 7.91992 8.30113 8.00572 8 8.00572C7.86739 8.00572 7.74021 8.0584 7.64645 8.15216C7.55268 8.24593 7.5 8.37311 7.5 8.50572V9.33335H8.5V8.95685C8.81242 8.89514 9.11181 8.77577 9.38105 8.60311C9.79175 8.33973 10.115 7.96327 10.3068 7.5189C10.4987 7.07426 10.5493 6.58373 10.4512 6.11025C10.3531 5.63694 10.1115 5.20511 9.76043 4.86816C9.40958 4.53143 8.965 4.30428 8.48394 4.21244ZM8 10.5833C7.58579 10.5833 7.25 10.9191 7.25 11.3333C7.25 11.7475 7.58579 12.0833 8 12.0833H8.00667C8.42088 12.0833 8.75667 11.7475 8.75667 11.3333C8.75667 10.9191 8.42088 10.5833 8.00667 10.5833H8Z"
                    fill={color}
                />
                <path
                    d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
                    stroke={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_2919_15">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
