import * as React from 'react';

import { useTheme } from '../../contexts/Theme';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function DoorIcon({
    fill = 'var(--nav-icon-fill)',
    stroke = 'var(--nav-icon-stroke)',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    const { colors } = useTheme();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
            <path
                d="M12 14.5H12.5V14V3C12.5 2.17157 11.8284 1.5 11 1.5H5C4.17157 1.5 3.5 2.17157 3.5 3V14V14.5H4H12Z"
                fill={fill ?? colors.system.grey}
                stroke={stroke ?? colors.system.greyDark}
            />
            <path
                d="M10 9C10.5523 9 11 8.55229 11 8C11 7.44772 10.5523 7 10 7C9.44771 7 9 7.44772 9 8C9 8.55229 9.44771 9 10 9Z"
                fill={stroke ?? colors.system.greyDark}
            />
            <path
                d="M1.5 14C1.22386 14 1 14.2239 1 14.5C1 14.7761 1.22386 15 1.5 15H14.5C14.7761 15 15 14.7761 15 14.5C15 14.2239 14.7761 14 14.5 14H1.5Z"
                fill={stroke ?? colors.system.greyDark}
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
