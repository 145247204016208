// Copyright 2022 Descript, Inc
import { getClientAttributes } from '../Utilities/ClientAttributes';
import { FeatureFlagName, FeatureFlagSettings, FeatureFlagValue } from '../FeatureFlags/types';

import * as ApiClient from './ApiClient';
import { AsyncContext } from '@descript/analytics';

// API result
type FeatureFlagsAPIResult = {
    user_id: string;
    flags: FeatureFlagSettings;
};

export async function fetchFeatureFlag(
    ctx: AsyncContext,
    {
        userId = 'me',
        feature,
    }: {
        userId?: string;
        feature: FeatureFlagName;
    },
): Promise<FeatureFlagValue> {
    const json = (await ApiClient.request(
        ctx,
        ApiClient.RequestType.GET,
        `/users/${userId}/feature_flags/${feature}`,
        getClientAttributes(),
    )) as FeatureFlagsAPIResult;
    return json.flags[feature];
}

export async function fetchFeatureFlags(
    ctx: AsyncContext,
    {
        userId = 'me',
        features,
    }: {
        userId?: string;
        features: readonly FeatureFlagName[];
    },
): Promise<FeatureFlagSettings> {
    const json = (await ApiClient.request(
        ctx,
        ApiClient.RequestType.GET,
        `/users/${userId}/feature_flags`,
        {
            flags: features.join(','),
            ...getClientAttributes(),
        },
    )) as FeatureFlagsAPIResult;
    return json.flags;
}

export async function fetchPublicFeatureFlag(
    ctx: AsyncContext,
    flagName: string,
    signal?: AbortSignal, // Optional signal parameter
): Promise<{ value: FeatureFlagValue }> {
    return await ApiClient.request(
        ctx,
        ApiClient.RequestType.GET,
        `/public/feature_flag/${flagName}`,
        undefined,
        undefined,
        signal ? { signal } : {}, // Pass the signal as part of the request options
    );
}
