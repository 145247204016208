// Copyright 2019 Descript, Inc

import { SpringConfig } from '@react-spring/web';
import { darken as polishedDarken, rgba } from 'polished';

export const FontSizes = Object.freeze({
    tiny: '11px',
    finePrint: '12px',
    regular: '13px',
    label: '15px',
    header: '19px',
    largeHeader: '26px',
    extraLargeHeader: '32px',
});

export const FontWeights = Object.freeze({
    regular: 400,
    semibold: 500,
    bold: 600,
});

export const TextColors = Object.freeze({
    standard: 'var(--color-standard-text)',
    subtext: 'var(--color-subtext)',
    black: 'var(--color-black-foreground)',
    lightGray: 'var(--color-black-30pct)',
    white: 'var(--color-white-foreground)',
    pink: 'var(--color-descript-pink-filler)',
    button: 'white',
    primary: 'var(--color-descript-blue)',
    destructive: 'var(--color-descript-red)',
    tooltipSubtitle: 'var(--tooltip-shortcut-color)',
    upgrade: {
        degrees: 157,
        color1: 'rgb(222, 40, 152)',
        color2: 'rgb(145, 102, 255)',
    },
});

export const TransitionDuration = Object.freeze({
    normal: 250,
});

export const Variants = Object.freeze({
    primary: 'rgb(0, 98, 255)',
    primaryLight: 'rgba(0, 98, 255, 0.1)',
    secondary: 'rgb(255, 255, 255)',
    grey: 'rgb(237, 237, 237)',
    light: 'transparent',
    lightSecondary: 'transparent',
    dark: 'rgb(151, 151, 151)',
    black: 'rgb(0, 0, 0)',
    destructive: 'rgb(255, 54, 91)',
    error: 'rgba(255, 54, 91, 0.1)',
    upgrade: {
        degrees: 157,
        color1: 'rgb(222, 40, 152)',
        color2: 'rgb(145, 102, 255)',
    },
    upgradeSubtle: {
        degrees: 157,
        color1: 'rgb(251, 228, 242)',
        color2: 'rgb(243, 234, 250)',
    },
    upgradeLight: 'transparent',
    inspector: 'rgb(255, 255, 255)',
    inspectorLight: 'transparent',
    inspectorDark: 'rgb(100, 100, 100)',
});

export type Variant = keyof typeof Variants;

export const IconOpacity = 0.4;
export const IconOpacityDisabled = 0.2;
export const LineColor = 'rgba(0, 0, 0, 0.15)';

export const SpringConfigs: Record<string, SpringConfig> = {
    superStiff: { mass: 1, tension: 850, friction: 28 },
};

/**
 * Darkens the provided color by 10% per specified level.
 * If the provided color is transparent, the alpha is modified instead.
 * @param color Color to be darkened.
 * @param levels How much the color should be darkened (defaults to 1).
 */
export function darken(color: string, levels: number = 1): string {
    if (levels === 0) {
        return color;
    }

    const amount = levels * 0.1;

    return color === 'transparent' ? rgba(0, 0, 0, amount / 2) : polishedDarken(amount, color);
}
