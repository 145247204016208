// Copyright 2018 Descript, Inc

import { useState, useEffect, CSSProperties } from 'react';
import classNames from 'classnames';
import { ReactNull } from '@descript/react-utils';

export interface AnimatedLogoProps {
    size?: number;
    color?: string;
    isAnimationPaused?: boolean;
    delay?: number;
}

export function DescriptLoading({
    color = 'var(--blue-500)',
    delay = 0,
    isAnimationPaused,
    size = 40,
}: AnimatedLogoProps): JSX.Element | null {
    const [show, setShow] = useState(delay === 0);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(true);
        }, delay);

        return () => {
            clearTimeout(timeout);
        };
    }, [delay]);

    if (!show) {
        return ReactNull;
    }

    return (
        <div className={classNames('AnimatedLogo', { paused: isAnimationPaused })}>
            <div className="container">
                <div
                    className="logo"
                    style={
                        {
                            ['--size']: `${size}px`,
                            ['--baguette-height']: `${Math.floor(size / 7)}px`,
                            ['--baguette-color']: color,
                        } as CSSProperties
                    }
                >
                    <div className="container">
                        <div className="line line1">
                            <div className="baguette" />
                            <div className="baguette" />
                        </div>
                        <div className="line line2">
                            <div className="baguette" />
                            <div className="baguette" />
                            <div className="baguette" />
                        </div>
                        <div className="line line3">
                            <div className="baguette" />
                            <div className="baguette" />
                            <div className="baguette" />
                        </div>
                        <div className="line line4">
                            <div className="baguette" />
                            <div className="baguette" />
                        </div>
                    </div>
                </div>
            </div>
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                /* Copyright 2018 Descript, Inc */

                .AnimatedLogo .logo {
                    /* main styles to change */
                    --main-bg: transparent;
                    --size: 160px;
                    --baguette-color: blue;
                    --frame-time: 1s;

                    /* additional variables */
                    --container-bg: var(--main-bg);
                    --container-bg: var(--main-bg);
                    --container-paddings: 2px;
                    --baguette-width: calc(var(--size) * 0.85);
                    --baguette-border-radius: var(--baguette-height);
                }

                .AnimatedLogo.paused * {
                    animation-play-state: paused !important;
                }

                .AnimatedLogo .logo {
                    position: relative;
                    margin: 0 auto;
                    width: var(--size);
                    height: var(--size);
                    background-color: var(--container-bg);
                }

                .AnimatedLogo .logo .container {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    position: relative;
                    height: var(--size);
                    width: calc(var(--size) - var(--container-paddings));
                    margin: 0 auto;

                    background: var(--container-bg);
                    border-radius: 40px 170px 170px 40px;
                    overflow: hidden;
                    /* Fixes overflow not being respected in safari */
                    will-change: transform;
                }

                .AnimatedLogo .logo .baguette {
                    position: absolute;
                    background-color: var(--baguette-color);
                    height: var(--baguette-height);
                    width: var(--baguette-width);
                    border-radius: var(--baguette-border-radius);
                }

                .AnimatedLogo .logo .line {
                    position: relative;
                    height: var(--baguette-height);
                    margin-left: calc(var(--container-paddings) / -2);
                    margin-right: calc(var(--container-paddings) / -2);
                }

                /* line1 */
                .AnimatedLogo .logo .line1 .baguette:first-child {
                    animation: l11 var(--frame-time) infinite linear;
                }

                .AnimatedLogo .logo .line1 .baguette:nth-child(2) {
                    animation: l12 var(--frame-time) infinite linear;
                }

                @keyframes l11 {
                    0% {
                        transform: translate3d(calc(-1 * var(--size)), 0, 0);
                    }
                    100% {
                        transform: translate3d(0, 0, 0);
                    }
                }

                @keyframes l12 {
                    0% {
                        transform: translate3d(0, 0, 0);
                    }
                    50% {
                        transform: translate3d(calc(0.5 * var(--size)), 0, 0);
                    }
                    100% {
                        transform: translate3d(calc(1.4 * var(--size)), 0, 0);
                    }
                }

                /* line2 */
                .AnimatedLogo .logo .line2 .baguette:first-child {
                    animation: l21 var(--frame-time) infinite linear;
                }

                .AnimatedLogo .logo .line2 .baguette:nth-child(2) {
                    animation: l22 var(--frame-time) infinite linear;
                }

                .AnimatedLogo .logo .line2 .baguette:nth-child(3) {
                    animation: l23 var(--frame-time) infinite linear;
                }

                @keyframes l21 {
                    0% {
                        transform: translate3d(calc(-0.55 * var(--size)), 0, 0);
                    }
                    100% {
                        transform: translate3d(calc(-2.05 * var(--size)), 0, 0);
                    }
                }

                @keyframes l22 {
                    0% {
                        transform: translate3d(calc(0.45 * var(--size)), 0, 0);
                    }
                    100% {
                        transform: translate3d(calc(-0.55 * var(--size)), 0, 0);
                    }
                }

                @keyframes l23 {
                    0% {
                        transform: translate3d(calc(1.45 * var(--size)), 0, 0);
                    }
                    100% {
                        transform: translate3d(calc(0.45 * var(--size)), 0, 0);
                    }
                }

                /* line3 */
                .AnimatedLogo .logo .line3 .baguette:first-child {
                    animation: l31 var(--frame-time) infinite linear;
                }

                .AnimatedLogo .logo .line3 .baguette:nth-child(2) {
                    animation: l32 var(--frame-time) infinite linear;
                }

                .AnimatedLogo .logo .line3 .baguette:nth-child(3) {
                    animation: l33 var(--frame-time) infinite linear;
                }

                @keyframes l31 {
                    0% {
                        transform: translate3d(calc(-1.45 * var(--size)), 0, 0);
                    }
                    100% {
                        transform: translate3d(calc(-0.45 * var(--size)), 0, 0);
                    }
                }

                @keyframes l32 {
                    0% {
                        transform: translate3d(calc(-0.45 * var(--size)), 0, 0);
                    }
                    100% {
                        transform: translate3d(calc(0.55 * var(--size)), 0, 0);
                    }
                }

                @keyframes l33 {
                    0% {
                        transform: translate3d(calc(0.55 * var(--size)), 0, 0);
                    }
                    100% {
                        transform: translate3d(calc(2.05 * var(--size)), 0, 0);
                    }
                }

                /* line4 */
                .AnimatedLogo .logo .line4 .baguette:first-child {
                    animation: l41 var(--frame-time) infinite linear;
                }

                .AnimatedLogo .logo .line4 .baguette:nth-child(2) {
                    animation: l42 var(--frame-time) infinite linear;
                }

                @keyframes l41 {
                    0% {
                        transform: translate3d(0, 0, 0);
                    }
                    50% {
                        transform: translate3d(calc(-0.6 * var(--size)), 0, 0);
                    }
                    100% {
                        transform: translate3d(calc(-1.1 * var(--size)), 0, 0);
                    }
                }

                @keyframes l42 {
                    0% {
                        transform: translate3d(var(--size), 0, 0);
                    }
                    100% {
                        transform: translate3d(0, 0, 0);
                    }
                }
                /* ~ the end ~ */
            `}</style>
        </div>
    );
}
