import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function RefreshIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.15259 8.00001C2.15259 5.13671 4.47375 2.81555 7.33704 2.81555C10.0407 2.81555 12.2611 4.88517 12.5002 7.52659L12.9721 7.03316C13.1629 6.8336 13.4794 6.82653 13.679 7.01739C13.8786 7.20825 13.8856 7.52475 13.6948 7.72432L12.322 9.1597C12.2277 9.25833 12.0972 9.31412 11.9607 9.31412C11.8242 9.31412 11.6937 9.25833 11.5993 9.1597L10.2266 7.72433C10.0357 7.52476 10.0428 7.20826 10.2424 7.0174C10.4419 6.82654 10.7584 6.8336 10.9493 7.03317L11.5038 7.613C11.3085 5.48341 9.51757 3.81555 7.33704 3.81555C5.02792 3.81555 3.15565 5.68593 3.15259 7.99434L3.15262 8C3.15262 10.309 5.02276 12.1811 7.33096 12.1844L7.33708 12.1844C8.51404 12.1844 9.57678 11.6992 10.3377 10.9165C10.5302 10.7185 10.8467 10.714 11.0447 10.9065C11.2427 11.099 11.2472 11.4155 11.0547 11.6135C10.1148 12.5804 8.79925 13.1825 7.34396 13.1844L7.33708 13.1845C4.47588 13.1845 2.15602 10.8667 2.15263 8.00629L2.15259 8.00001Z"
                fill={color}
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
