// Copyright © 2024 Descript, Inc. All rights reserved.
import { put } from 'typed-redux-saga';
import {
    DismissToastAction,
    ShowGenericToastAction,
} from '@descript/client/src/Actions/ToastActions';
import { Progress } from '@descript/client/src/Api/RecordingClient';
import {
    ToastAction,
    ToastIcon,
    ToastRoute,
} from '@descript/client/src/Components/ToastPresenterTypes';

const textMap = new Map<string, string>();
let showStartingToast = false;

export const enum RecorderToastMsg {
    PROCESSING = 'Processing',
    COMPLETED = 'Recording processed',
    FAILED = 'If your recording is not visible, please check Project files',
    TIMEOUT = 'Your recording is taking longer than expected to process. Please check back later.',
    //RECOVERY = 'Recovering a recording...',
    DOWNLOAD_RECOVERY = `We couldn't verify the recording was uploaded. Files were saved to your Downloads folder.`,
}

// FIXME: This all needs to be updated to use Super Toasts
// Recovery ffmpeg progress is available in WebRecorderRecover
// REC-4529 https://linear.app/descript/issue/REC-4529/[web-recorder]-convert-toasts-and-notifications-to-super-toast

export function* processingToast(
    sessionId: string,
    text: RecorderToastMsg,
    dismissAfter: number,
    icon?: ToastIcon,
    actionMessage?: string,
    actionOrRoute?: ToastAction | ToastRoute | undefined,
): Generator {
    yield* put(DismissToastAction({ toastId: 'processing-' + sessionId }));
    yield* put(
        ShowGenericToastAction({
            text,
            dismissAfter,
            toastId: 'processing-' + sessionId,
            isSuperToast: true,
            icon: icon,
            actionMessage,
            actionOrRoute,
        }),
    );
}

// Shows a fake toast right after user presses stop
export function* startToast(dismissAfter: number, icon?: ToastIcon): Generator {
    yield* put(
        ShowGenericToastAction({
            text: RecorderToastMsg.PROCESSING,
            dismissAfter,
            toastId: RecorderToastMsg.PROCESSING,
            isSuperToast: true,
            icon: icon,
        }),
    );

    showStartingToast = true;
}

export function* runningStepsToast(
    progress: Progress,
    dismissAfter: number,
    icon?: ToastIcon,
): Generator {
    // This shows the toast for overall progress

    if (progress.overallProgress) {
        const processingString: string = RecorderToastMsg.PROCESSING + ' recording ';
        yield* put(
            ShowGenericToastAction({
                text: processingString,
                dismissAfter,
                toastId: processingString + ' ' + Number(progress.overallProgress * 100) + '%',
                isSuperToast: true,
                icon: icon,
                progress: `${Math.floor(progress.overallProgress * 100)}%`,
            }),
        );

        const mapValue = getMapValue(processingString);
        if (mapValue) {
            yield* put(DismissToastAction({ toastId: mapValue }));
        }

        textMap.set(
            processingString,
            processingString + ' ' + Number(progress.overallProgress * 100) + '%',
        );
    }

    // This hides the fake toast we instantiated to let users know something is happening after they pressed stop
    if (showStartingToast) {
        yield* put(DismissToastAction({ toastId: RecorderToastMsg.PROCESSING }));

        showStartingToast = false;
    }

    /*
    // Toasts to show different processing steps from backend
    const runningSteps = progress.runningSteps;
    for (let i = 0; i < Object.keys(runningSteps).length; i++) {
        const displayText = getDisplayName(runningSteps[i]);

        if (!displayText) {
            continue;
        }

        yield* put(
            ShowGenericToastAction({
                text:
                    Math.floor(Number(runningSteps[i].progress * 100)) > 0
                        ? displayText +
                          ' ' +
                          Math.floor(Number(runningSteps[i].progress * 100)) +
                          '%'
                        : displayText,
                dismissAfter,
                toastId: displayText + ' ' + Number(runningSteps[i].progress * 100) + '%',
                isSuperToast: true,
                icon: icon,
            }),
        );

        const mapValue = getMapValue(displayText);
        if (mapValue) {
            yield* put(DismissToastAction({ toastId: mapValue }));
        }

        textMap.set(
            displayText,
            displayText + ' ' + Number(runningSteps[i].progress * 100) + '%',
        );
    }
     */
}

function getMapValue(processingString: string): string | undefined {
    return textMap.get(processingString);
}

/*
// This is commented out for now
// Gets what string should the toast display based off on backend step
function getDisplayName(runningStep: {
    code: string;
    displayName: string;
    progress: number;
}): string | undefined {
    // Backend defines a lot of code values. We map those code values to strings
    // This can be later moved to a separate file for localization purposes
    if (runningStep.code) {
        const codeValue = runningStep.code;

        if (codeValue === 'transcription') {
            return 'Transcribing';
        }

        if (codeValue === 'studio-sound') {
            return 'Applying Studio Sound';
        }

        return undefined;
    } else {
        return runningStep.displayName;
    }
}
*/

export function* dismissToast(): Generator {
    for (const [, value] of textMap) {
        yield* put(DismissToastAction({ toastId: value }));
    }

    textMap.clear();
}

export function* recoveryToast(sessionId: string): Generator {
    yield* put(
        ShowGenericToastAction({
            text: RecorderToastMsg.DOWNLOAD_RECOVERY,
            dismissAfter: 300,
            toastId: 'recovery-' + sessionId,
            isSuperToast: true,
        }),
    );
}
