// Copyright 2024 Descript, Inc
import {
    SentryErrorExtras,
    trackError as trackErrorInternal,
} from '@descript/client/src/Utilities/ErrorTracker';
import { ErrorCategory, DescriptError } from '@descript/errors';

export const trackError = (
    error: Error | unknown,
    type: string,
    extra: SentryErrorExtras = {},
    category: ErrorCategory = ErrorCategory.Rooms,
) => {
    if (!(error instanceof Error)) {
        error = new DescriptError(String(error), ErrorCategory.Rooms);
    }
    return trackErrorInternal(error as Error, type, {
        category,
        extra,
    });
};
