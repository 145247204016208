import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function LinkIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <path
                d="M17.3034 12.0001L18.364 10.9395C19.955 9.34847 19.955 7.22715 18.364 5.63616C16.7731 4.04517 14.6517 4.04517 13.0607 5.63616L10.4091 8.2878C8.81811 9.87879 8.81811 12.0001 10.4091 13.5911L10.9394 14.1214"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.6968 11.9999L5.63613 13.0605C4.04514 14.6515 4.04514 16.7729 5.63613 18.3638C7.22713 19.9548 9.34845 19.9548 10.9394 18.3638L13.5911 15.7122C15.1821 14.1212 15.1821 11.9999 13.5911 10.4089L13.0608 9.87857"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    fill: transparent;
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
