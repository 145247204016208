import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    rotate?: number;
    size?: number;
    variant?: 'default' | 'thick';
}

export function CloseIcon({
    color = 'currentColor',
    rotate = 0,
    size = 24,
    variant = 'default',
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g>
                <path
                    d="M13 3L3 13"
                    stroke={color}
                    strokeWidth={variant === 'thick' ? 2 : 1.5}
                />
                <path
                    d="M3 3L13 13"
                    stroke={color}
                    strokeWidth={variant === 'thick' ? 2 : 1.5}
                />
            </g>
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    transform: rotate(${rotate}deg);
                    transform-origin: center;
                }
            `}</style>
        </svg>
    );
}
