// Copyright 2022 Descript, Inc

import { ReadableSpan, SpanExporter, TimedEvent } from '@opentelemetry/sdk-trace-base';
import { ExportResult } from '@opentelemetry/core';
import { TraceEvent, Tags } from '@descript/analytics';
import { HrTime, SpanKind, SpanStatus, SpanStatusCode } from '@opentelemetry/api';
import { ITransmission } from '../transmission';
import { Resource } from '@opentelemetry/resources';

function warn(...args: unknown[]) {
    if (process.env.NODE_ENV !== 'production') {
        console.warn('[LEGACY_TRANSMISSION_EXPORTER]', ...args);
    }
}

const NSEC_PER_MSEC = 1e6;

function msFromHrTime([seconds, nanoseconds]: HrTime): number {
    return seconds * 1000 + nanoseconds / NSEC_PER_MSEC;
}

function dateFromHrTime(hrTime: HrTime): Date {
    return new Date(msFromHrTime(hrTime));
}

function kindFromOtelKind(kind: SpanKind): Tags.CompleteTagData[Tags.SpanTag.spanKind] {
    switch (kind) {
        case SpanKind.SERVER:
            return 'server';
        case SpanKind.CLIENT:
            return 'client';
        case SpanKind.PRODUCER:
            return 'publisher'; // instead of "producer"
        case SpanKind.CONSUMER:
            return 'consumer';
        case SpanKind.INTERNAL:
        default:
            return 'internal';
    }
}

const SPAN_STATUS_VALUES = new Set(Object.values(Tags.SpanStatus));
function isSpanStatus(str: string | undefined): str is Tags.SpanStatus {
    return str !== undefined && SPAN_STATUS_VALUES.has(str as Tags.SpanStatus);
}

function statusFromOtelStatus({ code, message }: SpanStatus): Tags.SpanStatus | undefined {
    switch (code) {
        case SpanStatusCode.UNSET:
            return undefined;
        case SpanStatusCode.OK:
            return Tags.SpanStatus.ok;
        case SpanStatusCode.ERROR:
        default:
            // is "isSpanStatus" ever going to be true?
            return isSpanStatus(message) ? message : Tags.SpanStatus.unknownError;
    }
}

function traceEventFromEvent({
    event,
    traceId,
    spanId,
    resource,
}: {
    event: TimedEvent;
    traceId: string;
    spanId: string;
    resource: Resource;
}): TraceEvent {
    return {
        time: dateFromHrTime(event.time),
        data: {
            ...resource.attributes,
            ...event.attributes,
            // Don't let these be overridden
            [Tags.SpanTag.traceId]: traceId,
            [Tags.SpanTag.parentId]: spanId,
            [Tags.SpanTag.name]: event.name,
            [Tags.SpanTag.annotationType]: 'span_event',
            // redundant, but to keep typescript happy
            [Tags.SpanTag.serviceName]: resource.attributes[Tags.SpanTag.serviceName] as string,
        },
        samplerate: 1,
    };
}

function* traceEventsFromSpan(span: ReadableSpan): Iterable<TraceEvent> {
    let {
        name,
        kind,
        parentSpanId,
        startTime,
        status,
        attributes,
        duration,
        resource,
        links,
        events,
    } = span;
    const { traceId, spanId } = span.spanContext();
    let samplerate = attributes[Tags.SpanTag.sampleRate];
    if (typeof samplerate !== 'number') {
        samplerate = 1;
    }
    if (links.length > 0) {
        warn('Links added with OpenTelemetry will not export');
    }
    if (attributes[Tags.SpanTag.spanStatus] !== undefined) {
        warn(
            'Manually set spanStatus attribute will not export. Use `recordSpanOk` or `recordSpanError` instead.',
        );
    }
    if (attributes[Tags.SpanTag.isErrored] !== undefined) {
        warn(
            'Manually set isErrored attribute will not export. Use `recordSpanOk` or `recordSpanError` instead.',
        );
    }
    if (status.code === SpanStatusCode.UNSET) {
        // Our legacy tracer would default every span to `ok` status on finalization.
        // So, if we have not set the status via `span.setStatus` (or `recordSpanError`),
        // set it to "ok" here.
        status = { code: SpanStatusCode.OK };
    }
    for (const event of events) {
        yield traceEventFromEvent({ event, traceId, spanId, resource });
    }
    yield {
        time: dateFromHrTime(startTime),
        samplerate: Math.round(samplerate), // our endpoint requires this to be an integer
        data: {
            [Tags.SpanTag.name]: name,
            [Tags.SpanTag.traceId]: traceId,
            [Tags.SpanTag.spanId]: spanId,
            [Tags.SpanTag.parentId]: parentSpanId,
            [Tags.SpanTag.spanKind]: kindFromOtelKind(kind),
            [Tags.SpanTag.message]:
                status.code === SpanStatusCode.ERROR
                    ? (status.message ?? Tags.SpanStatus.unknownError)
                    : undefined,
            [Tags.SpanTag.durationMs]: msFromHrTime(duration),
            ...resource.attributes,
            ...attributes,
            // set status based on the span's `status`, not on the attributes
            [Tags.SpanTag.spanStatus]: statusFromOtelStatus(status),
            [Tags.SpanTag.isErrored]: status.code === SpanStatusCode.ERROR,
            // redundant, but to keep typescript happy
            [Tags.SpanTag.serviceName]: resource.attributes[Tags.SpanTag.serviceName] as string,
        },
    };
}

export class LegacyTransmissionExporter implements SpanExporter {
    constructor(private readonly transmission: ITransmission) {}

    export(spans: ReadableSpan[], resultCallback: (result: ExportResult) => void): void {
        for (const span of spans) {
            for (const event of traceEventsFromSpan(span)) {
                this.transmission.sendEvent(event);
            }
        }
    }

    async shutdown(): Promise<void> {
        return await this.transmission.flush();
    }

    async forceFlush(): Promise<void> {
        return await this.transmission.flush();
    }
}
