// Copyright 2024 Descript, Inc
let inMemoryStorage = {};

function isSupported(getStorage) {
    try {
        const key = '__some_random_key_you_are_not_going_to_use__';
        getStorage().setItem(key, key);
        getStorage().removeItem(key);
        return true;
    } catch (e) {
        return false;
    }
}

type GetStorage = () => Storage;

export const safeStorage = (getStorage: GetStorage) => ({
    clear() {
        if (isSupported(getStorage)) {
            getStorage().clear();
        } else {
            inMemoryStorage = {};
        }
    },
    getItem(key: string) {
        if (isSupported(getStorage)) {
            return getStorage().getItem(key);
        }
        return inMemoryStorage?.[key];
    },
    removeItem(key: string) {
        if (isSupported(getStorage)) {
            getStorage().removeItem(key);
        } else {
            delete inMemoryStorage?.[key];
        }
    },
    setItem(key: string, value: string) {
        if (isSupported(getStorage)) {
            getStorage().setItem(key, value);
        } else {
            inMemoryStorage[key] = value;
        }
    },
});
