import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function AppsIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.5 4H14.5V10H20.5V4ZM8 20C9.933 20 11.5 18.433 11.5 16.5C11.5 14.567 9.933 13 8 13C6.067 13 4.5 14.567 4.5 16.5C4.5 18.433 6.067 20 8 20ZM8 4L11.5 10H4.5L8 4ZM20.5311 14.75L17.5 13L14.4689 14.75V18.25L17.5 20L20.5311 18.25V14.75Z"
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    fill: ${color};
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
