// Copyright 2022 Descript, Inc

import { PlatformHelpers } from '@descript/descript-core';
import { DragEvent } from 'react';
import * as React from 'react';
import { KeyCode } from './useKeyCommand';

// Sometimes we want to listen for both react events and DOM events (manually added EventListeners)
export type HybridMouseEvent = MouseEvent | React.MouseEvent<Element>;
export type HybridKeyboardEvent = KeyboardEvent | React.KeyboardEvent<Element>;

export type HybridMouseEventHandler = (ev: HybridMouseEvent) => void;
export type HybridMouseEventHandlerWithItem<T> = (
    ev: HybridMouseEvent,
    representedItem?: T,
) => void;
export type HybridKeyboardEventHandler = (ev: HybridKeyboardEvent) => void;

export function preventDefault<T>(event: React.SyntheticEvent<T> | Event): void {
    event.preventDefault();
}

export function stopPropagation<T>(event: React.SyntheticEvent<T> | Event | undefined): void {
    event?.stopPropagation();
}

/** Also stop the nativeEvent propagation */
export function stopAllPropagation<T>(event: React.SyntheticEvent<T> | Event): void {
    event.stopPropagation();

    if ('nativeEvent' in event) {
        event.nativeEvent.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
    }
}

export function disallowDragOver(event: DragEvent) {
    event.dataTransfer.dropEffect = 'none';
}

export function stopImmediatePropagation<T extends Event>(
    event: React.BaseSyntheticEvent<T>,
): void {
    event.nativeEvent.stopImmediatePropagation();
}

export function stopPropagationAndPreventDefault<
    T extends {
        stopPropagation: () => void;
        preventDefault: () => void;
    },
>(event?: T): void {
    event?.stopPropagation();
    event?.preventDefault();
}

export function preventRepeatKey(keyCodes: Set<KeyCode>) {
    return (event: HybridKeyboardEvent): void => {
        if (event.repeat && keyCodes.has(event.keyCode)) {
            event.preventDefault();
        }
    };
}

export function isLeftClick(event: HybridMouseEvent): boolean {
    if (PlatformHelpers.isMac()) {
        return event.button === 0 && !event.ctrlKey;
    }
    return event.button === 0;
}

export function isRightClick(event: HybridMouseEvent): boolean {
    if (PlatformHelpers.isMac()) {
        return event.button === 2 || (event.button === 0 && event.ctrlKey);
    }
    return event.button === 2;
}

export function isDomNode(testNode: unknown): testNode is HTMLElement | SVGElement {
    return testNode instanceof HTMLElement || testNode instanceof SVGElement;
}
