// Copyright 2023 Descript, Inc
import * as React from 'react';

/**
 * Track whether a value has changed during render.
 *
 * @example
 * function Component({ value }) {
 *   if (useDidChange(value)) {
 *      // do something
 *   }
 *
 *   return <div />
 * }
 */
export function useDidChange<T>(value: T) {
    const prevValue = React.useRef(value);

    if (value !== prevValue.current) {
        prevValue.current = value;
        return true;
    }

    return false;
}

/**
 * Track whether a value in a group of values has changed during render.
 *
 * @example
 * function Component({ value, otherValue }) {
 *   if (useAllDidChange([value, otherValue])) {
 *      // do something
 *   }
 *
 *   return <div />
 * }
 */
export function useAnyDidChange<T extends unknown[]>(values: T) {
    const prevValue = React.useRef(values);

    if (values.some((value, index) => value !== prevValue.current[index])) {
        prevValue.current = values;
        return true;
    }

    return false;
}
