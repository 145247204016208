import * as React from 'react';

interface Props extends React.SVGAttributes<SVGElement> {
    color?: string;
    size?: number;
}

export function ReturnIcon({
    color = 'currentColor',
    size = 24,
    ...props
}: React.PropsWithChildren<Props>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.55485 12.5474C9.84581 12.8392 10.3176 12.8392 10.6085 12.5474C10.8995 12.2556 10.8995 11.7825 10.6085 11.4906L9.00295 9.88037H12.9592C15.1963 9.88037 17.0099 11.6992 17.0099 13.9429C17.0099 16.1866 15.1963 18.0055 12.9592 18.0055H6.24506C5.83357 18.0055 5.5 18.3401 5.5 18.7528C5.5 19.1654 5.83357 19.5 6.24506 19.5H12.9592C16.0193 19.5 18.5 17.012 18.5 13.9429C18.5 10.8739 16.0193 8.38589 12.9592 8.38589H9.00295L10.6085 6.77562C10.8995 6.4838 10.8995 6.01068 10.6085 5.71886C10.3176 5.42705 9.84581 5.42705 9.55485 5.71886L6.67738 8.60475C6.38642 8.89657 6.38642 9.36969 6.67738 9.66151L9.55485 12.5474Z"
                fill={color}
            />
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                svg {
                    fill: ${color};
                    height: ${size}px;
                    width: ${size}px;
                    display: block;
                }
            `}</style>
        </svg>
    );
}
