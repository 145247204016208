// Copyright 2024 Descript, Inc
import { useEffect, useState } from 'react';

export const useMediaQuery = (query: string) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const handleMQChange = (data) => setMatches(data.matches);
        const result = window.matchMedia(query);
        try {
            result.addEventListener('change', handleMQChange);
        } catch {
            try {
                result.addListener(handleMQChange);
            } catch (e) {
                console.error(e);
            }
        }
        setMatches(result.matches);
        return () => {
            try {
                result.removeEventListener('change', handleMQChange);
            } catch {
                try {
                    result.removeListener(handleMQChange);
                } catch (e) {
                    console.error(e);
                }
            }
        };
    }, [query]);

    return matches;
};
