// Copyright 2024 Descript, Inc
/* eslint-disable @typescript-eslint/no-shadow */
import { SpanNames } from '@descript/client/src/Utilities/Tracing';
import { RecorderContext } from '@descript/client/src/Recording/ErrorsAndAnalytics/AnalyticsHandler';
import { IDBPDatabase, openDB } from 'idb';
import { WebRecorder } from '@descript/web-recorder';
type SpanType = (typeof SpanNames)[keyof typeof SpanNames];

const enum SpanDB {
    WebRecorderDB = 'web-recorder-spans',
}
const dbVersion = 1;

function dbSchemaUpgrade(db: IDBPDatabase) {
    db.createObjectStore('spans', { keyPath: 'spanId' });
}

/**
 * This asynchronous function is used to save an in-progress span.
 * @async
 * @function saveInProgressSpan
 * @param {string} userId - The ID of the user.
 * @param {SpanType} spanType - The type of the span.
 * @param {RecorderContext} context - The recorder context.
 * @param {string} spanId - The ID of the span.
 * @returns {Promise<void>} A Promise that resolves when the process finished.
 */
async function saveInProgressSpan(
    userId: string,
    spanType: SpanType,
    context: RecorderContext,
    spanId: string,
): Promise<void> {
    try {
        const db = await openDB(SpanDB.WebRecorderDB, dbVersion, {
            upgrade(db) {
                dbSchemaUpgrade(db);
            },
        });
        const spanInfo = {
            context,
            spanType,
            userId,
        };
        const tx = db.transaction('spans', 'readwrite');
        const store = tx.objectStore('spans');
        const existingSpan = await store.get(spanId);
        if (existingSpan) {
            await store.put({ spanInfo, spanId });
        } else {
            await store.add({ spanInfo, spanId });
        }
        await tx.done;
    } catch (e) {
        WebRecorder.onRecorderEvent({
            type: 'error',
            recorder: 'N/A',
            context: 'span_handler',
            error: e as Error,
        });
        throw e;
    }
}

/**
 * This asynchronous function is used to close an in-progress span.
 * @async
 * @function closeInProgressSpan
 * @param {string} userId - The ID of the user.
 * @param {SpanType} spanType - The type of the span.
 * @param {RecorderContext} context - The recorder context.
 * @param {string} spanId - The ID of the span.
 * @returns {Promise<void>} A Promise that resolves when the process finished.
 */
async function closeInProgressSpan(
    userId: string,
    spanType: SpanType,
    context: RecorderContext,
    spanId: string,
): Promise<void> {
    try {
        const db = await openDB(SpanDB.WebRecorderDB, dbVersion, {
            upgrade(db) {
                dbSchemaUpgrade(db);
            },
        });
        const tx = db.transaction('spans', 'readwrite');
        const store = tx.objectStore('spans');
        const existingSpan = await store.get(spanId);
        if (!existingSpan) {
            WebRecorder.onRecorderEvent({
                type: 'warning',
                recorder: 'N/A',
                context: 'span_handler',
                message: `Span ${spanId} not found in IndexedDB`,
            });
            return;
        }
        await store.delete(spanId);
        await tx.done;
    } catch (e) {
        WebRecorder.onRecorderEvent({
            type: 'error',
            recorder: 'N/A',
            context: 'span_handler',
            error: e as Error,
        });
        throw e;
    }
}

/**
 * This asynchronous function is used to access an in-progress span.
 * @async
 * @function getInProgressSpans
 * @returns {Promise} A Promise that resolves with the retrieved span.
 */
async function getInProgressSpans() {
    try {
        const db = await openDB(SpanDB.WebRecorderDB, dbVersion, {
            upgrade(db) {
                dbSchemaUpgrade(db);
            },
        });
        const tx = db.transaction('spans', 'readonly');
        const store = tx.objectStore('spans');
        const spans = await store.getAll();
        await tx.done;
        return spans;
    } catch (e) {
        WebRecorder.onRecorderEvent({
            type: 'error',
            recorder: 'N/A',
            context: 'span_handler',
            error: e as Error,
        });
        throw e;
    }
}

export const SpanStorage = {
    saveInProgressSpan,
    closeInProgressSpan,
    getInProgressSpans,
};
